import { combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { normalize } from "normalizr";
import { fromJS } from "immutable";
import { setEntities } from "@e-group/redux-modules/immutable/entities";
import { closeDialog } from "@e-group/redux-modules/dialogs";
import {
  setSnackbarData,
  openSnackbar,
} from "@e-group/redux-modules/immutable/snackbars";
import { SNACKBAR } from "App.jsx";
import {
  fetchGetInvoices,
  fetchGetInvoicesRequest,
  fetchGetInvoicesSuccess,
  fetchGetInvoicesFailure,
  fetchGetInvoiceRequest,
  fetchGetInvoiceSuccess,
  fetchGetInvoiceFailure,
  fetchPostInvoiceRequest,
  fetchPostInvoiceSuccess,
  fetchPostInvoiceFailure,
  fetchPatchInvoiceRequest,
  fetchPatchInvoiceSuccess,
  fetchPatchInvoiceFailure,
  fetchDeleteInvoiceRequest,
  fetchDeleteInvoiceSuccess,
  fetchDeleteInvoiceFailure,
  fetchPostAllowanceRequest,
  fetchPostAllowanceSuccess,
  fetchPostAllowanceFailure,
  fetchPostCopyInvoiceRequest,
  fetchPostCopyInvoiceSuccess,
  fetchPostCopyInvoiceFailure,
  setSelectedInvoice,
  handleNext,
  FETCH_GET_INVOICES,
  FETCH_GET_INVOICE,
  FETCH_POST_INVOICE,
  FETCH_PATCH_INVOICE,
  FETCH_DELETE_INVOICE,
  FETCH_POST_ALLOWANCE,
  FETCH_POST_COPY_INVOICE,
} from "./redux";
import { DIALOG } from "./InvoiceDialog";
import { DIALOG as EDIT_DIALOG } from "./InvoiceEditDialog";
import { DIALOG as ALLOWANCE_DIALOG } from "./InvoiceAllowanceDialog";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetInvoicesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_INVOICES,
  apiName: "b2bApis.fetchGetInvoices",
  fetchRequest: fetchGetInvoicesRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.invoices
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetInvoicesSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetInvoicesFailure(error))
    ),
});

export const fetchGetInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_INVOICE,
  apiName: "b2bApis.fetchGetInvoice",
  fetchRequest: fetchGetInvoiceRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.invoice);
    return [
      setEntities(fromJS(entities)),
      fetchGetInvoiceSuccess(fromJS(result)),
      setSelectedInvoice(fromJS(response.data)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetInvoiceFailure(error))
    ),
});

export const fetchPostInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_INVOICE,
  apiName: "b2bApis.fetchPostInvoice",
  fetchRequest: fetchPostInvoiceRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.invoice);
    return [
      fetchGetInvoices({
        from: 0,
        size: 10,
        sellerIdentifier: response.data.sellerIdentifier,
      }),
      setEntities(fromJS(entities)),
      fetchPostInvoiceSuccess(fromJS(result)),
      handleNext(),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostInvoiceFailure(error))
    ),
  handleAfterFetch: () => of(closeDialog(EDIT_DIALOG)),
});

export const fetchPatchInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_INVOICE,
  apiName: "b2bApis.fetchPatchInvoice",
  fetchRequest: fetchPatchInvoiceRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(response.data || {}, schema.invoice);
    // A0501 means allowance
    if (action.payload.eInvoiceMessage === "A0501") {
      return [
        setEntities(fromJS(entities)),
        fetchPatchInvoiceSuccess(fromJS(result)),
        closeDialog(DIALOG),
      ];
    }
    return [
      setEntities(fromJS(entities)),
      fetchPatchInvoiceSuccess(fromJS(result)),
      handleNext(),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchInvoiceFailure(error))
    ),
  handleAfterFetch: () => of(closeDialog(EDIT_DIALOG)),
});

export const fetchDeleteInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_INVOICE,
  apiName: "b2bApis.fetchDeleteInvoice",
  fetchRequest: fetchDeleteInvoiceRequest,
  handleSuccess: (response, { schema }) => {
    return [
      fetchGetInvoices({
        from: 0,
        size: 10,
        sellerIdentifier: response.data.sellerIdentifier,
      }),
      fetchDeleteInvoiceSuccess(),
      closeDialog(DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteInvoiceFailure(error))
    ),
});

export const fetchPostAllowanceEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ALLOWANCE,
  apiName: "b2bApis.fetchPostAllowance",
  fetchRequest: fetchPostAllowanceRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.allowance
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostAllowanceSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostAllowanceFailure(error))
    ),
  handleAfterFetch: () => of(closeDialog(ALLOWANCE_DIALOG)),
});

export const fetchPostCopyInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_COPY_INVOICE,
  apiName: "b2bApis.fetchPostCopyInvoice",
  fetchRequest: fetchPostCopyInvoiceRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.invoice);
    return [
      setEntities(fromJS(entities)),
      fetchPostCopyInvoiceSuccess(fromJS(result)),
      setSnackbarData({
        name: SNACKBAR,
        message: "複製成功",
        variant: "success",
      }),
      openSnackbar(SNACKBAR),
      fetchGetInvoices({
        from: 0,
        size: 10,
        sellerIdentifier: response.data.sellerIdentifier,
      }),
      closeDialog(DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCopyInvoiceFailure(error))
    ),
});

export default combineEpics(
  fetchGetInvoicesEpic,
  fetchGetInvoiceEpic,
  fetchPostInvoiceEpic,
  fetchPatchInvoiceEpic,
  fetchDeleteInvoiceEpic,
  fetchPostAllowanceEpic,
  fetchPostCopyInvoiceEpic
);
