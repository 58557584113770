import { Map } from "immutable";
import { handleActions } from "redux-actions";
import {
  SET_SELECTED_SELLER_IDENTIFIER,
  SET_SELECTED_INVOICE,
  SET_ACTIVE_STEP,
  SET_CONFIRM_ALLOWANCE,
  HANDLE_NEXT,
  HANDLE_PREV
} from "./redux";

const reducer = handleActions(
  {
    [SET_SELECTED_SELLER_IDENTIFIER]: (state, action) =>
      state.set("selectedSellerIdentifier", action.payload),
    [SET_SELECTED_INVOICE]: (state, action) =>
      state.set("selectedInvoice", action.payload),
    [SET_ACTIVE_STEP]: (state, action) =>
      state.set("activeStep", action.payload),
    [SET_CONFIRM_ALLOWANCE]: (state, action) =>
      state.set("confirmAllowance", action.payload),
    [HANDLE_NEXT]: state =>
      state.update("activeStep", el => {
        const nextStep = el + 1;
        if (nextStep <= state.get("activeStepMax")) {
          return nextStep;
        }
        return el;
      }),
    [HANDLE_PREV]: state =>
      state.update("activeStep", el => {
        const prevStep = el - 1;
        if (prevStep >= 0) {
          return prevStep;
        }
        return el;
      })
  },
  Map({
    selectedSellerIdentifier: "28473556",
    activeStep: 0,
    activeStepMax: 3,
    confirmAllowance: false
  })
);

export default reducer;
