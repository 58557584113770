import { combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { normalize } from "normalizr";
import { fromJS } from "immutable";
import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetInvoicePeriodsRequest,
  fetchGetInvoicePeriodsSuccess,
  fetchGetInvoicePeriodsFailure,
  FETCH_GET_INVOICE_PERIODS,
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetInvoicePeriodsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_INVOICE_PERIODS,
  apiName: "b2cApis.fetchGetInvoicePeriods",
  fetchRequest: fetchGetInvoicePeriodsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.invoicePeriods
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetInvoicePeriodsSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetInvoicePeriodsFailure(error))
    ),
});

export default combineEpics(fetchGetInvoicePeriodsEpic);
