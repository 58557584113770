import actionCreators from "redux/actionCreators";
import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";

/** Actions */
export const {
  fetchGetInvoicePeriods,
  fetchGetInvoicePeriodsRequest,
  fetchGetInvoicePeriodsSuccess,
  fetchGetInvoicePeriodsFailure,
  setSelectedSellerIdentifier,
} = actionCreators.pages.invoicePeriods;

/** Types */
export const FETCH_GET_INVOICE_PERIODS = fetchGetInvoicePeriods().type;
export const FETCH_GET_INVOICE_PERIODS_REQUEST = fetchGetInvoicePeriodsRequest()
  .type;
export const FETCH_GET_INVOICE_PERIODS_SUCCESS = fetchGetInvoicePeriodsSuccess()
  .type;
export const FETCH_GET_INVOICE_PERIODS_FAILURE = fetchGetInvoicePeriodsFailure()
  .type;

export const SET_SELECTED_SELLER_IDENTIFIER = setSelectedSellerIdentifier()
  .type;

/** Selectors */
const getUiStates = (state) => state.getIn(["pages", "InvoicePeriods"]);

export const getSelectedSellerIdentifier = createSelector(
  getUiStates,
  (uiStates) => uiStates.get("selectedSellerIdentifier")
);

const getApiStates = (state) =>
  state.getIn(["apis", "pages", "invoicePeriods"], Map());

const getFetchGetInvoicePeriods = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetInvoicePeriods", Map())
);

export const getInvoicePeriods = createSelector(
  getFetchGetInvoicePeriods,
  getEntities,
  (fetchGet, entities) =>
    denormalize(
      fetchGet.getIn(["response", "result"]),
      schema.invoicePeriods,
      entities
    )
);

export const getInvoicePeriodsTotal = createSelector(
  getFetchGetInvoicePeriods,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getInvoicePeriodsIsLoading = createSelector(
  getFetchGetInvoicePeriods,
  (fetchGet) => fetchGet.get("isLoading")
);
