import { Map } from "immutable";
import { handleActions } from "redux-actions";
import {
  FETCH_GET_DOCUMENT_VERSION_SUCCESS,
  CLEAR_DOCUMENT_VERSION
} from "./redux";

const reducer = handleActions(
  {
    [FETCH_GET_DOCUMENT_VERSION_SUCCESS]: (state, action) =>
      state.set("response", action.payload),
    [CLEAR_DOCUMENT_VERSION]: state => state.delete("response")
  },
  Map()
);

export default reducer;
