import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: 5,
    marginTop: 1
  }
}));

const DocumentStatusIcon = ({ isPublic }) => {
  const classes = useStyles();

  if (isPublic) {
    return (
      <Tooltip title="公開狀態">
        <PublicIcon className={classes.icon} fontSize="small" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="私人狀態">
      <LockIcon className={classes.icon} fontSize="small" />
    </Tooltip>
  );
};

DocumentStatusIcon.propTypes = {
  isPublic: PropTypes.bool.isRequired
};

export default DocumentStatusIcon;
