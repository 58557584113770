import React, { useState } from "react";

import moment from "moment";
import { useHistory } from "react-router";
import makeSearchDataList from "@e-group/hooks/makeSearchDataList";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { fetchGetFeedbacks, getFeedbacks, getFeedbacksTotal } from "./redux";

import SearchDataList from "@e-group/material-module/SearchDataList";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FixedCenter from "components/FixedCenter";
import FeedbackDialog, { DIALOG } from "./FeedbackDialog";

const useSearchDataList = makeSearchDataList({
  fromKey: "startIndex",
  sizeKey: "size",
  queryKey: "query",
});

const Feedbacks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const feedbacks = useSelector(getFeedbacks);
  const feedbacksTotal = useSelector(getFeedbacksTotal);
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const {
    handleSearchChange,
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    payload,
  } = useSearchDataList({
    fetchGet: React.useCallback(
      (payload) => {
        dispatch(fetchGetFeedbacks(payload));
      },
      [dispatch]
    ),
    history,
  });

  const renderColumns = (rowData, index) => {
    return (
      <ListItem key={index}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">{rowData[0]}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">{rowData[1]}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">{rowData[2]}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">{rowData[3]}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">{rowData[4]}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">{rowData[5]}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const renderDataRow = (feedback) => {
    return (
      <ListItem
        key={feedback.organizationFeedbackId}
        button
        onClick={() => {
          setSelectedFeedback(feedback);
          dispatch(openDialog(DIALOG));
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              {feedback.organizationService.service.serviceName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              {feedback.feedbackType.feedbackTypeName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {feedback.organizationFeedbackTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              {feedback.organizationFeedbackPersonName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">
              {feedback.organizationFeedbackPersonEmail}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              {moment(feedback.organizationFeedbackCreateDateString).format(
                "LLLL"
              )}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  if (!feedbacks) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <FeedbackDialog
        organizationFeedbackId={selectedFeedback.organizationFeedbackId}
      />
      <Container maxWidth="md">
        <Paper>
          <SearchDataList
            onSubmit={handleSearchSubmit}
            title="回饋列表"
            SearchBarProps={{
              placeholder: "回饋搜尋",
              onChange: handleSearchChange,
              defaultValue: payload.query,
            }}
            columns={["系統", "類型", "標題", "姓名", "Email", "回饋日期"]}
            data={feedbacks.toJS()}
            serverSide
            isEmpty={!feedbacksTotal}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            disablePadding
            MuiTablePaginationProps={{
              count: feedbacksTotal,
              labelRowsPerPage: "每頁幾筆",
              page: Math.ceil(payload.startIndex / payload.size),
              rowsPerPage: parseInt(payload.size),
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
            }}
          />
        </Paper>
      </Container>
    </>
  );
};

export default Feedbacks;
