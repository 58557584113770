import { schema } from "normalizr";

export const documentPermission = new schema.Entity(
  "documentPermissions",
  {},
  {
    idAttribute: (el) => `${el.document.documentId}${el.user.loginId}`,
  }
);
export const documentPermissions = [documentPermission];

export const document = new schema.Entity(
  "documents",
  {
    documentPermission,
  },
  {
    idAttribute: "documentId",
  }
);
export const documents = [document];

export const documentVersion = new schema.Entity(
  "documentVersions",
  {},
  {
    idAttribute: "documentVersionId",
  }
);
export const documentVersions = [documentVersion];

export const feedback = new schema.Entity(
  "feedbacks",
  {},
  {
    idAttribute: "organizationFeedbackId",
  }
);
export const feedbacks = [feedback];

export const drawio = new schema.Entity(
  "drawios",
  {},
  {
    idAttribute: "drawioId",
  }
);
export const drawios = [drawio];

export const user = new schema.Entity(
  "users",
  {},
  {
    idAttribute: "loginId",
  }
);
export const users = [user];

export const organization = new schema.Entity(
  "organizations",
  {},
  {
    idAttribute: "organizationId",
  }
);
export const organizations = [organization];

export const invoice = new schema.Entity(
  "invoices",
  {},
  {
    idAttribute: "invoiceId",
  }
);
export const invoices = [invoice];

export const allowance = new schema.Entity(
  "allowances",
  {},
  {
    idAttribute: "allowanceNumber",
  }
);
export const allowances = [allowance];

export const invoicePeriod = new schema.Entity(
  "invoicePeriods",
  {},
  {
    idAttribute: "invoicePeriodId",
  }
);
export const invoicePeriods = [invoicePeriod];
