import React from "react";

import axios from "axios";
import textMd from "./text.md";

import MarkdownElement from "@e-group/material-lab/MarkdownElement";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import FixedCenter from "components/FixedCenter";

const Privacy = props => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    axios.get(textMd).then(res => {
      setText(res.data);
    });
  }, []);

  if (!text) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <Container>
      <Paper square={false} elevation={0}>
        <Box py={2} px={3}>
          <MarkdownElement text={text} />
        </Box>
      </Paper>
    </Container>
  );
};

export default Privacy;
