import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchGetDocuments,
  fetchGetDocumentsRequest,
  fetchGetDocumentsSuccess,
  fetchGetDocumentsFailure,

  fetchPostDocument,
  fetchPostDocumentRequest,
  fetchPostDocumentSuccess,
  fetchPostDocumentFailure,
} = actionCreators.pages.documents;

/** Types */
export const FETCH_GET_DOCUMENTS = fetchGetDocuments().type;
export const FETCH_GET_DOCUMENTS_REQUEST = fetchGetDocumentsRequest().type;
export const FETCH_GET_DOCUMENTS_SUCCESS = fetchGetDocumentsSuccess().type;
export const FETCH_GET_DOCUMENTS_FAILURE = fetchGetDocumentsFailure().type;

export const FETCH_POST_DOCUMENT = fetchPostDocument().type;
export const FETCH_POST_DOCUMENT_REQUEST = fetchPostDocumentRequest().type;
export const FETCH_POST_DOCUMENT_SUCCESS = fetchPostDocumentSuccess().type;
export const FETCH_POST_DOCUMENT_FAILURE = fetchPostDocumentFailure().type;

/**
 * Selectors
 */
const getApiStates = (state) =>
  state.getIn(["apis", "pages", "documents"], Map());

export const getFetchGetDocuments = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetDocuments", Map())
);

export const getFetchPostDocument = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPostDocument", Map())
);

export const getDocuments = createSelector(
  getFetchGetDocuments,
  getEntities,
  (fetchGet, entities) =>
    denormalize(
      fetchGet.getIn(["response", "result"]),
      schema.documents,
      entities
    )
);

export const getDocumentsTotal = createSelector(
  getFetchGetDocuments,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getIsLoading = createSelector(getFetchGetDocuments, (fetchGet) =>
  fetchGet.get("isLoading")
);

export const getDocumentIsPosting = createSelector(
  getFetchPostDocument,
  (fetchGet) => fetchGet.get("isLoading")
);
