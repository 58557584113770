import apis from "redux/apis";
import _escape from "lodash.escape";

function createHTML(XMLString) {
  const mxgraph = `"highlight":"#0000ff","nav":true,"resize":true,"editable":false,"xml":${JSON.stringify(
    XMLString
  )},"toolbar":"pages zoom layers lightbox","page":0`;
  return `<!--[if IE]><meta http-equiv="X-UA-Compatible" content="IE=5,IE=9" ><![endif]-->
<!DOCTYPE html>
<html>
<head>
<meta charset="utf-8"/>
</head>
<body>
<div class="mxgraph" style="max-width:100%;border:1px solid transparent;" data-mxgraph="{${_escape(
    mxgraph
  )}}"></div>
<script type="text/javascript" src="https://www.draw.io/js/viewer.min.js"></script>
</body>
</html>`;
}

export const initDrawioEditor = (iframe, xml) => {
  iframe.contentWindow.postMessage(
    JSON.stringify({
      action: "load",
      autosave: 1,
      xml
    }),
    "*"
  );
};

export const saveing = iframe => {
  iframe.contentWindow.postMessage(
    JSON.stringify({
      action: "status",
      message: "Saving...",
      modified: true
    }),
    "*"
  );
};

const saveSuccess = iframe => {
  iframe.contentWindow.postMessage(
    JSON.stringify({
      action: "status",
      message: "Save Success!",
      modified: false
    }),
    "*"
  );
};

export const createDrawioByPost = ({ iframe, editor, xml }) => {
  return apis.eds
    .fetchPostDrawio({
      drawioTitle: `drawio-${new Date().getTime()}`,
      drawioContent: createHTML(xml)
    })
    .then(response => {
      editor.execute("insertDrawio", response.data.drawioPath);
      saveSuccess(iframe);
      return response.data.drawioId;
    });
};

export const updateDrawioByPatch = ({ iframe, editor, drawioId, xml }) => {
  return apis.eds
    .fetchPatchDrawio({
      drawioId,
      drawioContent: createHTML(xml)
    })
    .then(response => {
      editor.execute("updateSelectedDrawioAttribute", {
        name: "src",
        value: response.data.drawioPath
      });
      saveSuccess(iframe);
    });
};
