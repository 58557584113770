import React from "react";
import PropTypes from "prop-types";

import { Map } from "immutable";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { makeStyles } from "@material-ui/core/styles";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import { formValueSelector, isInvalid, submit } from "redux-form/immutable";
import { reset } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPostAllowance,
  getSelectedSellerIdentifier,
  getSelectedInvoice,
  getAllowanceIsPosting,
} from "./redux";
import makeFormValueIsEqual from "utils/makeFormValueIsEqual";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import InvoiceAllowanceForm, { FORM } from "./InvoiceAllowanceForm";

import ResetIcon from "@material-ui/icons/Replay";

export const DIALOG = "invoiceAllowanceDialog";

const CONFIRM_LEAVE_DIALOG = "confirmLeaveInvoiceAllowanceDialog";

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)(ConfirmDialog);

const selector = formValueSelector(FORM);

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const useFormValueIsEqual = makeFormValueIsEqual(FORM);

const InvoiceAllowanceDialog = function InvoiceAllowanceDialog(props) {
  const dispatch = useDispatch();
  const buyerName = useSelector((state) => selector(state, "buyerName"));
  const buyerIdentifier = useSelector((state) =>
    selector(state, "buyerIdentifier")
  );
  const invoiceProductList = useSelector((state) =>
    selector(state, "invoiceProductList")
  );
  const formIsInvalid = useSelector((state) => isInvalid(FORM)(state));
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);

  const selectedInvoice = useSelector(getSelectedInvoice);
  const isLoading = useSelector(getAllowanceIsPosting);
  const classes = useStyles();
  const { isOpen, handleClose } = props;
  const isEqual = useFormValueIsEqual();
  const [confirmAllowance, setConfirmAllowance] = React.useState(false);

  const closeConform = () => {
    if (!isEqual) {
      setConfirmAllowance(false);
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
      setConfirmAllowance(false);
    }
  };

  const handleSubmit = (values) => {
    const allowanceProductList = values
      .get("invoiceProductList")
      .map((el) =>
        el
          .deleteAll([
            "invoiceId",
            "invoiceProductId",
            "invoiceProductDescription",
            "invoiceProductQuantity",
            "invoiceProductUnitPrice",
            "invoiceProductAmount",
            "invoiceProductSequenceNumber",
          ])
          .set(
            "allowanceProductOriginalSequenceNumber",
            el.get("invoiceProductSequenceNumber")
          )
          .set(
            "allowanceProductOriginalDescription",
            el.get("invoiceProductDescription")
          )
          .set("allowanceProductQuantity", el.get("invoiceProductQuantity"))
          .set("allowanceProductUnitPrice", el.get("invoiceProductUnitPrice"))
      );
    const payload = Map({
      invoiceId: values.get("invoiceId"),
      allowanceProductList: allowanceProductList,
    });
    dispatch(
      fetchPostAllowance({
        sellerIdentifier: selectedSellerIdentifier,
        ...payload.toJS(),
      })
    );
    setConfirmAllowance(false);
  };

  const handleReset = () => {
    dispatch(reset(FORM));
  };
  const handleConfirm = () => {
    setConfirmAllowance(true);
  };
  const handleBack = () => {
    setConfirmAllowance(false);
  };

  const renderBasicFieldsStep = () => {
    return (
      <InvoiceAllowanceForm
        initialValues={selectedInvoice}
        onSubmit={handleSubmit}
      />
    );
  };

  const renderCheckStep = () => {
    let totalAmount = 0;
    if (formIsInvalid) {
      return <Typography>請先輸入折讓商品</Typography>;
    }
    if (invoiceProductList) {
      invoiceProductList.map(
        (el) =>
          (totalAmount =
            totalAmount +
            el.get("invoiceProductQuantity") *
              el.get("invoiceProductUnitPrice"))
      );
    }
    const tax = Math.round(totalAmount * 0.05);
    return (
      <React.Fragment>
        <Typography>買方名稱：{buyerName}</Typography>
        <Typography>買方統編：{buyerIdentifier}</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>商品品名</TableCell>
                <TableCell>商品數量</TableCell>
                <TableCell>單品項價格</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceProductList &&
                invoiceProductList.map((invoiceProduct, index) => {
                  if (typeof invoiceProduct !== "undefined") {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductDescription")}
                        </TableCell>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductQuantity")}
                        </TableCell>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductUnitPrice")}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return invoiceProduct;
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography>商品總額：{totalAmount}</Typography>
        <Typography>稅金：{tax}</Typography>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ConfirmLeaveDialog
        title="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={closeConform}>
        <DialogTitle>折讓發票</DialogTitle>
        <DialogContent>
          <Box px={3}>
            <Box display={confirmAllowance ? "none" : "block"}>
              {renderBasicFieldsStep()}
            </Box>
            <Box display={confirmAllowance ? "block" : "none"}>
              {renderCheckStep()}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {confirmAllowance ? (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                onClick: () => handleBack(),
              }}
            >
              上一步
            </Button>
          ) : (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                endIcon: <ResetIcon />,
                onClick: () => handleReset(),
              }}
            >
              重整
            </Button>
          )}
          {confirmAllowance ? (
            <Button
              loading={isLoading}
              MuiButtonProps={{
                type: "submit",
                variant: "contained",
                color: "primary",
                className: classes.button,
                onClick: () => dispatch(submit(FORM)),
              }}
            >
              送出
            </Button>
          ) : (
            <Button
              MuiButtonProps={{
                variant: "contained",
                color: "primary",
                className: classes.button,
                onClick: () => handleConfirm(),
              }}
            >
              確認折讓內容
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

InvoiceAllowanceDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceAllowanceDialog);
