import React from "react";

import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import makeSearchDataList from "@e-group/hooks/makeSearchDataList";
import { openDialog } from "@e-group/redux-modules/dialogs";
import {
  fetchGetInvoices,
  fetchGetInvoice,
  setSelectedSellerIdentifier,
  setActiveStep,
  setSelectedInvoice,
  getInvoices,
  getInvoicesTotal,
  getInvoicesIsLoading,
  getSelectedSellerIdentifier
} from "./redux";

import SearchDataList from "@e-group/material-module/SearchDataList";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FixedCenter from "components/FixedCenter";
import InvoiceDialog, { DIALOG } from "./InvoiceDialog";
import InvoiceEditDialog, { DIALOG as ADD_DIALOG } from "./InvoiceEditDialog";
import InvoiceAllowanceDialog from "./InvoiceAllowanceDialog";

const useSearchDataList = makeSearchDataList();
const sellerIdentifierList = [
  {
    name: "馴錢師財商顧問股份有限公司",
    id: "28473556"
  },
  {
    name: "益群健康股份有限公司",
    id: "24314136"
  }
];

const Invoices = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const invoices = useSelector(getInvoices);
  const invoicesTotal = useSelector(getInvoicesTotal);
  const isLoading = useSelector(getInvoicesIsLoading);
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const {
    handleSearchChange,
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    payload
  } = useSearchDataList({
    fetchGet: React.useCallback(
      payload => {
        dispatch(
          fetchGetInvoices({
            sellerIdentifier: selectedSellerIdentifier,
            ...payload
          })
        );
      },
      [dispatch, selectedSellerIdentifier]
    ),
    history
  });

  const handleSelectChange = e => {
    dispatch(setSelectedSellerIdentifier(e.target.value));
  };

  const handleRowClick = invoice => {
    dispatch(
      fetchGetInvoice({
        sellerIdentifier: selectedSellerIdentifier,
        invoiceId: invoice.invoiceId
      })
    );
    dispatch(setActiveStep(0));
    dispatch(openDialog(DIALOG));
  };

  const handleAddClick = () => {
    dispatch(openDialog(ADD_DIALOG));
    dispatch(setActiveStep(0));
    dispatch(setSelectedInvoice(undefined));
  };

  const renderColumns = rowData => {
    return (
      <ListItem>
        <Grid container>
          <Grid item xs={12} sm={3} key={rowData[0]}>
            <Typography variant="body2">{rowData[0]}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} key={rowData[1]}>
            <Typography variant="body2">{rowData[1]}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} key={rowData[2]}>
            <Typography variant="body2">{rowData[2]}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} key={rowData[3]}>
            <Typography variant="body2">{rowData[3]}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} key={rowData[4]}>
            <Typography variant="body2">{rowData[4]}</Typography>
          </Grid>
        </Grid>
        <ListItemSecondaryAction>證明聯下載</ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderDataRow = rowData => {
    return (
      <ListItem
        key={rowData.invoiceId}
        button
        onClick={() => handleRowClick(rowData)}
      >
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">
              {rowData.transactionUpdateDateString}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">{rowData.buyerName}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              {rowData.invoiceDate &&
                moment(rowData.invoiceDate).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">{rowData.invoiceNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">{rowData.invoiceStatus}</Typography>
          </Grid>
        </Grid>
        <ListItemSecondaryAction>
          {rowData.invoiceProveFilePath && (
            <Link href={rowData.invoiceProveFilePath} download target="_blank">
              下載
            </Link>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  if (!invoices) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <React.Fragment>
      <InvoiceDialog />
      <InvoiceEditDialog />
      <InvoiceAllowanceDialog />
      <Container>
        <Box display="flex" alignItems="center" mb={1}>
          <TextField
            onChange={handleSelectChange}
            value={selectedSellerIdentifier}
            label="選擇公司"
            select
            SelectProps={{
              disableUnderline: true
            }}
          >
            {sellerIdentifierList.map(el => (
              <ListItem key={el.id} value={el.id}>
                {el.name}
              </ListItem>
            ))}
          </TextField>
          <Box flexGrow={1} />
          <div>
            <Button
              onClick={handleAddClick}
              variant="contained"
              color="primary"
            >
              新增發票
            </Button>
          </div>
        </Box>
        <Paper>
          <SearchDataList
            onSubmit={handleSearchSubmit}
            title="發票列表"
            SearchBarProps={{
              placeholder: "發票搜尋",
              onChange: handleSearchChange,
              defaultValue: payload.query
            }}
            columns={[
              "紀錄更新日期",
              "買方名稱",
              "發票開立日期",
              "發票號碼",
              "發票狀態"
            ]}
            data={invoices.toJS()}
            serverSide
            loading={isLoading}
            isEmpty={!invoicesTotal}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            disablePadding
            MuiTablePaginationProps={{
              count: invoicesTotal,
              labelRowsPerPage: "每頁幾筆",
              page: Math.ceil(payload.from / payload.size),
              rowsPerPage: parseInt(payload.size),
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default Invoices;
