import React, { useEffect } from "react";

import s3Url from "utils/s3Url";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetFeedback, getFeedback } from "./redux";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

export const DIALOG = "FeedbackDialog";

const FeedbackDialog = ({
  isOpen = false,
  handleClose,
  organizationFeedbackId,
}) => {
  const dispatch = useDispatch();
  const feedback = useSelector(getFeedback);

  useEffect(() => {
    if (organizationFeedbackId) {
      dispatch(
        fetchGetFeedback({
          organizationFeedbackId,
        })
      );
    }
  }, [dispatch, organizationFeedbackId]);

  const renderContent = () => {
    if (!feedback) {
      return <CircularProgress disableShrink />;
    }
    return (
      <>
        <Typography gutterBottom>
          姓名：{feedback.get("organizationFeedbackPersonName")}
        </Typography>
        <Typography gutterBottom>
          Email：{feedback.get("organizationFeedbackPersonEmail")}
        </Typography>
        <Typography gutterBottom>
          標題：{feedback.get("organizationFeedbackTitle")}
        </Typography>
        <Typography gutterBottom>
          內容：{feedback.get("organizationFeedbackContent")}
        </Typography>
        {feedback.get("organizationFeedbackFileList") && (
          <>
            <Typography gutterBottom>圖片檔案：</Typography>
            <Grid container spacing={2}>
              {feedback.get("organizationFeedbackFileList").map((el) => (
                <Grid item xs={12} key={el.get("organizationFeedbackFileId")}>
                  <img
                    style={{ width: "100%" }}
                    src={s3Url(el.get("organizationFeedbackFilePath"))}
                    alt=""
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={() => handleClose()}>
      <div style={{ display: "flex", alignItems: "center", paddingLeft: 24 }}>
        <Typography variant="h6">回饋內容</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)(FeedbackDialog);
