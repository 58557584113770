import React from "react";

import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import shortUsername from "@e-group/utils/shortUsername";
import stringToColor from "@e-group/utils/stringToColor";
import { getDocumentPermissions } from "./redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import StyledAvatar from "components/StyledAvatar";

const useStyles = makeStyles(theme => ({
  avatar: {
    marginLeft: -9,
    float: "right"
  }
}));

const DocumentAvatars = props => {
  const classes = useStyles();
  const theme = useTheme();
  const documentPermissions = useSelector(getDocumentPermissions);
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [cookies] = useCookies();

  const renderAvatar = el => {
    const userName = el.getIn(["user", "userName"]);
    return (
      <Tooltip key={el.getIn(["user", "loginId"])} title={userName}>
        <StyledAvatar
          className={classes.avatar}
          style={{
            backgroundColor: stringToColor(userName)
          }}
        >
          {shortUsername(userName)}
        </StyledAvatar>
      </Tooltip>
    );
  };

  if (isXS) {
    return undefined;
  }

  if (!documentPermissions) {
    return (
      <Box display="inline-block">
        <CircularProgress size={24} disableShrink />
      </Box>
    );
  }

  const filteredPermissions = documentPermissions.filter(
    el => el.getIn(["user", "loginId"]) !== cookies.lid
  );

  if (filteredPermissions.size < 5) {
    return (
      <Box display="inline-block">{filteredPermissions.map(renderAvatar)}</Box>
    );
  }

  const shortPermissions = filteredPermissions.take(5);
  const otherNumber = filteredPermissions.size - 4;

  return (
    <Box display="inline-block">
      <Tooltip title={`其他 ${otherNumber} 人`}>
        <StyledAvatar className={classes.avatar}>{otherNumber}</StyledAvatar>
      </Tooltip>
      {shortPermissions.map(renderAvatar)}
    </Box>
  );
};

export default DocumentAvatars;
