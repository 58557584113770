import { List } from "immutable";
import { TAX_RATE } from "./config";

/**
 * normalize form field of number
 * @param {object} options
 */
export const positive = (options = {}) => {
  const { min = 0, max } = options;

  return (value, previousValue, allValues) => {
    let result = Number(value);
    if (result < min) result = min;
    if (typeof max === "number") {
      if (result > max) result = max;
    }
    return result;
  };
};

/**
 * normalize form field of number
 * @param {object} options
 */
export const positiveFixed = (options = {}) => {
  const { min = 0, max } = options;

  return (value, previousValue, allValues) => {
    let result = Number(value).toFixed();
    if (result < min) result = min;
    if (typeof max === "number") {
      if (result > max) result = max;
    }
    return result;
  };
};

/**
 * format currency
 * @param {string | number} currency
 * @param {object} options
 */
export const ccyformat = (currency, options = {}) => {
  const { round, maximumFractionDigits = 6 } = options;

  let nextCurrency = Number(Number(currency).toFixed(6));
  if (round) {
    nextCurrency = Math.round(nextCurrency);
  }
  return nextCurrency.toLocaleString(undefined, {
    maximumFractionDigits,
  });
};

/**
 * calc invoice amounts
 * @param {object} invoiceProductList
 * @param {boolean} taxIncluded
 */
export const calcInvoiceAmounts = (invoiceProductList, taxIncluded) => {
  let salesAmount = 0;
  let taxAmount = 0;
  let invoiceAmount = 0;
  let calcedList = List();

  if (taxIncluded) {
    calcedList = invoiceProductList.map((el) => {
      const priceBeforeTax = el.get("invoiceProductUnitPrice") / 1.05;
      const subtotal = priceBeforeTax * el.get("invoiceProductQuantity");
      salesAmount += subtotal;
      return el
        .set("invoiceProductUnitPrice", priceBeforeTax)
        .set("subtotal", subtotal);
    });
  } else {
    calcedList = invoiceProductList.map((el) => {
      const subtotal =
        el.get("invoiceProductUnitPrice") * el.get("invoiceProductQuantity");
      salesAmount += subtotal;
      return el.set("subtotal", subtotal);
    });
  }
  taxAmount = salesAmount * TAX_RATE;
  invoiceAmount = taxAmount + salesAmount;

  return [calcedList, salesAmount, taxAmount, invoiceAmount];
};
