import React from "react";
import PropTypes from "prop-types";

import { Map } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import { getOptions } from "components/RouterRoot/redux";
import { fetchPatchOrganization } from "./redux";

import Main from "@e-group/material-layout/Main";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";

const Organization = ({ organization }) => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState(organization);
  const options = useSelector(getOptions);

  const handleChange = name => e => {
    const value = e.target.value;
    setValues(values => values.set(name, value));
  };

  const handleSave = () => {
    dispatch(
      fetchPatchOrganization({
        organizationId: organization.get("organizationId"),
        ...values.deleteAll(["member", "organizationCreateDateString", "organizationUpdateDateString", "organizationSource", "organizationTypeString", "contactWindowGenderString"]).toJS()
      })
    );
  };

  return (
    <Container maxWidth="sm">
      <Main>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" gutterBottom>
              {organization.get("organizationName")}-單位總覽
            </Typography>
            <Divider />
            <TextField
              label="單位名稱"
              onChange={handleChange("organizationName")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationName")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="單位類型"
              onChange={handleChange("organizationType")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationType")}
              select
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            >
              {options
                .get("organizationType")
                .map((el, key) => (
                  <MenuItem key={key} value={key}>
                    {el}
                  </MenuItem>
                ))
                .valueSeq()}
            </TextField>
            
            <TextField
              label="聯絡窗口姓名"
              onChange={handleChange("contactWindowName")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("contactWindowName")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="聯絡窗口英文姓名"
              onChange={handleChange("contactWindowEnglishName")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("contactWindowEnglishName", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="聯絡窗口電話"
              onChange={handleChange("organizationPhone")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationPhone", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="聯絡窗口信箱"
              onChange={handleChange("organizationdEmail")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationdEmail", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="聯絡窗口職稱"
              onChange={handleChange("contactWindowJobTitle")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("contactWindowJobTitle", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="聯絡窗口性別"
              onChange={handleChange("contactWindowGender")}
              variant="outlined"
              fullWidth
              margin="normal"
              select
              value={values.get("contactWindowGender")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            >
              {options
                .get("organizationGender")
                .map((el, key) => (
                  <MenuItem key={key} value={key}>
                    {el}
                  </MenuItem>
                ))
                .valueSeq()}
            </TextField>
            <TextField
              label="單位郵遞區號"
              onChange={handleChange("organizationZIPCode")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationZIPCode", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="單位城市"
              onChange={handleChange("organizationCity")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationCity", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="單位地區"
              onChange={handleChange("organizationArea")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationArea", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="單位地址"
              onChange={handleChange("organizationAddress")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationAddress", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="單位類型備註"
              onChange={handleChange("organizationTypeNote")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={values.get("organizationTypeNote", "")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleSave}>
                      儲存
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Paper>
      </Main>
    </Container>
  );
};

Organization.propTypes = {
  organization: PropTypes.instanceOf(Map).isRequired
};

export default Organization;
