import { combineReducers } from "redux-immutable";
import { combineEpics } from "redux-observable";

import MuiThemeProvider from "components/MuiThemeProvider/reducers";

import UserAvatarMenuEpics from "components/UserAvatarMenu/epics";
import RouterRootEpics from "components/RouterRoot/epics";

export const components = combineReducers({
  MuiThemeProvider
});

export const componentsEpics = combineEpics(
  UserAvatarMenuEpics,
  RouterRootEpics
);
