import React from "react";
import PropTypes from "prop-types";

import { reduxForm, Field, FieldArray } from "redux-form/immutable";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextLoadingField from "@e-group/material-form/TextLoadingField";

export const FORM = "invoiceAllowanceForm";

const MIN_INVOICE_PRODUCT_QUANTITY = 1;

const validate = values => {
  const errors = {};
  if (
    !values.has("invoiceProductList") ||
    values.get("invoiceProductList").size === 0
  ) {
    errors.invoiceProductList = { _error: "至少需要折讓一筆商品" };
  } else {
    const listErrors = [];
    values.get("invoiceProductList").forEach((invoiceProduct, index) => {
      const fieldError = {};
      if (!invoiceProduct.has("invoiceProductDescription")) {
        fieldError.invoiceProductDescription = "「商品品名」為必填";
      }
      if (!invoiceProduct.has("invoiceProductQuantity")) {
        fieldError.invoiceProductQuantity = "「商品數量」為必填";
      } else {
        if (
          invoiceProduct.get("invoiceProductQuantity") <
          MIN_INVOICE_PRODUCT_QUANTITY
        ) {
          fieldError.invoiceProductQuantity = `「商品數量」不得小於 ${MIN_INVOICE_PRODUCT_QUANTITY}`;
        }
      }
      if (!invoiceProduct.has("invoiceProductUnitPrice")) {
        fieldError.invoiceProductUnitPrice = "「單品項價格」為必填";
      }
      listErrors[index] = fieldError;
    });
    if (listErrors.length) {
      errors.invoiceProductList = listErrors;
    }
  }
  return errors;
};

const positiveFixed = ({ min = 0 } = {}) => (
  value,
  previousValue,
  allValues
) => {
  let result = Number(value).toFixed();
  if (result < min) result = min;
  return result;
};

const renderTalkIssueGroups = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    {submitFailed && error && (
      <Typography color="secondary">{error}</Typography>
    )}
    <Box mt={1}>
      {fields.map((invoiceProduct, index) => {
        return (
          <Grid container key={index} spacing={1}>
            <Grid item xs={3}>
              <Field
                name={`${invoiceProduct}.invoiceProductDescription`}
                label="商品品名"
                component={TextLoadingField}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name={`${invoiceProduct}.invoiceProductQuantity`}
                label="商品數量"
                component={TextLoadingField}
                fullWidth
                normalize={positiveFixed({
                  min: MIN_INVOICE_PRODUCT_QUANTITY
                })}
                variant="outlined"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name={`${invoiceProduct}.invoiceProductUnitPrice`}
                label="單品項價格"
                component={TextLoadingField}
                fullWidth
                normalize={positiveFixed()}
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => fields.remove(index)}
              >
                刪除
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  </React.Fragment>
);

const InvoiceAllowanceForm = ({ handleSubmit, initialValues }) => {
  return (
    <form id={FORM} onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography>買方名稱：{initialValues.get("buyerName")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            買方名稱：{initialValues.get("buyerIdentifier")}
          </Typography>
        </Grid>
      </Grid>
      <FieldArray name="invoiceProductList" component={renderTalkIssueGroups} />
    </form>
  );
};

InvoiceAllowanceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM,
  validate
})(InvoiceAllowanceForm);
