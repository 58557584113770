import React from "react";
import PropTypes from "prop-types";

import { Map } from "immutable";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { makeStyles } from "@material-ui/core/styles";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import { formValueSelector, isInvalid, submit } from "redux-form/immutable";
import { reset } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPostAllowance,
  setConfirmAllowance,
  getSelectedSellerIdentifier,
  getAllowanceInitialValues,
  getAllowanceIsPosting,
  getConfirmAllowance,
  getSelectedInvoice,
} from "./redux";
import makeFormValueIsEqual from "utils/makeFormValueIsEqual";
import { ccyformat, calcInvoiceAmounts } from "./utils";
import { DIALOG as INVOICE_DIALOG } from "./InvoiceDialog";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import InvoiceAllowanceForm, { FORM } from "./InvoiceAllowanceForm";

import ResetIcon from "@material-ui/icons/Replay";

export const DIALOG = "invoiceAllowanceDialog";
export const CONFIRM_LEAVE_DIALOG = "confirmLeaveInvoiceAllowanceDialog";

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)(ConfirmDialog);

const selector = formValueSelector(FORM);
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const useFormValueIsEqual = makeFormValueIsEqual(FORM);

const InvoiceAllowanceDialog = function InvoiceAllowanceDialog(props) {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEqual = useFormValueIsEqual();
  const buyerName = useSelector((state) => selector(state, "buyerName"));
  const buyerIdentifier = useSelector((state) =>
    selector(state, "buyerIdentifier")
  );
  const invoiceProductList = useSelector((state) =>
    selector(state, "invoiceProductList")
  );
  const formIsInvalid = useSelector((state) => isInvalid(FORM)(state));
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const selectedInvoice = useSelector(getSelectedInvoice);
  const allowanceInitialValues = useSelector(getAllowanceInitialValues);
  const isLoading = useSelector(getAllowanceIsPosting);
  const confirmAllowance = useSelector(getConfirmAllowance);

  const closeConform = () => {
    if (!isEqual || confirmAllowance) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  const handleSubmit = (values) => {
    const allowanceProductList = values
      .get("invoiceProductList")
      .map((el) =>
        el
          .deleteAll([
            "invoiceId",
            "invoiceProductDescription",
            "invoiceProductQuantity",
            "invoiceProductUnitPrice",
            "invoiceProductAmount",
            "invoiceProductSequenceNumber",
          ])
          .set(
            "allowanceProductOriginalSequenceNumber",
            el.get("invoiceProductSequenceNumber")
          )
          .set(
            "allowanceProductOriginalDescription",
            el.get("invoiceProductDescription")
          )
          .set("allowanceProductQuantity", el.get("invoiceProductQuantity"))
          .set("allowanceProductUnitPrice", el.get("invoiceProductUnitPrice"))
      );
    const payload = Map({
      invoiceId: values.get("invoiceId"),
      buyerIdentifier: values.get("buyerIdentifier"),
      buyerName: values.get("buyerName"),
      allowanceProductList: allowanceProductList,
    });
    dispatch(
      fetchPostAllowance({
        sellerIdentifier: selectedSellerIdentifier,
        ...payload.toJS(),
      })
    );
  };

  const renderCheckStep = () => {
    if (formIsInvalid) {
      return <Typography>請先輸入折讓商品</Typography>;
    }
    if (!invoiceProductList) {
      return undefined;
    }
    const taxIncluded = selectedInvoice.get("taxableType") === "taxIncluded";
    const [
      calcedList,
      salesAmount,
      taxAmount,
      invoiceAmount,
    ] = calcInvoiceAmounts(invoiceProductList, taxIncluded);

    return (
      <React.Fragment>
        <Typography>買方名稱：{buyerName}</Typography>
        <Typography>買方統編：{buyerIdentifier}</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>商品品名</TableCell>
                <TableCell align="right">商品數量</TableCell>
                <TableCell align="right">單品項價格(未稅)</TableCell>
                <TableCell align="right">小計(未稅)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calcedList.map((invoiceProduct, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {invoiceProduct.get("invoiceProductDescription")}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("invoiceProductQuantity"))}{" "}
                      {invoiceProduct.get("invoiceProductUnit")}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("invoiceProductUnitPrice"))}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("subtotal"))}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell
                  rowSpan={3}
                  colSpan={2}
                  style={{ borderBottom: 0 }}
                />
                <TableCell>銷售額合計</TableCell>
                <TableCell align="right">
                  {ccyformat(salesAmount, { round: true })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>營業稅</TableCell>
                <TableCell align="right">
                  {ccyformat(taxAmount, { round: true })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>總計</TableCell>
                <TableCell align="right">
                  {ccyformat(invoiceAmount, { round: true })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  };

  const renderActions = () => {
    if (confirmAllowance) {
      return (
        <React.Fragment>
          <Button
            MuiButtonProps={{
              variant: "outlined",
              color: "primary",
              onClick: () => dispatch(setConfirmAllowance(false)),
            }}
          >
            上一步
          </Button>
          <Button
            loading={isLoading}
            MuiButtonProps={{
              type: "submit",
              variant: "contained",
              color: "primary",
              className: classes.button,
              onClick: () => dispatch(submit(FORM)),
            }}
          >
            送出
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          MuiButtonProps={{
            variant: "outlined",
            color: "primary",
            onClick: () => {
              handleClose();
              dispatch(openDialog(INVOICE_DIALOG));
            },
          }}
        >
          返回發票內容
        </Button>
        <Button
          MuiButtonProps={{
            variant: "contained",
            color: "primary",
            className: classes.button,
            onClick: () => dispatch(setConfirmAllowance(true)),
          }}
        >
          確認折讓內容
        </Button>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ConfirmLeaveDialog
        title="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={closeConform}>
        <DialogTitle>折讓發票</DialogTitle>
        <DialogContent>
          <Box px={3}>
            <Box display={confirmAllowance ? "none" : "block"}>
              <InvoiceAllowanceForm
                initialValues={allowanceInitialValues}
                onSubmit={handleSubmit}
              />
              <Box mt={1}>
                <Button
                  MuiButtonProps={{
                    variant: "outlined",
                    color: "primary",
                    endIcon: <ResetIcon />,
                    onClick: () => dispatch(reset(FORM)),
                  }}
                >
                  重新輸入
                </Button>
              </Box>
            </Box>
            <Box display={confirmAllowance ? "block" : "none"}>
              {renderCheckStep()}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>{renderActions()}</DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

InvoiceAllowanceDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceAllowanceDialog);
