import { createSelector } from "reselect";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";
import { Map } from "immutable";

/** Actions */
export const {
  fetchGetDocumentVersion,
  fetchGetDocumentVersionRequest,
  fetchGetDocumentVersionSuccess,
  fetchGetDocumentVersionFailure,
  clearDocumentVersion,

  fetchPatchDocument,
  fetchPatchDocumentRequest,
  fetchPatchDocumentSuccess,
  fetchPatchDocumentFailure,
} = actionCreators.pages.documentVersion;

/** Types */
export const FETCH_GET_DOCUMENT_VERSION = fetchGetDocumentVersion().type;
export const FETCH_GET_DOCUMENT_VERSION_REQUEST = fetchGetDocumentVersionRequest()
  .type;
export const FETCH_GET_DOCUMENT_VERSION_SUCCESS = fetchGetDocumentVersionSuccess()
  .type;
export const FETCH_GET_DOCUMENT_VERSION_FAILURE = fetchGetDocumentVersionFailure()
  .type;
export const CLEAR_DOCUMENT_VERSION = clearDocumentVersion().type;

export const FETCH_PATCH_DOCUMENT = fetchPatchDocument().type;
export const FETCH_PATCH_DOCUMENT_REQUEST = fetchPatchDocumentRequest().type;
export const FETCH_PATCH_DOCUMENT_SUCCESS = fetchPatchDocumentSuccess().type;
export const FETCH_PATCH_DOCUMENT_FAILURE = fetchPatchDocumentFailure().type;

/** Selectors **/
const getUiStates = (state) => state.getIn(["pages", "DocumentVersion"]);

export const getDocumentVersion = createSelector(
  getUiStates,
  getEntities,
  (uiStates, entities) => {
    if (uiStates.has("response")) {
      return denormalize(
        uiStates.get("response"),
        schema.documentVersion,
        entities
      );
    }
    return undefined;
  }
);

const getApiStates = (state) =>
  state.getIn(["apis", "components", "documentVersion"], Map());

const getFetchPatchDocument = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPatchDocument", Map())
);

export const getDocumentIsPatching = createSelector(
  getFetchPatchDocument,
  (fetchPatch) => fetchPatch.get("isLoading")
);
