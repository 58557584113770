import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchGetOrganization,
  fetchGetOrganizationRequest,
  fetchGetOrganizationSuccess,
  fetchGetOrganizationFailure,

  fetchPatchOrganization,
  fetchPatchOrganizationRequest,
  fetchPatchOrganizationSuccess,
  fetchPatchOrganizationFailure,
} = actionCreators.pages.organization;

/** Types */

export const FETCH_GET_ORGANIZATION = fetchGetOrganization().type;
export const FETCH_GET_ORGANIZATION_REQUEST = fetchGetOrganizationRequest()
  .type;
export const FETCH_GET_ORGANIZATION_SUCCESS = fetchGetOrganizationSuccess()
  .type;
export const FETCH_GET_ORGANIZATION_FAILURE = fetchGetOrganizationFailure()
  .type;

export const FETCH_PATCH_ORGANIZATION = fetchPatchOrganization().type;
export const FETCH_PATCH_ORGANIZATION_REQUEST = fetchPatchOrganizationRequest()
  .type;
export const FETCH_PATCH_ORGANIZATION_SUCCESS = fetchPatchOrganizationSuccess()
  .type;
export const FETCH_PATCH_ORGANIZATION_FAILURE = fetchPatchOrganizationFailure()
  .type;

/**
 * Selectors
 */
const getApiStates = (state) =>
  state.getIn(["apis", "pages", "organization"], Map());

const getFetchGetOrganization = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetOrganization", Map())
);

const getFetchPatchOrganization = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPatchOrganization", Map())
);

export const getOrganization = createSelector(
  getFetchGetOrganization,
  getEntities,
  (fetchGet, entities) =>
    denormalize(fetchGet.get("response"), schema.organization, entities)
);

export const getIsLoading = createSelector(
  getFetchGetOrganization,
  (fetchGet) => fetchGet.get("isLoading")
);

export const getIsPatching = createSelector(
  getFetchPatchOrganization,
  (fetchGet) => fetchGet.get("isLoading")
);
