import { combineEpics } from "redux-observable";
import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import { reducer as formReducer } from "redux-form/immutable";

import { reducer as dialogs } from "@e-group/redux-modules/immutable/dialogs";
import { reducer as snackbars } from "@e-group/redux-modules/immutable/snackbars";
import { reducer as entities } from "@e-group/redux-modules/immutable/entities";
import { reducer as apis } from "@e-group/redux-modules/immutable/apis";
import { components, componentsEpics } from "./components";
import { pages, pagesEpics } from "./pages";

export const rootEpic = combineEpics(componentsEpics, pagesEpics);

// root reducer
export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    dialogs,
    snackbars,
    entities,
    apis,
    components,
    pages,
  });
