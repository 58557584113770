import { of, concat } from 'rxjs';
// import { replace } from 'connected-react-router/immutable';
import getDeviceInfo from '@e-group/utils/getDeviceInfo';
import { openDialog, setDialogData } from '@e-group/redux-modules/dialogs';
import { DIALOG } from 'App';

import apis from './apis';

export default function apiErrorsHandler(error, { state$, action }) {
  let payload = {
    function: 'apiErrorsHandler',
    browserDescription: window.navigator.userAgent,
    jsonData: {
      action,
      store: state$.value.toJS(),
      deviceInfo: getDeviceInfo()
    },
    level: 'ERROR'
  };

  // handle server side errors
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 401: {
        return concat(
          of(
            setDialogData({
              name: DIALOG,
              title: '帳號密碼錯誤',
              message: '帳號密碼錯誤請再輸入一次。'
            })
          ),
          of(openDialog(DIALOG))
        );
      }
      // case 403: {
      //   document.cookie =
      //     'lid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      //   document.cookie =
      //     'tid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      //   document.cookie =
      //     'u_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      //   return concat(
      //     of(replace('/login')),
      //     of(
      //       setDialogData({
      //         name: DIALOG,
      //         title: '登入逾時',
      //         message: '您的登入狀態已經逾時請重新登入一次。'
      //       })
      //     ),
      //     of(openDialog(DIALOG))
      //   );
      // }
      case 409: {
        return concat(
          of(
            setDialogData({
              name: DIALOG,
              title: '此帳號已被註冊',
              message: '此帳號已被註冊，請使用另外一組帳號'
            })
          ),
          of(openDialog(DIALOG))
        );
      }
      // log server side errors
      default:
        apis.tools.fetchPostLogs(payload);
        return of();
    }
  }

  // handle client side errors
  payload = {
    ...payload,
    message: error.message
  };
  apis.tools.fetchPostLogs(payload);
  return of();
}
