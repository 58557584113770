import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form/immutable";

import TextLoadingField from "@e-group/material-form/TextLoadingField";

export const form = "documentAddForm";

const validate = values => {
  const errors = {};
  if (!values.get("name")) {
    errors.name = "必填";
  }
  return errors;
};

const DocumentAddForm = ({ handleSubmit }) => {
  return (
    <form id={form} onSubmit={handleSubmit}>
      <Field
        name="documentTitle"
        label="名稱"
        autoFocus
        required
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
    </form>
  );
};

DocumentAddForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form,
  validate
})(DocumentAddForm);
