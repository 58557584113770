import React from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import displayValues from "@e-group/utils/displayValues";
import { openDialog } from "@e-group/redux-modules/dialogs";
import {
  fetchDeleteInvoice,
  fetchPatchInvoice,
  fetchPostCopyInvoice,
  getInvoiceIsDeleting,
  getSelectedSellerIdentifier,
  getSelectedInvoice,
  getAllowanceInitialValues,
  getInvoiceIsCopying,
  setConfirmAllowance,
} from "./redux";
import { DIALOG as EDIT_DIALOG } from "./InvoiceEditDialog";
import { DIALOG as ALLOWANCE_DIALOG } from "./InvoiceAllowanceDialog";
import { ccyformat, calcInvoiceAmounts } from "./utils";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

export const DIALOG = "invoiceDialog";
export const CONFIRM_DELETE_DIALOG = "confirmDeleteInvoiceDialog";

const ConfirmDeleteDialog = withReduxDialog(CONFIRM_DELETE_DIALOG)(
  ConfirmDialog
);

const InvoiceDialog = React.forwardRef(function InvoiceDialog(props, ref) {
  const { isOpen, handleClose } = props;
  const dispatch = useDispatch();
  const [cancelReason, setCancelReason] = React.useState("");
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const selectedInvoice = useSelector(getSelectedInvoice);
  const allowanceInitialValues = useSelector(getAllowanceInitialValues);
  const invoiceIsDeleting = useSelector(getInvoiceIsDeleting);
  const invoiceIsCopying = useSelector(getInvoiceIsCopying);

  const invoiceStatus = selectedInvoice
    ? selectedInvoice.get("invoiceStatus")
    : "";
  const displayDeleteButton =
    invoiceStatus === "已開立" || invoiceStatus === "未開立";
  const displayEditButton = invoiceStatus === "未開立";
  const displayAllowanceButton =
    (invoiceStatus === "已開立" || invoiceStatus === "已開立(折讓)") &&
    allowanceInitialValues.get("invoiceProductList").size > 0;

  const getDeleteButtonText = () => {
    if (!selectedInvoice) {
      return undefined;
    }
    switch (selectedInvoice.get("invoiceStatus")) {
      case "已開立":
        return "作廢發票";
      case "未開立":
        return "刪除發票";
      default:
        return "刪除發票";
    }
  };

  // A0501 will be used if the invoice has been allowanced.
  const handleDelete = () => {
    if (invoiceStatus === "未開立") {
      dispatch(
        fetchDeleteInvoice({
          sellerIdentifier: selectedSellerIdentifier,
          invoiceId: selectedInvoice.get("invoiceId"),
        })
      );
    } else {
      dispatch(
        fetchPatchInvoice({
          sellerIdentifier: selectedInvoice.get("sellerIdentifier"),
          invoiceId: selectedInvoice.get("invoiceId"),
          cancelReason,
          eInvoiceMessage: "A0501",
        })
      );
    }
  };

  const handleChange = (e) => {
    setCancelReason(e.target.value);
  };

  const renderContent = () => {
    if (!selectedInvoice) {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress />
        </Box>
      );
    }
    return displayValues(
      [
        {
          key: "發票號碼",
          value: selectedInvoice.get("invoiceNumber"),
        },
        {
          key: "發票期別",
          value: selectedInvoice.get("yearMonth"),
        },
        {
          value: selectedInvoice.has("invoiceDate"),
          render: (index) => {
            return (
              <Grid xs={4} item key="發票開立日期">
                <Typography variant="body1" gutterBottom component="div">
                  發票開立日期：
                  {moment(selectedInvoice.get("invoiceDate")).format(
                    "YYYY-MM-DD"
                  )}{" "}
                  {selectedInvoice.get("invoiceTime")}
                </Typography>
              </Grid>
            );
          },
        },
        {
          value: selectedInvoice.has("transactionUpdateDateString"),
          render: (index) => {
            return (
              <Grid xs={4} item key="上次編輯時間">
                <Typography variant="body1" gutterBottom component="div">
                  上次編輯時間：
                  {selectedInvoice.get("transactionUpdateDateString")}
                </Typography>
              </Grid>
            );
          },
        },
        {
          key: "發票防偽隨機碼",
          value: selectedInvoice.get("invoiceRandomNumber"),
        },
        {
          key: "發票狀態",
          value: selectedInvoice.get("invoiceStatus"),
        },
        {
          key: "發票類別",
          value: selectedInvoice.get("invoiceType"),
        },
        {
          key: "應稅類別",
          value:
            selectedInvoice.get("taxableType") === "taxIncluded"
              ? "應稅內含"
              : "應稅外加",
        },
        {
          key: "賣方統編",
          value: selectedInvoice.get("sellerIdentifier"),
        },
        {
          key: "買方統編",
          value: selectedInvoice.get("buyerIdentifier"),
        },
        {
          key: "買方名稱",
          value: selectedInvoice.get("buyerName"),
        },
        {
          key: "買方地址",
          value: selectedInvoice.get("buyerAddress"),
        },
        {
          key: "作廢發票號碼",
          value: selectedInvoice.get("cancelInvoiceNumber"),
        },
        {
          key: "作廢日期",
          value: selectedInvoice.get("cancelDate"),
        },
        {
          key: "作廢時間",
          value: selectedInvoice.get("cancelTime"),
        },
        {
          key: "作廢原因",
          value: selectedInvoice.get("cancelReason"),
        },
        {
          key: "電子商務模式",
          value: selectedInvoice.get("invocieECommerceModel"),
        },
        {
          key: "總備註",
          value: selectedInvoice.get("invoiceMainRemark"),
        },
        {
          value: selectedInvoice.has("invoiceProveFilePath"),
          render: () => {
            return (
              <Grid xs={4} item key="證明聯下載">
                證明聯下載：
                <Link
                  href={`${selectedInvoice.get("invoiceProveFilePath")}`}
                  target="_blank"
                >
                  證明聯下載
                </Link>
              </Grid>
            );
          },
        },
      ],
      ({ key, value }) => (
        <Grid xs={4} item key={key}>
          <Typography variant="body1" gutterBottom component="div">
            {key}：{value}
          </Typography>
        </Grid>
      )
    );
  };

  const renderProduct = () => {
    if (!selectedInvoice) {
      return <div />;
    }

    const taxIncluded = selectedInvoice.get("taxableType") === "taxIncluded";
    const invoiceProductList = selectedInvoice.get("invoiceProductList");
    const [
      calcedList,
      salesAmount,
      taxAmount,
      invoiceAmount,
    ] = calcInvoiceAmounts(invoiceProductList, taxIncluded);

    return (
      <Box border="solid 1px gray" px={1} mt={2} width="inherit">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              商品明細
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ marginBottom: "15px" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>商品品名</TableCell>
                    <TableCell align="right">商品數量</TableCell>
                    <TableCell align="right">單品項價格(未稅)</TableCell>
                    <TableCell align="right">小計(未稅)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calcedList.map((invoiceProduct, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductDescription")}
                          {invoiceProduct.get("invoiceProductRemark")
                            ? `(${invoiceProduct.get("invoiceProductRemark")})`
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {ccyformat(
                            invoiceProduct.get("invoiceProductQuantity")
                          )}{" "}
                          {invoiceProduct.get("invoiceProductUnit")}
                        </TableCell>
                        <TableCell align="right">
                          {ccyformat(
                            invoiceProduct.get("invoiceProductUnitPrice")
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {ccyformat(invoiceProduct.get("subtotal"))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      colSpan={2}
                      style={{ borderBottom: 0 }}
                    />
                    <TableCell>銷售額合計</TableCell>
                    <TableCell align="right">
                      {ccyformat(salesAmount, { round: true })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>營業稅</TableCell>
                    <TableCell align="right">
                      {ccyformat(taxAmount, { round: true })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>總計</TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceAmount, { round: true })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderAllowance = () => {
    if (
      selectedInvoice &&
      selectedInvoice.has("allowanceList") &&
      selectedInvoice.get("allowanceList").size
    ) {
      return (
        <Box border="solid 1px gray" px={1} mt={2} width="inherit">
          <Grid xs={12} item>
            <Divider />
            <Typography variant="h6" gutterBottom component="div">
              折讓紀錄
            </Typography>
          </Grid>
          {selectedInvoice.get("allowanceList").map((el) => (
            <React.Fragment key={el.get("allowanceNumber")}>
              <Typography variant="body1" gutterBottom component="div">
                折讓編號：{el.get("allowanceNumber")}
              </Typography>
              <Typography variant="body1" gutterBottom component="div">
                折讓日期：{el.get("allowanceDate")}
              </Typography>
              <Link
                variant="body1"
                gutterBottom
                href={el.get("allowanceProveFilePath")}
                download
                target="_blank"
              >
                折讓證明聯下載
              </Link>
              <TableContainer style={{ marginBottom: "15px" }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>商品品名</TableCell>
                      <TableCell align="right">商品數量</TableCell>
                      <TableCell align="right">單品項價格</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {el.get("allowanceProductList").map((el) => (
                      <TableRow key={el.get("allowanceProductId")}>
                        <TableCell>
                          {el.get("allowanceProductOriginalDescription")}
                        </TableCell>
                        <TableCell align="right">
                          {ccyformat(el.get("allowanceProductQuantity"))}
                        </TableCell>
                        <TableCell align="right">
                          {ccyformat(el.get("allowanceProductUnitPrice"))}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell rowSpan={2} style={{ borderBottom: 0 }} />
                      <TableCell>稅額</TableCell>
                      <TableCell align="right">
                        {ccyformat(el.get("invoiceTaxAmount"))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>總計</TableCell>
                      <TableCell align="right">
                        {ccyformat(el.get("invoiceTotalAmount"))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <ConfirmDeleteDialog
        title={`確認${getDeleteButtonText()}`}
        message={
          <React.Fragment>
            <Typography>
              {getDeleteButtonText()}後將無法還原，請輸入作廢原因。
            </Typography>
            <TextField
              onChange={handleChange}
              value={cancelReason}
              autoFocus
              fullWidth
            />
          </React.Fragment>
        }
        onConfirm={handleDelete}
        MuiDialogContentTextProps={{
          component: "div",
        }}
        MuiConfirmButtonProps={{
          disabled: cancelReason === "",
        }}
      />
      <Dialog
        maxWidth="lg"
        fullWidth
        open={isOpen}
        onClose={() => handleClose()}
      >
        <DialogTitle>發票內容</DialogTitle>
        <DialogContent>
          <Grid container>
            {renderContent()}
            {renderProduct()}
            {renderAllowance()}
          </Grid>
        </DialogContent>
        <DialogActions>
          {displayEditButton && (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                disabled: invoiceIsDeleting,
                onClick: () => {
                  dispatch(openDialog(EDIT_DIALOG));
                  handleClose();
                },
              }}
            >
              編輯發票
            </Button>
          )}
          <Button
            loading={invoiceIsCopying}
            MuiButtonProps={{
              variant: "outlined",
              color: "primary",
              onClick: () => {
                dispatch(
                  fetchPostCopyInvoice({
                    sellerIdentifier: selectedSellerIdentifier,
                    invoiceId: selectedInvoice.get("invoiceId"),
                  })
                );
              },
            }}
          >
            複製發票
          </Button>
          {displayAllowanceButton && (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                disabled: invoiceIsDeleting,
                onClick: () => {
                  dispatch(setConfirmAllowance(false));
                  dispatch(openDialog(ALLOWANCE_DIALOG));
                  handleClose();
                },
              }}
            >
              折讓發票
            </Button>
          )}
          {displayDeleteButton && (
            <Button
              loading={invoiceIsDeleting}
              MuiButtonProps={{
                variant: "outlined",
                color: "secondary",
                onClick: () => {
                  dispatch(openDialog(CONFIRM_DELETE_DIALOG));
                },
              }}
            >
              {getDeleteButtonText()}
            </Button>
          )}
          <Button
            MuiButtonProps={{
              variant: "outlined",
              onClick: handleClose,
              disabled: invoiceIsDeleting,
            }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

InvoiceDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceDialog);
