import React from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import shortUsername from "@e-group/utils/shortUsername";
import stringToColor from "@e-group/utils/stringToColor";
import useUserInfo from "utils/useUserInfo";
import { fetchPostLogout } from "./redux";

import ButtonMenu from "@e-group/material-lab/ButtonMenu";
import MenuItem from "@material-ui/core/MenuItem";
import StyledAvatar from "components/StyledAvatar";

const UserAvatarMenu = ({ id }) => {
  const dispatch = useDispatch();
  const [userInfo] = useUserInfo();
  return (
    <ButtonMenu
      id={id}
      button={
        <StyledAvatar
          style={{
            backgroundColor: stringToColor(userInfo.loginName),
            cursor: "pointer"
          }}
        >
          {shortUsername(userInfo.loginName)}
        </StyledAvatar>
      }
    >
      <MenuItem
        onClick={() => {
          dispatch(fetchPostLogout());
        }}
      >
        登出
      </MenuItem>
    </ButtonMenu>
  );
};

UserAvatarMenu.propTypes = {
  id: PropTypes.string.isRequired
};

export default UserAvatarMenu;
