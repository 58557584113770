import { combineReducers } from "redux-immutable";
import { combineEpics } from "redux-observable";

import Document from "pages/Document/reducers";
import DocumentVersion from "pages/DocumentVersion/reducers";
import B2BInvoices from "pages/Invoices/B2BInvoices/reducers";
import B2CInvoices from "pages/Invoices/B2CInvoices/reducers";
import InvoicePeriods from "pages/Invoices/InvoicePeriods/reducers";
import Feedbacks from "pages/Feedbacks/reducers";

import OrganizationsEpics from "pages/Organizations/epics";
import OrganizationEpics from "pages/Organization/epics";
import DocumentEpics from "pages/Document/epics";
import DocumentsEpics from "pages/Documents/epics";
import DocumentVersionEpics from "pages/DocumentVersion/epics";
import FeedbacksEpics from "pages/Feedbacks/epics";
import B2BInvoicesEpics from "pages/Invoices/B2BInvoices/epics";
import B2CInvoicesEpics from "pages/Invoices/B2CInvoices/epics";
import InvoicePeriodsEpics from "pages/Invoices/InvoicePeriods/epics";

import LoginEpics from "landingPages/Login/epics";
import RegisterEpics from "landingPages/Register/epics";

export const pages = combineReducers({
  Document,
  DocumentVersion,
  B2BInvoices,
  B2CInvoices,
  InvoicePeriods,
  Feedbacks,
});

export const pagesEpics = combineEpics(
  OrganizationsEpics,
  OrganizationEpics,
  DocumentEpics,
  DocumentsEpics,
  DocumentVersionEpics,
  FeedbacksEpics,
  B2BInvoicesEpics,
  B2CInvoicesEpics,
  InvoicePeriodsEpics,
  LoginEpics,
  RegisterEpics
);
