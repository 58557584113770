import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import FixedCenter from '../FixedCenter';

const ApiErrorBoundaries = ({ error }) => {
  const response = error.response;
  const status = response ? response.status : 'unknown error';

  if (status === 404) {
    return (
      <FixedCenter>
        <div>
          <Typography variant="h4" gutterBottom align="center">
            404
          </Typography>
          <Typography variant="h6" align="center">
            此頁面不存在
          </Typography>
        </div>
      </FixedCenter>
    );
  }
  if (status === 403) {
    return (
      <FixedCenter>
        <div>
          <Typography variant="h6" align="center">
            您沒有存取權限
          </Typography>
        </div>
      </FixedCenter>
    );
  }
  return (
    <FixedCenter>
      <Typography variant="h6">{status}</Typography>
    </FixedCenter>
  );
};

ApiErrorBoundaries.propTypes = {
  error: PropTypes.object.isRequired
};

export default ApiErrorBoundaries;
