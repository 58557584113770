import React from "react";
import PropTypes from "prop-types";

import { Map } from "immutable";
import { reduxForm, Field, FieldArray } from "redux-form/immutable";
import { useSelector, useDispatch } from "react-redux";
import { getFormValues } from "redux-form/immutable";
import {
  fetchGetNpos,
  fetchGetMembers,
  getSelectedSellerIdentifier,
  getNposOption,
  getMembersOption,
} from "./redux";
import apis from "redux/apis";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextLoadingField from "@e-group/material-form/TextLoadingField";
import ReactSelectField from "@e-group/material-form/ReactSelectField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const FORM = "invoiceEditForm";

const MIN_INVOICE_PRODUCT_QUANTITY = 1;

const validate = (values) => {
  const errors = {};
  let totalAmount = 0;
  if (!values.get("buyerName")) {
    if (values.get("invoiceResultType") !== 6) {
      errors.buyerName = "「買方名稱」為必填";
    }
  } else {
    if (values.get("buyerName").length > 60) {
      errors.buyerName = "「買受人名稱」長度必須小於60個字";
    }
  }
  if (values.get("buyerAddress") && values.get("buyerAddress").length > 100) {
    errors.buyerAddress = "「地址」長度必須小於100個字";
  }
  if (!values.get("buyerIdentifier")) {
    errors.buyerIdentifier = "「買方統編」為必填";
  } else {
    if (values.get("buyerIdentifier").length !== 8) {
      errors.buyerIdentifier = "「買方統編」長度必須為8";
    }
  }
  if (!values.get("citizenCarrierId")) {
    errors.citizenCarrierId = "「自然人憑證條碼」為必填";
  } else {
    if (!/^[A-Z]{2}[0-9]{14}$/.test(values.get("citizenCarrierId"))) {
      errors.citizenCarrierId = "「自然人憑證條碼」格式錯誤";
    }
  }
  if (
    !values.has("invoiceProductList") ||
    values.get("invoiceProductList").size === 0
  ) {
    errors.invoiceProductList = { _error: "至少需要新增一筆商品" };
  } else {
    const listErrors = [];
    values.get("invoiceProductList").forEach((invoiceProduct, index) => {
      const fieldError = {};
      if (!invoiceProduct.has("invoiceProductDescription")) {
        fieldError.invoiceProductDescription = "「商品品名」為必填";
      }
      if (!invoiceProduct.has("invoiceProductQuantity")) {
        fieldError.invoiceProductQuantity = "「商品數量」為必填";
      } else {
        if (
          invoiceProduct.get("invoiceProductQuantity") <
          MIN_INVOICE_PRODUCT_QUANTITY
        ) {
          fieldError.invoiceProductQuantity = `「商品數量」不得小於 ${MIN_INVOICE_PRODUCT_QUANTITY}`;
        }
      }
      if (!invoiceProduct.has("invoiceProductUnitPrice")) {
        fieldError.invoiceProductUnitPrice = "「單品項價格」為必填";
      }
      if (!invoiceProduct.has("invoiceProductType")) {
        fieldError.invoiceProductType = "「類型」為必填";
      }
      totalAmount =
        totalAmount +
        invoiceProduct.get("invoiceProductQuantity") *
          invoiceProduct.get("invoiceProductUnitPrice");
      if (totalAmount > 100000000) {
        fieldError.invoiceProductUnitPrice =
          "總金額已超過限制金額:$100,000,000";
      }
      listErrors[index] = fieldError;
    });
    if (listErrors.length) {
      errors.invoiceProductList = listErrors;
    }
  }
  return errors;
};

const asyncValidate = (values) => {
  if (values.get("invoiceResultType") === 2 && !values.get("phoneCarrierId")) {
    return new Promise((resolve, reject) => {
      reject("「手機條碼」為必填");
    }).catch(() => {
      const error = {
        phoneCarrierId: "「手機條碼」為必填",
      };
      throw error;
    });
  } else if (
    values.get("invoiceResultType") === 2 &&
    !/^\/{1}[0-9A-Z+-.]{7}$/.test(values.get("phoneCarrierId"))
  ) {
    return new Promise((resolve, reject) => {
      reject("「手機條碼」格式錯誤");
    }).catch(() => {
      const error = {
        phoneCarrierId: "「手機條碼」格式錯誤",
      };
      throw error;
    });
  } else if (values.get("invoiceResultType") === 2) {
    return apis.b2cApis
      .fetchPostCheckPhoneBarcode({
        barcode: values.get("phoneCarrierId"),
      })
      .then((response) => {
        if (response.data === false) {
          const error = {
            phoneCarrierId: "「手機條碼」未註冊，請輸入正確的手機條瑪",
          };
          throw error;
        }
      });
  }
  return new Promise((resolve, reject) => {
    reject("「手機條碼」格式錯誤");
  }).catch(() => {
    console.log("no content");
  });
};

const positiveFixed = ({ min = 0 } = {}) => (
  value,
  previousValue,
  allValues
) => {
  let result = Number(value).toFixed();
  if (result < min) result = min;
  return result;
};

const renderTalkIssueGroups = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    <Button
      color="primary"
      variant="outlined"
      onClick={() => fields.push(Map())}
    >
      新增商品
    </Button>
    {submitFailed && error && (
      <Typography color="secondary">{error}</Typography>
    )}
    <Box mt={1}>
      {fields.map((invoiceProduct, index) => {
        return (
          <Grid container key={index} spacing={1}>
            <Grid item xs={2}>
              <Field
                name={`${invoiceProduct}.invoiceProductDescription`}
                label="商品品名*"
                component={TextLoadingField}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name={`${invoiceProduct}.invoiceProductQuantity`}
                label="商品數量*"
                component={TextLoadingField}
                fullWidth
                normalize={positiveFixed({
                  min: MIN_INVOICE_PRODUCT_QUANTITY,
                })}
                variant="outlined"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item>
              <Field
                name={`${invoiceProduct}.invoiceProductUnit`}
                label="商品單位"
                component={TextLoadingField}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name={`${invoiceProduct}.invoiceProductUnitPrice`}
                label="單品項價格(已含稅)*"
                component={TextLoadingField}
                fullWidth
                normalize={positiveFixed()}
                variant="outlined"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item>
              <Field
                name={`${invoiceProduct}.invoiceProductRemark`}
                label="備註(限20字)"
                component={TextLoadingField}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={0}>
              <Field
                label="類型*"
                component={TextLoadingField}
                name={`${invoiceProduct}.invoiceProductType`}
                fullWidth
                select
                variant="outlined"
                size="small"
              >
                <MenuItem value="外部-社工課">外部-社工課</MenuItem>
                <MenuItem value="外部-學員">外部-學員</MenuItem>
                <MenuItem value="社工自訓">社工自訓</MenuItem>
                <MenuItem value="專案">專案</MenuItem>
                <MenuItem value="教材">教材</MenuItem>
                <MenuItem value="諮詢">諮詢</MenuItem>
                <MenuItem value="諮詢(EAP)">諮詢(EAP)</MenuItem>
                <MenuItem value="其他">其他</MenuItem>
              </Field>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => fields.remove(index)}
                size="small"
                disabled={fields.length === 1}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  </React.Fragment>
);

const InvoiceEditForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const values = useSelector(getFormValues(FORM));
  const nposOption = useSelector(getNposOption);
  const membersOption = useSelector(getMembersOption);

  const handleNposInputChange = (value) => {
    if (value === "") return;
    dispatch(
      fetchGetNpos({
        query: value,
      })
    );
  };

  const handleMembersInputChange = (value) => {
    if (value === "") return;
    dispatch(
      fetchGetMembers({
        sellerIdentifier: selectedSellerIdentifier,
        query: value,
      })
    );
  };

  const Option = (props) => {
    const {
      userName,
      userEmail,
      userPhone,
      invoiceNPOBAN,
      invoiceNPOId,
      invoiceNPOName,
    } = props.data;
    if (values && values.get("invoiceResultType") === 1) {
      return (
        <ListItem
          buttonRef={props.innerRef}
          selected={props.isFocused}
          button
          style={{
            fontWeight: props.isSelected ? 500 : 400,
          }}
          {...props.innerProps}
        >
          <ListItemText
            primary={userName}
            secondary={`Email：${userEmail} 手機：${
              userPhone !== undefined ? userPhone : "無"
            }`}
          />
        </ListItem>
      );
    }
    if (values && values.get("invoiceResultType") === 4) {
      return (
        <ListItem
          buttonRef={props.innerRef}
          selected={props.isFocused}
          button
          style={{
            fontWeight: props.isSelected ? 500 : 400,
          }}
          {...props.innerProps}
        >
          <ListItemText
            primary={invoiceNPOName}
            secondary={`統編：${invoiceNPOId} 捐贈碼：${invoiceNPOBAN}`}
          />
        </ListItem>
      );
    }
  };

  const renderInvoiceWay = () => {
    if (values && values.get("invoiceResultType") === 1) {
      return (
        <Field
          name="memberCarrierId"
          component={ReactSelectField}
          options={membersOption.toJS()}
          isClearable
          onInputChange={handleMembersInputChange}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          MuiTextFieldProps={{
            label: "* 輸入姓名、手機、Email搜尋會員",
            variant: "outlined",
            fullWidth: true,
            size: "small",
            margin: "normal",
          }}
          components={{ Option }}
        />
      );
    }
    if (values && values.get("invoiceResultType") === 2) {
      return (
        <Field
          name="phoneCarrierId"
          label="* 輸入手機條碼(第一碼必為【/】，其餘七碼由【0-9】、【A-Z】與特殊符號【.】【-】【+】組成)"
          component={TextLoadingField}
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
        />
      );
    }
    if (values && values.get("invoiceResultType") === 3) {
      return (
        <Field
          name="citizenCarrierId"
          label="* 輸入自然人憑證條碼(前2碼為大寫英文【A-Z】;後14碼為數字【0-9】)"
          component={TextLoadingField}
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
        />
      );
    }
    if (values && values.get("invoiceResultType") === 4) {
      return (
        <Field
          name="donateCarrierId"
          component={ReactSelectField}
          options={nposOption.toJS()}
          isClearable
          onInputChange={handleNposInputChange}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          MuiTextFieldProps={{
            label: "* 輸入單位名稱、統編、捐贈碼查詢機構",
            variant: "outlined",
            fullWidth: true,
            margin: "normal",
            size: "small",
          }}
          components={{ Option }}
        />
      );
    }
    if (values && values.get("invoiceResultType") === 6) {
      return (
        <Grid item>
          <Field
            name="buyerName"
            label="買受人名稱"
            component={TextLoadingField}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
          <Field
            name="buyerAddress"
            label="地址"
            component={TextLoadingField}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
          />
        </Grid>
      );
    }
  };

  return (
    <form id={FORM} onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Field
            label="發票選項"
            component={TextLoadingField}
            name="invoiceResultType"
            fullWidth
            select
            variant="outlined"
            size="small"
            margin="normal"
          >
            <MenuItem value={1}>會員載具</MenuItem>
            <MenuItem value={2}>手機條碼</MenuItem>
            <MenuItem value={3}>自然人憑證條碼</MenuItem>
            <MenuItem value={4}>捐贈發票</MenuItem>
            <MenuItem value={6}>紙本發票</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={9}>
          {renderInvoiceWay()}
        </Grid>
      </Grid>
      <FieldArray name="invoiceProductList" component={renderTalkIssueGroups} />
    </form>
  );
};

InvoiceEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM,
  validate,
  asyncValidate,
  asyncBlurFields: ["phoneCarrierId"],
})(InvoiceEditForm);
