import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { replace } from "connected-react-router/immutable";
import {
  fetchPostLogoutRequest,
  fetchPostLogoutSuccess,
  fetchPostLogoutFailure,
  FETCH_POST_LOGOUT
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchPostLogoutEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_LOGOUT,
  apiName: "fetchPostLogout",
  fetchRequest: fetchPostLogoutRequest,
  handleSuccess: response => [fetchPostLogoutSuccess(), replace("/login")],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostLogoutFailure(error))
    )
});

export default combineEpics(fetchPostLogoutEpic);
