import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchGetFeedbacks,
  fetchGetFeedbacksRequest,
  fetchGetFeedbacksSuccess,
  fetchGetFeedbacksFailure,
  fetchGetFeedback,
  fetchGetFeedbackRequest,
  fetchGetFeedbackSuccess,
  fetchGetFeedbackFailure,
} = actionCreators.pages.feedbacks;

/** Types */
export const FETCH_GET_FEEDBACKS = fetchGetFeedbacks().type;
export const FETCH_GET_FEEDBACKS_REQUEST = fetchGetFeedbacksRequest().type;
export const FETCH_GET_FEEDBACKS_SUCCESS = fetchGetFeedbacksSuccess().type;
export const FETCH_GET_FEEDBACKS_FAILURE = fetchGetFeedbacksFailure().type;

export const FETCH_GET_FEEDBACK = fetchGetFeedback().type;
export const FETCH_GET_FEEDBACK_REQUEST = fetchGetFeedbackRequest().type;
export const FETCH_GET_FEEDBACK_SUCCESS = fetchGetFeedbackSuccess().type;
export const FETCH_GET_FEEDBACK_FAILURE = fetchGetFeedbackFailure().type;

/**
 * Ui states
 */
const getUiStates = (state) => state.getIn(["pages", "Feedbacks"]);

export const getFeedback = createSelector(
  getUiStates,
  getEntities,
  (uiStates, entities) =>
    denormalize(uiStates.get("feedback"), schema.feedback, entities)
);

/**
 * Selectors
 */
const getApiStates = (state) =>
  state.getIn(["apis", "pages", "feedbacks"], Map());

export const getFetchGetFeedbacks = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetFeedbacks", Map())
);

export const getFeedbacks = createSelector(
  getFetchGetFeedbacks,
  getEntities,
  (fetchGet, entities) =>
    denormalize(
      fetchGet.getIn(["response", "result"]),
      schema.feedbacks,
      entities
    )
);

export const getFeedbacksTotal = createSelector(
  getFetchGetFeedbacks,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getIsLoading = createSelector(getFetchGetFeedbacks, (fetchGet) =>
  fetchGet.get("isLoading")
);
