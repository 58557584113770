import { handleActions } from "redux-actions";
import { Map } from "immutable";
import { FETCH_GET_FEEDBACK_SUCCESS } from "./redux";

const reducer = handleActions(
  {
    [FETCH_GET_FEEDBACK_SUCCESS]: (state, action) =>
      state.set("feedback", action.payload),
  },
  Map({})
);

export default reducer;
