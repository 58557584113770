import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { closeDialog } from "@e-group/redux-modules/dialogs";
import { push } from "connected-react-router/immutable";
import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetDocumentsRequest,
  fetchGetDocumentsSuccess,
  fetchGetDocumentsFailure,
  fetchPostDocumentRequest,
  fetchPostDocumentSuccess,
  fetchPostDocumentFailure,
  FETCH_GET_DOCUMENTS,
  FETCH_POST_DOCUMENT,
} from "./redux";
import { DIALOG } from "./DocumentAddDialog";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetDocumentsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_DOCUMENTS,
  apiName: "eds.fetchGetDocuments",
  fetchRequest: fetchGetDocumentsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.documents
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetDocumentsSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetDocumentsFailure(error))
    ),
});

export const fetchPostDocumentEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_DOCUMENT,
  apiName: "eds.fetchPostDocument",
  fetchRequest: fetchPostDocumentRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.document
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostDocumentSuccess(fromJS(result)),
      closeDialog(DIALOG),
      push(`/documents/${response.data.documentId}`),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostDocumentFailure(error))
    ),
});

export default combineEpics(fetchGetDocumentsEpic, fetchPostDocumentEpic);
