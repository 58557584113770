import React from "react";
import PropTypes from "prop-types";

import { Map } from "immutable";
import { reduxForm, Field, FieldArray } from "redux-form/immutable";
import { MIN_INVOICE_PRODUCT_QUANTITY } from "./config";
import { positive } from "./utils";
import MenuItem from "@material-ui/core/MenuItem";
import TextLoadingField from "@e-group/material-form/TextLoadingField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const FORM = "invoiceEditForm";

const validate = (values) => {
  const errors = {};
  let totalAmount = 0;
  if (!values.get("buyerName")) {
    errors.buyerName = "「買方名稱」為必填";
  }
  if (!values.get("buyerIdentifier")) {
    errors.buyerIdentifier = "「買方統編」為必填";
  } else {
    if (values.get("buyerIdentifier").length !== 8) {
      errors.buyerIdentifier = "「買方統編」長度必須為8";
    }
  }
  if (
    values.has("invoiceProductList") &&
    values.get("invoiceProductList").size > 0
  ) {
    const listErrors = [];
    values.get("invoiceProductList").forEach((invoiceProduct, index) => {
      const fieldError = {};
      if (!invoiceProduct.has("invoiceProductDescription")) {
        fieldError.invoiceProductDescription = "「商品品名」為必填";
      }
      if (!invoiceProduct.has("invoiceProductQuantity")) {
        fieldError.invoiceProductQuantity = "「商品數量」為必填";
      } else {
        if (
          invoiceProduct.get("invoiceProductQuantity") <
          MIN_INVOICE_PRODUCT_QUANTITY
        ) {
          fieldError.invoiceProductQuantity = `「商品數量」不得小於 ${MIN_INVOICE_PRODUCT_QUANTITY}`;
        }
      }
      if (
        invoiceProduct.has("invoiceProductUnit") &&
        invoiceProduct.get("invoiceProductUnit").length > 6
      ) {
        fieldError.invoiceProductUnit = "「單位」不得超過6個字";
      }
      if (!invoiceProduct.has("invoiceProductUnitPrice")) {
        fieldError.invoiceProductUnitPrice = "「單品項價格」為必填";
      }
      if (
        invoiceProduct.has("invoiceProductRemark") &&
        invoiceProduct.get("invoiceProductRemark").length > 40
      ) {
        fieldError.invoiceProductRemark = "「備註」不得超過20個字";
      }
      if (!invoiceProduct.has("invoiceProductType")) {
        fieldError.invoiceProductType = "「類型」為必填";
      }
      totalAmount =
        totalAmount +
        invoiceProduct.get("invoiceProductQuantity") *
          invoiceProduct.get("invoiceProductUnitPrice");
      if (totalAmount > 100000000) {
        fieldError.invoiceProductUnitPrice =
          "總金額已超過限制金額:$100,000,000";
      }
      listErrors[index] = fieldError;
    });
    if (listErrors.length) {
      errors.invoiceProductList = listErrors;
    }
  }
  return errors;
};

const renderTalkIssueGroups = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    {fields.map((invoiceProduct, index) => {
      return (
        <Grid
          key={index}
          xs={12}
          container
          item
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Field
              name={`${invoiceProduct}.invoiceProductDescription`}
              label="商品品名*"
              component={TextLoadingField}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item>
            <Field
              name={`${invoiceProduct}.invoiceProductQuantity`}
              label="商品數量*"
              component={TextLoadingField}
              fullWidth
              normalize={positive({
                min: MIN_INVOICE_PRODUCT_QUANTITY,
              })}
              variant="outlined"
              size="small"
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={`${invoiceProduct}.invoiceProductUnit`}
              label="商品單位"
              component={TextLoadingField}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item>
            <Field
              name={`${invoiceProduct}.invoiceProductUnitPrice`}
              label="單品項價格*"
              component={TextLoadingField}
              fullWidth
              normalize={positive()}
              variant="outlined"
              size="small"
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={`${invoiceProduct}.invoiceProductRemark`}
              label="備註(限20字)"
              component={TextLoadingField}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={0}>
            <Field
              label="類型*"
              component={TextLoadingField}
              name={`${invoiceProduct}.invoiceProductType`}
              fullWidth
              select
              variant="outlined"
              size="small"
            >
              <MenuItem value="外部-社工課">外部-社工課</MenuItem>
              <MenuItem value="外部-學員">外部-學員</MenuItem>
              <MenuItem value="社工自訓">社工自訓</MenuItem>
              <MenuItem value="專案">專案</MenuItem>
              <MenuItem value="教材">教材</MenuItem>
              <MenuItem value="諮詢">諮詢</MenuItem>
              <MenuItem value="諮詢(EAP)">諮詢(EAP)</MenuItem>
              <MenuItem value="其他">其他</MenuItem>
            </Field>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => fields.remove(index)}
              size="small"
              disabled={fields.length === 1}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    })}
    <Grid item xs={12}>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => fields.push(Map())}
        fullWidth
      >
        新增商品
      </Button>
    </Grid>
  </React.Fragment>
);

const InvoiceEditForm = ({ handleSubmit }) => {
  return (
    <form id={FORM} onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field
            name="buyerName"
            label="買方名稱"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="buyerIdentifier"
            label="買方統編"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="buyerAddress"
            label="買方地址"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
          />
        </Grid>
        <FieldArray
          name="invoiceProductList"
          component={renderTalkIssueGroups}
        />
      </Grid>
    </form>
  );
};

InvoiceEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM,
  validate,
})(InvoiceEditForm);
