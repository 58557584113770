import { combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";
import { fromJS } from "immutable";
import { replace } from "connected-react-router/immutable";
import {
  fetchGetFacebookLoginUrlRequest,
  fetchGetFacebookLoginUrlSuccess,
  fetchGetFacebookLoginUrlFailure,
  fetchPostFacebookLoginRequest,
  fetchPostFacebookLoginSuccess,
  fetchPostFacebookLoginFailure,
  fetchGetGoogleLoginUrlRequest,
  fetchGetGoogleLoginUrlSuccess,
  fetchGetGoogleLoginUrlFailure,
  fetchPostGoogleLoginRequest,
  fetchPostGoogleLoginSuccess,
  fetchPostGoogleLoginFailure,
  FETCH_GET_FACEBOOK_LOGIN_URL,
  FETCH_POST_FACEBOOK_LOGIN,
  FETCH_GET_GOOGLE_LOGIN_URL,
  FETCH_POST_GOOGLE_LOGIN
} from "./redux";

export const fetchGetFacebookLoginUrlEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_FACEBOOK_LOGIN_URL,
  apiName: "fetchGetFacebookLoginUrl",
  fetchRequest: fetchGetFacebookLoginUrlRequest,
  handleSuccess: response => [
    fetchGetFacebookLoginUrlSuccess(fromJS(response.data))
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetFacebookLoginUrlFailure(error))
    )
});

export const fetchPostFacebookLoginEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_FACEBOOK_LOGIN,
  apiName: "fetchPostFacebookLogin",
  fetchRequest: fetchPostFacebookLoginRequest,
  handleSuccess: response => [fetchPostFacebookLoginSuccess(), replace("/")],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostFacebookLoginFailure(error))
    )
});

export const fetchGetGoogleLoginUrlEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_GOOGLE_LOGIN_URL,
  apiName: "fetchGetGoogleLoginUrl",
  fetchRequest: fetchGetGoogleLoginUrlRequest,
  handleSuccess: response => [
    fetchGetGoogleLoginUrlSuccess(fromJS(response.data))
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetGoogleLoginUrlFailure(error))
    )
});

export const fetchPostGoogleLoginEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_GOOGLE_LOGIN,
  apiName: "fetchPostGoogleLogin",
  fetchRequest: fetchPostGoogleLoginRequest,
  handleSuccess: response => [fetchPostGoogleLoginSuccess(), replace("/")],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostGoogleLoginFailure(error))
    )
});

export default combineEpics(
  fetchGetFacebookLoginUrlEpic,
  fetchPostFacebookLoginEpic,
  fetchGetGoogleLoginUrlEpic,
  fetchPostGoogleLoginEpic
);
