import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing()
  }
}));

const Navbar = props => {
  const classes = useStyles();
  return (
    <AppBar color="default">
      <Toolbar>
        <Typography variant="h6">EDS</Typography>
        <Box flexGrow={1} />
        <Button
          component={Link}
          className={classes.button}
          variant="outlined"
          color="primary"
          to="/login"
        >
          登入
        </Button>
        <Button
          component={Link}
          className={classes.button}
          variant="contained"
          color="primary"
          to="/register"
        >
          註冊
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
