import { handleActions } from "redux-actions";
import { Map, List } from "immutable";
import {
  SET_INVITED_USERS,
  CLEAR_INVITED_USERS,
  FETCH_GET_DOCUMENT_SUCCESS,
  CLEAR_DOCUMENT,
  FETCH_GET_DOCUMENT_VERSIONS_SUCCESS,
  CLEAR_DOCUMENT_VERSIONS,
  TOGGLE_DRAWER
} from "./redux";

const reducer = handleActions(
  {
    [SET_INVITED_USERS]: (state, action) =>
      state.set("invitedUsers", action.payload),
    [CLEAR_INVITED_USERS]: (state, action) => state.set("invitedUsers", List()),
    [FETCH_GET_DOCUMENT_SUCCESS]: (state, action) =>
      state.set("document", action.payload),
    [CLEAR_DOCUMENT]: state => state.delete("document"),
    [FETCH_GET_DOCUMENT_VERSIONS_SUCCESS]: (state, action) => {
      if (state.has("documentVersions")) {
        return state.update("documentVersions", documentVersions =>
          documentVersions
            .concat(action.payload.get("result"))
            .toSet()
            .toList()
        );
      }
      return state.set("documentVersions", action.payload.get("result"));
    },
    [CLEAR_DOCUMENT_VERSIONS]: state => state.delete("documentVersions"),
    [TOGGLE_DRAWER]: (state, action) => state.set("isOpen", action.payload)
  },
  Map({
    invitedUsers: List(),
    isOpen: false
  })
);

export default reducer;
