import React from "react";
import PropTypes from "prop-types";

import { reduxForm, Field, FieldArray } from "redux-form/immutable";
import { MIN_INVOICE_PRODUCT_QUANTITY } from "./config";
import { positive } from "./utils";

import TextLoadingField from "@e-group/material-form/TextLoadingField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const FORM = "invoiceAllowanceForm";

const renderInvoiceProductList = ({ fields, initialValues }) =>
  fields.map((invoiceProduct, index) => {
    const item = fields.get(index);
    const max = initialValues
      .get("invoiceProductList")
      .find((el) => el.get("invoiceProductId") === item.get("invoiceProductId"))
      .get("invoiceProductQuantity");
    return (
      <Grid xs={12} item container key={index} spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Field
            name={`${invoiceProduct}.invoiceProductDescription`}
            label="商品品名"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            size="small"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={`${invoiceProduct}.invoiceProductQuantity`}
            label="商品數量"
            component={TextLoadingField}
            fullWidth
            normalize={positive({
              min: MIN_INVOICE_PRODUCT_QUANTITY,
              max,
            })}
            variant="outlined"
            size="small"
            type="number"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={`${invoiceProduct}.invoiceProductUnitPrice`}
            label="單品項價格"
            component={TextLoadingField}
            fullWidth
            normalize={positive()}
            variant="outlined"
            size="small"
            type="number"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <IconButton
            onClick={() => fields.remove(index)}
            size="small"
            disabled={fields.length === 1}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  });

const InvoiceAllowanceForm = ({ handleSubmit, initialValues }) => {
  return (
    <form id={FORM} onSubmit={handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1" gutterBottom>
          買方：{initialValues.get("buyerName")}
        </Typography>
        <Typography variant="body1">
          統編：{initialValues.get("buyerIdentifier")}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <FieldArray
          name="invoiceProductList"
          component={renderInvoiceProductList}
          props={{
            initialValues,
          }}
        />
      </Grid>
    </form>
  );
};

InvoiceAllowanceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM,
})(InvoiceAllowanceForm);
