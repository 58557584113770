import PropTypes from "prop-types";

import { renderRoutes } from "react-router-config";

const InvoicesRoot = ({ route }) => {
  return renderRoutes(route.routes);
};

InvoicesRoot.propTypes = {
  // react-router-dom props
  route: PropTypes.object.isRequired
};

export default InvoicesRoot;
