import PropTypes from "prop-types";

import { renderRoutes } from "react-router-config";

const OrganizationsRoot = ({ route }) => {
  return renderRoutes(route.routes);
};

OrganizationsRoot.propTypes = {
  // react-router-dom props
  route: PropTypes.object.isRequired
};

export default OrganizationsRoot;
