import React from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import { submit } from "redux-form/immutable";
import { fetchPostOrganization, getOrganizationIsPosting } from "./redux";

import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import OrganizationAddForm, { FORM } from "./OrganizationAddForm";

export const DIALOG = "organizationAddDialog";

const OrganizationAddDialog = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getOrganizationIsPosting);
  const handleSubmit = (values) => {
    dispatch(fetchPostOrganization(values.toJS()));
  };
  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>新增單位</DialogTitle>
      <DialogContent>
        <DialogContentText>替你的單位取個名稱吧！</DialogContentText>
        <OrganizationAddForm onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions>
        <Button
          MuiButtonProps={{
            onClick: handleClose,
            color: "primary",
          }}
        >
          取消
        </Button>
        <Button
          loading={isPosting}
          MuiButtonProps={{
            type: "submit",
            variant: "contained",
            color: "primary",
            onClick: () => {
              dispatch(submit(FORM));
            },
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrganizationAddDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(OrganizationAddDialog);
