import React from "react";
import PropTypes from "prop-types";

import { reduxForm, Field } from "redux-form/immutable";
import { useSelector } from "react-redux";
import { getOptions } from "components/RouterRoot/redux";

import TextLoadingField from "@e-group/material-form/TextLoadingField";
import MenuItem from "@material-ui/core/MenuItem";

export const FORM = "organizationAddForm";

const validate = values => {
  const errors = {};
  if (!values.get("organizationName")) {
    errors.organizationName = "必填";
  }
  return errors;
};

const OrganizationAddForm = ({ handleSubmit }) => {
  const options = useSelector(getOptions);
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="organizationName"
        label="單位名稱"
        autoFocus
        required
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationType"
        label="單位類型"
        autoFocus
        required
        select
        component={TextLoadingField}
        fullWidth
        margin="normal"
      >
      
      {options
                .get("organizationType")
                .map((el, key) => (
                  <MenuItem key={key} value={key}>
                    {el}
                  </MenuItem>
                ))
                .valueSeq()}
      </Field>
      <Field
        name="contactWindowName"
        label="聯絡窗口姓名"
        autoFocus
        required
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="contactWindowEnglishName"
        label="聯絡窗口英文姓名"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationPhone"
        label="聯絡窗口電話"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationdEmail"
        label="聯絡窗口信箱"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="contactWindowJobTitle"
        label="聯絡窗口職稱"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="contactWindowGender"
        label="聯絡窗口性別"
        autoFocus
        required
        select
        component={TextLoadingField}
        fullWidth
        margin="normal"
      >
      {options
        .get("organizationGender")
        .map((el, key) => (
          <MenuItem key={key} value={key}>
            {el}
          </MenuItem>
        ))
        .valueSeq()}
       </Field> 
      <Field
        name="organizationZIPCode"
        label="單位郵遞區號"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationCity"
        label="單位城市"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationArea"
        label="單位地區"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationAddress"
        label="單位地址"
        autoFocus
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
      <Field
        name="organizationTypeNote"
        label="單位類型備註"
        autoFocus        
        component={TextLoadingField}
        fullWidth
        margin="normal"
      />
    </form>
  );
};


OrganizationAddForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM,
  validate
})(OrganizationAddForm);
