import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { replace } from "connected-react-router/immutable";
import {
  fetchPostRegisterRequest,
  fetchPostRegisterSuccess,
  fetchPostRegisterFailure,
  FETCH_POST_REGISTER
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchPostRegisterEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_REGISTER,
  apiName: "fetchPostRegister",
  fetchRequest: fetchPostRegisterRequest,
  handleSuccess: response => [fetchPostRegisterSuccess(), replace("/")],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostRegisterFailure(error))
    )
});

export default combineEpics(fetchPostRegisterEpic);
