import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default withStyles({
  root: {
    backgroundColor: "#fff",
    borderColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff"
    },
    "&:active": {
      backgroundColor: "#fff",
      borderColor: "#fff"
    }
  }
})(Button);
