import React from "react";
import PropTypes from "prop-types";

import base64ToObject from "@e-group/utils/base64ToObject";
import { useDispatch, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import { compose } from "redux";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import routesUser from "routes-user";
import routesUnlogin from "routes-unlogin";
import routesAdmin from "routes-admin";
import { fetchGetOptions, getOptions } from "./redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import FixedCenter from "components/FixedCenter";

/**
 * 用 cookie 判斷是否有登入，若有則回傳後台頁面若無則回傳前台頁面
 */
const RouterRoot = ({ allCookies }) => {
  const dispatch = useDispatch();
  const options = useSelector(getOptions);

  React.useEffect(() => {
    if (allCookies.lid && allCookies.tid && allCookies.u_info) {
      dispatch(fetchGetOptions());
    }
  }, [allCookies.lid, allCookies.tid, allCookies.u_info, dispatch]);

  if (allCookies.lid && allCookies.tid && allCookies.u_info) {
    const userInfo = base64ToObject(allCookies.u_info);
    if (options) {
      if (userInfo.isAdmin) {
        return renderRoutes(routesAdmin);
      }
      return renderRoutes(routesUser);
    }

    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return renderRoutes(routesUnlogin);
};

RouterRoot.propTypes = {
  allCookies: PropTypes.object.isRequired
};

/**
 * withRouter 必須在 withCookies 前面才會觸發換頁以及 cookie 變更
 */
export default compose(withRouter, withCookies)(RouterRoot);
