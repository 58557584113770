import React from "react";

import { useHistory } from "react-router";
import makeSearchDataList from "@e-group/hooks/makeSearchDataList";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { getOptions } from "components/RouterRoot/redux";
import {
  fetchGetOrganizations,
  getOrganizations,
  getOrganizationsTotal,
  fetchDeleteOrganization
} from "./redux";

import SearchDataList from "@e-group/material-module/SearchDataList";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import FixedCenter from "components/FixedCenter";
import OrganizationAddDialog, {
  DIALOG as ADD_DIALOG
} from "./OrganizationAddDialog";

const useSearchDataList = makeSearchDataList({
  fromKey: "offset",
  sizeKey: "limit",
  queryKey: "search"
});

const Organizations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const organizationsTotal = useSelector(getOrganizationsTotal);
  const options = useSelector(getOptions);
  const {
    handleSearchChange,
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    payload
  } = useSearchDataList({
    fetchGet: React.useCallback(
      payload => {
        dispatch(fetchGetOrganizations(payload));
      },
      [dispatch]
    ),
    history
  });

  const handleDelete = organizationId => event => {
    event.stopPropagation();
    dispatch(fetchDeleteOrganization({ organizationId }));
  };

  const renderColumns = (rowData, index) => (
    <TableRow key={index}>
      {rowData.map(el => (
        <TableCell key={el}>{el}</TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = organization => {
    return (
      <TableRow
        key={organization.organizationId}
        hover
        onClick={e => {
          history.push(`/crm/organizations/${organization.organizationId}`);
        }}
      >
        <TableCell>
          <Typography variant="body2">
            {organization.organizationName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {options.getIn([
              "organizationType",
              String(organization.organizationType)
            ])}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {organization.organizationCity}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {organization.organizationArea}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {organization.organizationPhone}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleDelete(organization.organizationId)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  if (!organizations) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <OrganizationAddDialog />
      <Container maxWidth="md">
        <Paper>
          <SearchDataList
            size="small"
            variant="table"
            onSubmit={handleSearchSubmit}
            title="單位列表"
            SearchBarProps={{
              placeholder: "單位搜尋",
              onChange: handleSearchChange,
              defaultValue: payload.query
            }}
            toolsbar={
              <Tooltip title="新增單位">
                <IconButton onClick={() => dispatch(openDialog(ADD_DIALOG))}>
                  <AddBoxIcon />
                </IconButton>
              </Tooltip>
            }
            columns={["名稱", "類型", "城市", "地區", "電話", "操作"]}
            data={organizations.toJS()}
            serverSide
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            MuiTablePaginationProps={{
              count: organizationsTotal,
              labelRowsPerPage: "每頁幾筆",
              page: Math.ceil(payload.offset / payload.limit),
              rowsPerPage: parseInt(payload.limit),
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Container>
    </>
  );
};

export default Organizations;
