import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import grey from '@material-ui/core/colors/grey';

export interface EdsThemeOptions extends ThemeOptions {
  layout: {
    sideMenuWidth: number;
  };
}

const fontFamily = [
  '"Segoe UI"',
  'SegoeUI',
  '"Microsoft JhengHei"',
  '微軟正黑體',
  '"SF Pro TC"',
  '"SF Pro Display"',
  '"SF Pro Icons"',
  '"PingFang TC"',
  '"Helvetica Neue"',
  '"Helvetica"',
  '"Arial"',
  'sans-serif'
].join(',');
const theme = createMuiTheme();
const TYPE = 'light';
const light = TYPE === 'light';

const edsThemeOptions: EdsThemeOptions = {
  layout: {
    sideMenuWidth: 76
  },
  palette: {
    type: TYPE,
    background: {
      default: light ? '#fff' : grey['A400']
    }
  },
  typography: {
    fontFamily
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: light ? '#fff' : grey['A400'],
        color: light
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText
      }
    }
  }
};

const edsTheme = createMuiTheme(edsThemeOptions)

// for develop
if (process.env.NODE_ENV !== 'production') console.log(edsTheme);

export default edsTheme;
