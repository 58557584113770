import React from "react";

import { useParams, useRouteMatch } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import makeInfiniteScroll from "./makeInfiniteScroll";
import {
  fetchPostBackupDocumentVersion,
  fetchPatchDocumentVersion,
  fetchGetDocumentVersions,
  clearDocumentVersions,
  toggleDrawer,
  getDocument,
  getDocumentVersions,
  getIsOpen,
  getDocumentVersionsTotal,
  getDocumentVersionsIsGetting,
  getBackupDocumentVersionIsPosting,
} from "./redux";

import { Link } from "react-router-dom";
import Button from "@e-group/material/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import StyledTextField from "./StyledTextField";

const useUseInfiniteScroll = makeInfiniteScroll({
  disableDefaultTarget: true,
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 350,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    ...theme.mixins.toolbar,
  },
  drawerSearch: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const DocumentVersionsDrawer = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { documentId } = useParams();
  const isOpen = useSelector(getIsOpen);
  const documentData = useSelector(getDocument);
  const documentVersions = useSelector(getDocumentVersions);
  const isPosting = useSelector(getBackupDocumentVersionIsPosting);
  const classes = useStyles();
  const [containerEl, setContainerEl] = React.useState();
  const documentVersionsTotal = useSelector(getDocumentVersionsTotal);
  const isGetting = useSelector(getDocumentVersionsIsGetting);
  const [query, setQuery] = React.useState();
  const [page, setPage] = useUseInfiniteScroll({
    target: containerEl,
    scrollHeight: containerEl ? containerEl.scrollHeight : undefined,
    isLoading: isGetting,
    maxPage: Math.floor(documentVersionsTotal / 10),
  });

  React.useEffect(() => {
    dispatch(
      fetchGetDocumentVersions({
        documentId,
        from: page * 10,
        size: 10,
        query,
      })
    );
  }, [dispatch, documentId, page, query]);

  /**
   * To clear redux values after component unmount.
   */
  React.useEffect(() => {
    return () => {
      dispatch(clearDocumentVersions());
    };
  }, [dispatch]);

  const handleClose = (event) => {
    dispatch(toggleDrawer(false));
  };

  const handleUpdateTitle = (e, documentVersion) => {
    const value = e.target.value;
    if (value === "") return;
    dispatch(
      fetchPatchDocumentVersion({
        documentId: documentData.get("documentId"),
        documentVersionId: documentVersion.get("documentVersionId"),
        documentVersionTitle: e.target.value,
      })
    );
  };

  const handleSaveVersion = () => {
    dispatch(
      fetchPostBackupDocumentVersion({
        documentId: documentData.get("documentId"),
      })
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    dispatch(clearDocumentVersions());
    setPage(0);
    dispatch(
      fetchGetDocumentVersions({
        documentId: documentData.get("documentId"),
        query: value,
      })
    );
  };

  const renderList = () => {
    if (!documentVersions) {
      return (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress disableShrink />
        </Box>
      );
    } else if (documentVersions.size === 0) {
      return (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>無版本紀錄</Typography>
        </Box>
      );
    }

    return (
      <List>
        {documentVersions.map((el) => (
          <ListItem key={el.get("documentVersionId")}>
            <ListItemText
              primary={moment(el.get("documentVersionCreateDateString")).format(
                "LLLL"
              )}
              secondary={
                <StyledTextField
                  onChange={(e) => handleUpdateTitle(e, el)}
                  defaultValue={el.get("documentVersionTitle")}
                />
              }
              secondaryTypographyProps={{
                component: "div",
              }}
            />
            <ListItemSecondaryAction>
              <Tooltip title="查看版本">
                <IconButton
                  component={Link}
                  to={`${match.url}/document-versions/${el.get(
                    "documentVersionId"
                  )}`}
                  edge="end"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {isGetting && (
          <Box mt={1} display="flex" justifyContent="center">
            <CircularProgress disableShrink size={20} />
          </Box>
        )}
      </List>
    );
  };

  return (
    <Drawer
      BackdropProps={{
        invisible: true,
      }}
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      SlideProps={{
        onEntered: (el) => {
          setContainerEl(el);
        },
      }}
    >
      <div className={classes.container} role="presentation">
        <div className={classes.drawerHeader}>
          <Typography variant="h6">版本紀錄</Typography>
          <Box flexGrow={1} />
          <IconButton onClick={handleClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.drawerSearch}>
          <OutlinedInput
            placeholder="搜尋紀錄"
            onChange={handleSearch}
            margin="dense"
            disabled={documentVersions && documentVersions.size === 0}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box mt={1} textAlign="right">
            <Button
              loading={isPosting}
              MuiButtonProps={{
                color: "primary",
                onClick: handleSaveVersion,
              }}
            >
              新增版本
            </Button>
          </Box>
        </div>
        {renderList()}
      </div>
    </Drawer>
  );
};

export default DocumentVersionsDrawer;
