import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetFeedbackRequest,
  fetchGetFeedbackSuccess,
  fetchGetFeedbackFailure,
  fetchGetFeedbacksRequest,
  fetchGetFeedbacksSuccess,
  fetchGetFeedbacksFailure,
  FETCH_GET_FEEDBACK,
  FETCH_GET_FEEDBACKS,
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetFeedbackEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_FEEDBACK,
  apiName: "feedbacks.fetchGetFeedback",
  fetchRequest: fetchGetFeedbackRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.feedback
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetFeedbackSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetFeedbackFailure(error))
    ),
});

export const fetchGetFeedbacksEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_FEEDBACKS,
  apiName: "feedbacks.fetchGetFeedbacks",
  fetchRequest: fetchGetFeedbacksRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.feedbacks
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetFeedbacksSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetFeedbacksFailure(error))
    ),
});

export default combineEpics(fetchGetFeedbackEpic, fetchGetFeedbacksEpic);
