import React from "react";

import { useCookies } from "react-cookie";
import base64ToObject from "@e-group/utils/base64ToObject";

export default function useUserInfo() {
  const [cookies] = useCookies(["u_info"]);
  const userInfo = React.useMemo(() => {
    if (cookies.u_info) {
      return base64ToObject(cookies.u_info);
    }
    return {};
  }, [cookies.u_info]);

  return [userInfo];
}
