import React from "react";
import PropTypes from "prop-types";

import { fromJS } from "immutable";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { makeStyles } from "@material-ui/core/styles";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import {
  formValueSelector,
  hasSubmitFailed,
  isInvalid,
  submit,
} from "redux-form/immutable";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPostInvoice,
  fetchPatchInvoice,
  handleNext,
  handlePrev,
  getInvoiceIsPosting,
  getInvoiceIsPatching,
  getSelectedSellerIdentifier,
  getActiveStep,
  getActiveStepMax,
  getSelectedInvoice,
} from "./redux";
import makeFormValueIsEqual from "utils/makeFormValueIsEqual";
import { ccyformat, calcInvoiceAmounts } from "./utils";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import InvoiceEditForm, { FORM } from "./InvoiceEditForm";

export const DIALOG = "invoiceEditDialog";
export const CONFIRM_LEAVE_DIALOG = "confirmLeaveInvoiceAddDialog";

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)(ConfirmDialog);

const selector = formValueSelector(FORM);
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const useFormValueIsEqual = makeFormValueIsEqual(FORM);

const InvoiceEditDialog = React.forwardRef(function InvoiceEditDialog(
  props,
  ref
) {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEqual = useFormValueIsEqual();
  const [selectedInvoiceWay, setSelectedInvoiceWay] = React.useState(1);
  const [selectedTaxableType, setSelectedTaxableType] = React.useState(
    "taxExcluded"
  );
  const buyerName = useSelector((state) => selector(state, "buyerName"));
  const buyerIdentifier = useSelector((state) =>
    selector(state, "buyerIdentifier")
  );
  const invoiceProductList = useSelector((state) =>
    selector(state, "invoiceProductList")
  );
  const formIsInvalid = useSelector((state) => isInvalid(FORM)(state));
  const formHasSubmitFailed = useSelector((state) =>
    hasSubmitFailed(FORM)(state)
  );
  const isPosting = useSelector(getInvoiceIsPosting);
  const isPatching = useSelector(getInvoiceIsPatching);
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const activeStep = useSelector(getActiveStep);
  const activeStepMax = useSelector(getActiveStepMax);
  const selectedInvoice = useSelector(getSelectedInvoice);
  const initialValues = React.useMemo(
    () =>
      selectedInvoice ||
      fromJS({
        invoiceProductList: [{}],
      }),
    [selectedInvoice]
  );

  // Init field values not include in redux form
  React.useEffect(() => {
    if (selectedInvoice) {
      setSelectedInvoiceWay(selectedInvoice.get("invoiceWay"));
      setSelectedTaxableType(selectedInvoice.get("taxableType"));
    }
  }, [selectedInvoice]);

  const getDialogTitle = () => {
    if (selectedInvoice) {
      return "編輯發票";
    }
    return "新增發票";
  };

  const closeConform = () => {
    if (!isEqual) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  const handleSubmit = (values) => {
    /**
     * Use A0401 to issue an invoice．
     */
    if (selectedInvoice) {
      dispatch(
        fetchPatchInvoice({
          ...values.toJS(),
          invoiceWay: selectedInvoiceWay,
          taxableType: selectedTaxableType,
          eInvoiceMessage: "A0401",
        })
      );
    } else {
      dispatch(
        fetchPostInvoice({
          invoiceWay: selectedInvoiceWay,
          taxableType: selectedTaxableType,
          sellerIdentifier: selectedSellerIdentifier,
          ...values.toJS(),
        })
      );
    }
  };

  const renderSelectInvoiceWayStep = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RadioGroup
            name="invoiceWay"
            value={selectedInvoiceWay}
            onChange={(e) => setSelectedInvoiceWay(Number(e.target.value))}
            row
          >
            <FormControlLabel control={<Radio />} label="立刻開立" value={1} />
            <FormControlLabel control={<Radio />} label="儲存發票" value={2} />
          </RadioGroup>
          <FormHelperText>
            {selectedInvoiceWay === 1
              ? "立刻開立將會立即上傳至財政部"
              : "儲存發票日後再開立"}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            name="taxableType"
            value={selectedTaxableType}
            onChange={(e) => setSelectedTaxableType(e.target.value)}
            row
          >
            <FormControlLabel
              control={<Radio />}
              label="未稅"
              value="taxExcluded"
            />
            <FormControlLabel
              control={<Radio />}
              label="含稅"
              value="taxIncluded"
            />
          </RadioGroup>
          <FormHelperText>
            {selectedTaxableType === "taxExcluded"
              ? "總金額未包含 5% 營業稅"
              : "總金額內含 5% 營業稅"}
          </FormHelperText>
        </Grid>
      </Grid>
    );
  };

  const renderCheckStep = () => {
    if (formIsInvalid) {
      return <Typography>請先完成填寫基本資料</Typography>;
    }
    if (!invoiceProductList) {
      return undefined;
    }
    const taxIncluded = selectedTaxableType === "taxIncluded";
    const [
      calcedList,
      salesAmount,
      taxAmount,
      invoiceAmount,
    ] = calcInvoiceAmounts(invoiceProductList, taxIncluded);

    return (
      <React.Fragment>
        <Typography>買方名稱：{buyerName}</Typography>
        <Typography>買方統編：{buyerIdentifier}</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>商品品名</TableCell>
                <TableCell align="right">商品數量</TableCell>
                <TableCell align="right">單品項價格(未稅)</TableCell>
                <TableCell align="right">小計(未稅)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calcedList.map((invoiceProduct, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {invoiceProduct.get("invoiceProductDescription")}
                      {invoiceProduct.get("invoiceProductRemark")
                        ? `(${invoiceProduct.get("invoiceProductRemark")})`
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("invoiceProductQuantity"))}{" "}
                      {invoiceProduct.get("invoiceProductUnit")}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("invoiceProductUnitPrice"))}
                    </TableCell>
                    <TableCell align="right">
                      {ccyformat(invoiceProduct.get("subtotal"))}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell
                  rowSpan={3}
                  colSpan={2}
                  style={{ borderBottom: 0 }}
                />
                <TableCell>銷售額合計</TableCell>
                <TableCell align="right">
                  {ccyformat(salesAmount, { round: true })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>營業稅</TableCell>
                <TableCell align="right">
                  {ccyformat(taxAmount, { round: true })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>總計</TableCell>
                <TableCell align="right">
                  {ccyformat(invoiceAmount, { round: true })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ConfirmLeaveDialog
        title="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={closeConform}>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              公司名稱：
              {selectedSellerIdentifier === "28473556" &&
                "馴錢師財商顧問股份有限公司"}
              {selectedSellerIdentifier === "24314136" &&
                "益群健康股份有限公司"}
            </Grid>
            <Grid item xs={6}>
              公司統編：{selectedSellerIdentifier}
            </Grid>
          </Grid>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel error={formHasSubmitFailed && formIsInvalid}>
                填寫基本資料
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>選擇開立方式</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認發票內容</StepLabel>
            </Step>
          </Stepper>
          <Box px={3}>
            <Box display={activeStep === 0 ? "block" : "none"}>
              <InvoiceEditForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
              />
            </Box>
            <Box display={activeStep === 1 ? "block" : "none"}>
              {renderSelectInvoiceWayStep()}
            </Box>
            <Box display={activeStep === 2 ? "block" : "none"}>
              {renderCheckStep()}
            </Box>
            <Box display={activeStep === activeStepMax ? "block" : "none"}>
              <Typography variant="h6" align="center">
                {getDialogTitle()}完成!
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        {activeStep !== activeStepMax && (
          <DialogActions>
            <Button
              MuiButtonProps={{
                disabled: activeStep === 0,
                variant: "outlined",
                onClick: () => {
                  dispatch(handlePrev());
                },
                className: classes.button,
              }}
            >
              上一步
            </Button>
            {activeStep === activeStepMax - 1 ? (
              <Button
                loading={isPosting || isPatching}
                MuiButtonProps={{
                  type: "submit",
                  variant: "contained",
                  color: "primary",
                  className: classes.button,
                  onClick: () => dispatch(submit(FORM)),
                }}
              >
                送出
              </Button>
            ) : (
              <Button
                MuiButtonProps={{
                  variant: "outlined",
                  color: "primary",
                  onClick: () => {
                    dispatch(handleNext());
                  },
                  className: classes.button,
                }}
              >
                下一步
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
});

InvoiceEditDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceEditDialog);
