import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default withStyles({
  root: {
    backgroundColor: "#4267b2",
    borderColor: "#4267b2",
    "&:hover": {
      backgroundColor: "#4267b2",
      borderColor: "#4267b2"
    },
    "&:active": {
      backgroundColor: "#4267b2",
      borderColor: "#4267b2"
    }
  }
})(Button);
