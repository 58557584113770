import React from "react";
import PropTypes from "prop-types";

import { openDialog } from "@e-group/redux-modules/dialogs";
import { makeStyles } from "@material-ui/core/styles";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import {
  formValueSelector,
  hasSubmitFailed,
  isInvalid,
  submit,
} from "redux-form/immutable";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPostInvoice,
  fetchPatchInvoice,
  handleNext,
  handlePrev,
  getInvoiceIsPosting,
  getInvoiceIsPatching,
  getSelectedSellerIdentifier,
  getActiveStep,
  getActiveStepMax,
  getSelectedInvoice,
} from "./redux";
import makeFormValueIsEqual from "utils/makeFormValueIsEqual";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import InvoiceEditForm, { FORM } from "./InvoiceEditForm";

export const DIALOG = "invoiceEditDialog";

const CONFIRM_LEAVE_DIALOG = "confirmLeaveInvoiceAddDialog";

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)(ConfirmDialog);

const selector = formValueSelector(FORM);

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const useFormValueIsEqual = makeFormValueIsEqual(FORM);

const InvoiceEditDialog = React.forwardRef(function InvoiceEditDialog(
  props,
  ref
) {
  const dispatch = useDispatch();
  const buyerName = useSelector((state) => selector(state, "buyerName"));
  const buyerIdentifier = useSelector((state) =>
    selector(state, "buyerIdentifier")
  );
  const invoiceProductList = useSelector((state) =>
    selector(state, "invoiceProductList")
  );
  const formIsInvalid = useSelector((state) => isInvalid(FORM)(state));
  const formHasSubmitFailed = useSelector((state) =>
    hasSubmitFailed(FORM)(state)
  );
  const isPosting = useSelector(getInvoiceIsPosting);
  const isPatching = useSelector(getInvoiceIsPatching);
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const activeStep = useSelector(getActiveStep);
  const activeStepMax = useSelector(getActiveStepMax);

  const selectedInvoice = useSelector(getSelectedInvoice);
  const [selectedInvoiceWay, setSelectedInvoiceWay] = React.useState(1);
  const classes = useStyles();
  const { isOpen, handleClose } = props;
  const isEqual = useFormValueIsEqual();

  const getDialogTitle = () => {
    if (selectedInvoice) {
      return "編輯發票";
    }
    return "新增發票";
  };

  const closeConform = () => {
    if (!isEqual) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  const handleInvoiceWayChange = (e) => {
    setSelectedInvoiceWay(e.target.value);
  };

  const handleSubmit = (values) => {
    let payload = values.deleteAll([
      "memberCarrierId",
      "phoneCarrierId",
      "citizenCarrierId",
      "donateCarrierId",
    ]);
    const memberCarrierId = values.getIn(["memberCarrierId", "value"]);
    const phoneCarrierId = values.get("phoneCarrierId");
    const citizenCarrierId = values.get("citizenCarrierId");
    const donateCarrierId = values.getIn(["donateCarrierId", "value"]);
    const userName = values.getIn(["memberCarrierId", "label"]);
    const userEmail = values.getIn(["memberCarrierId", "remark"]);
    const invoiceNPOName = values.getIn(["donateCarrierId", "label"]);
    const buyerName = values.get("buyerName");
    const buyerAddress = values.get("buyerAddress");

    if (selectedInvoice) {
      payload = payload.delete("invoiceWay");
      switch (values.get("invoiceResultType")) {
        case 1: {
          return dispatch(
            fetchPatchInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              eInvoiceMessage: "C0401",
              invoiceCarrierId1: memberCarrierId,
              userName: userName,
              userEmail: userEmail,
              ...payload.toJS(),
            })
          );
        }
        case 2: {
          return dispatch(
            fetchPatchInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              eInvoiceMessage: "C0401",
              invoiceCarrierId1: phoneCarrierId,
              ...payload.toJS(),
            })
          );
        }
        case 3: {
          return dispatch(
            fetchPatchInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              eInvoiceMessage: "C0401",
              invoiceCarrierId1: citizenCarrierId,
              ...payload.toJS(),
            })
          );
        }
        case 4: {
          return dispatch(
            fetchPatchInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              eInvoiceMessage: "C0401",
              invoiceNPOBAN: donateCarrierId,
              invoiceNPOName: invoiceNPOName,
              ...payload.toJS(),
            })
          );
        }
        case 6: {
          return dispatch(
            fetchPatchInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              eInvoiceMessage: "C0401",
              buyerName: buyerName,
              buyerAddress: buyerAddress,
              ...payload.toJS(),
            })
          );
        }
        default: {
          return payload;
        }
      }
    } else {
      switch (values.get("invoiceResultType")) {
        case 1: {
          return dispatch(
            fetchPostInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              invoiceCarrierId1: memberCarrierId,
              userName: userName,
              userEmail: userEmail,
              ...payload.toJS(),
            })
          );
        }
        case 2: {
          return dispatch(
            fetchPostInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              invoiceCarrierId1: phoneCarrierId,
              ...payload.toJS(),
            })
          );
        }
        case 3: {
          return dispatch(
            fetchPostInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              invoiceCarrierId1: citizenCarrierId,
              ...payload.toJS(),
            })
          );
        }
        case 4: {
          return dispatch(
            fetchPostInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              invoiceNPOBAN: donateCarrierId,
              invoiceNPOName: invoiceNPOName,
              ...payload.toJS(),
            })
          );
        }
        case 6: {
          return dispatch(
            fetchPostInvoice({
              invoiceWay: selectedInvoiceWay,
              sellerIdentifier: selectedSellerIdentifier,
              buyerName: buyerName,
              buyerAddress: buyerAddress,
              ...payload.toJS(),
            })
          );
        }
        default: {
          return payload;
        }
      }
    }
  };

  const renderBasicFieldsStep = () => {
    return (
      <InvoiceEditForm
        initialValues={selectedInvoice}
        onSubmit={handleSubmit}
      />
    );
  };

  const renderSelectInvoiceWayStep = () => {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            variant="outlined"
            size="small"
            onChange={handleInvoiceWayChange}
            select
            value={selectedInvoiceWay}
          >
            <MenuItem value={1}>立刻開立</MenuItem>
            <MenuItem value={2}>儲存發票</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          {selectedInvoiceWay === 1 ? (
            <Typography variant="body1" display="inline">
              立刻開立將會立即上傳至財政部
            </Typography>
          ) : (
            <Typography variant="body1" display="inline">
              儲存發票日後再開立
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderCheckStep = () => {
    let totalAmount = 0;
    if (formIsInvalid) {
      return <Typography>請先完成填寫基本資料</Typography>;
    }
    if (invoiceProductList) {
      invoiceProductList.map(
        (el) =>
          (totalAmount =
            totalAmount +
            el.get("invoiceProductQuantity") *
              el.get("invoiceProductUnitPrice"))
      );
    }

    return (
      <React.Fragment>
        {buyerName && <Typography>買方名稱：{buyerName}</Typography>}
        {buyerIdentifier && (
          <Typography>買方統編：{buyerIdentifier}</Typography>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>商品品名</TableCell>
                <TableCell>商品數量</TableCell>
                <TableCell>單品項價格</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceProductList &&
                invoiceProductList.map((invoiceProduct, index) => {
                  if (typeof invoiceProduct !== "undefined") {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductDescription")}
                        </TableCell>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductQuantity")}{" "}
                          {invoiceProduct.get("invoiceProductUnit")}
                        </TableCell>
                        <TableCell>
                          {invoiceProduct.get("invoiceProductUnitPrice")}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return invoiceProduct;
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography>總計：{totalAmount}</Typography>
      </React.Fragment>
    );
  };

  const renderFinallyStep = () => (
    <Typography variant="h6" align="center">
      {getDialogTitle()}完成!
    </Typography>
  );

  return (
    <React.Fragment>
      <ConfirmLeaveDialog
        title="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={closeConform}>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              公司名稱：
              {selectedSellerIdentifier === "28473556" &&
                "馴錢師財商顧問股份有限公司"}
              {selectedSellerIdentifier === "24314136" &&
                "益群健康股份有限公司"}
            </Grid>
            <Grid item xs={6}>
              公司統編：{selectedSellerIdentifier}
            </Grid>
          </Grid>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel error={formHasSubmitFailed && formIsInvalid}>
                填寫基本資料
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>選擇開立方式</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認發票內容</StepLabel>
            </Step>
          </Stepper>
          <Box px={3}>
            <Box display={activeStep === 0 ? "block" : "none"}>
              {renderBasicFieldsStep()}
            </Box>
            <Box display={activeStep === 1 ? "block" : "none"}>
              {renderSelectInvoiceWayStep()}
            </Box>
            <Box display={activeStep === 2 ? "block" : "none"}>
              {renderCheckStep()}
            </Box>
            <Box display={activeStep === activeStepMax ? "block" : "none"}>
              {renderFinallyStep()}
            </Box>
          </Box>
        </DialogContent>
        {activeStep !== activeStepMax && (
          <DialogActions>
            <Button
              MuiButtonProps={{
                disabled: activeStep === 0,
                variant: "outlined",
                onClick: () => {
                  dispatch(handlePrev());
                },
                className: classes.button,
              }}
            >
              上一步
            </Button>
            {activeStep === activeStepMax - 1 ? (
              <Button
                loading={isPosting || isPatching}
                MuiButtonProps={{
                  type: "submit",
                  variant: "contained",
                  color: "primary",
                  className: classes.button,
                  onClick: () => dispatch(submit(FORM)),
                }}
              >
                送出
              </Button>
            ) : (
              <Button
                MuiButtonProps={{
                  variant: "outlined",
                  color: "primary",
                  onClick: () => {
                    dispatch(handleNext());
                  },
                  className: classes.button,
                }}
              >
                下一步
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
});

InvoiceEditDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceEditDialog);
