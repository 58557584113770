import React from "react";
import queryString from "query-string";
import FixedCenter from "components/FixedCenter";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { fetchPostGoogleLogin } from "./redux";
import { useLocation } from "react-router";

const GoogleLogin = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { code } = React.useMemo(() => queryString.parse(location.search), [
    location.search
  ]);

  React.useEffect(() => {
    if (code) {
      dispatch(
        fetchPostGoogleLogin({
          code
        })
      );
    }
  }, [code, dispatch]);

  return (
    <FixedCenter>
      <CircularProgress />
    </FixedCenter>
  );
};

export default GoogleLogin;
