import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { push } from "connected-react-router/immutable";
import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetDocumentVersionRequest,
  fetchGetDocumentVersionSuccess,
  fetchGetDocumentVersionFailure,
  fetchPatchDocumentRequest,
  fetchPatchDocumentSuccess,
  fetchPatchDocumentFailure,
  FETCH_GET_DOCUMENT_VERSION,
  FETCH_PATCH_DOCUMENT,
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetDocumentVersionEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_DOCUMENT_VERSION,
  apiName: "eds.fetchGetDocumentVersion",
  fetchRequest: fetchGetDocumentVersionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.documentVersion
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetDocumentVersionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetDocumentVersionFailure(error))
    ),
});

export const fetchPatchDocumentEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_DOCUMENT,
  apiName: "eds.fetchPatchDocument",
  fetchRequest: fetchPatchDocumentRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.document
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchDocumentSuccess(fromJS(result)),
      push(`/documents/${response.data.documentId}`),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchDocumentFailure(error))
    ),
});

export default combineEpics(
  fetchGetDocumentVersionEpic,
  fetchPatchDocumentEpic
);
