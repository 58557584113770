import React from "react";

import moment from "moment";
import { useHistory } from "react-router";
import makeSearchDataList from "@e-group/hooks/makeSearchDataList";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "@e-group/redux-modules/dialogs";
import { fetchGetDocuments, getDocuments, getDocumentsTotal } from "./redux";

import { Link } from "react-router-dom";
import SearchDataList from "@e-group/material-module/SearchDataList";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FixedCenter from "components/FixedCenter";
import DocumentAddDialog, { DIALOG } from "./DocumentAddDialog";

const useSearchDataList = makeSearchDataList({
  fromKey: "from",
  sizeKey: "size",
  queryKey: "query"
});

const Documents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const documents = useSelector(getDocuments);
  const documentsTotal = useSelector(getDocumentsTotal);
  const {
    handleSearchChange,
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    payload
  } = useSearchDataList({
    fetchGet: React.useCallback(
      payload => {
        dispatch(fetchGetDocuments(payload));
      },
      [dispatch]
    ),
    history
  });

  const renderColumns = (rowData, index) => {
    return (
      <ListItem key={index}>
        <Grid container>
          {rowData.map(el => (
            <Grid item xs={12} sm={6} key={el}>
              <Typography variant="body2">{el}</Typography>
            </Grid>
          ))}
        </Grid>
      </ListItem>
    );
  };

  const renderDataRow = document => {
    return (
      <ListItem
        key={document.documentId}
        button
        component={Link}
        to={`/documents/${document.documentId}`}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{document.documentTitle}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              {moment(document.documentUpdateDateString).format("LLLL")}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  if (!documents) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (documents.size === 0 && typeof payload.query === "undefined") {
    return (
      <>
        <DocumentAddDialog />
        <FixedCenter>
          <Button
            onClick={() => dispatch(openDialog(DIALOG))}
            variant="outlined"
            color="primary"
          >
            新增第一份文件
          </Button>
        </FixedCenter>
      </>
    );
  }

  return (
    <React.Fragment>
      <DocumentAddDialog />
      <Container maxWidth="md">
        <Paper>
          <SearchDataList
            onSubmit={handleSearchSubmit}
            title="文件列表"
            SearchBarProps={{
              placeholder: "文件搜尋",
              onChange: handleSearchChange,
              defaultValue: payload.query
            }}
            toolsbar={
              <Tooltip title="新增文件">
                <IconButton onClick={() => dispatch(openDialog(DIALOG))}>
                  <AddBoxIcon />
                </IconButton>
              </Tooltip>
            }
            columns={["名稱", "上次更新時間"]}
            data={documents.toJS()}
            serverSide
            isEmpty={!documentsTotal}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            disablePadding
            MuiTablePaginationProps={{
              count: documentsTotal,
              labelRowsPerPage: "每頁幾筆",
              page: Math.ceil(payload.from / payload.size),
              rowsPerPage: parseInt(payload.size),
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default Documents;
