import React from "react";
import PropTypes from "prop-types";

import apis from "redux/apis";
import { List } from "immutable";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import shortUsername from "@e-group/utils/shortUsername";
import stringToColor from "@e-group/utils/stringToColor";
import grey from "@material-ui/core/colors/grey";
import {
  fetchGetUsers,
  fetchGetDocumentPermissions,
  fetchPostDocumentPermissions,
  fetchDeleteDocumentPermission,
  setInvitedUsers,
  getNoPermissionUsers,
  getUsersIsGetting,
  getDocumentPermissions,
  getDocumentPermissionsIsPosting,
  getInvitedUsers,
} from "./redux";

import Button from "@e-group/material/Button";
import ButtonMenu from "@e-group/material-lab/ButtonMenu";
import TextLoading from "@e-group/material/TextLoading";
import Divider from "@material-ui/core/Divider";
import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import MuiButton from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  rootButton: {
    margin: theme.spacing(0, 1),
  },
  rootPopover: {
    maxHeight: 520,
  },
  paper: {
    width: 400,
  },
  content: {
    maxHeight: 300,
    overflow: "auto",
  },
  noPermissionList: {
    backgroundColor: grey[100],
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  actionIcon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: -3,
  },
}));

const DocumentShareButton = ({ isEditable }) => {
  const { documentId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const buttonEl = React.useRef();
  const [openShareListPanel, setOpenShareListPanel] = React.useState(false);
  const noPermissionUsers = useSelector(getNoPermissionUsers);
  const documentPermissions = useSelector(getDocumentPermissions);
  const isPosting = useSelector(getDocumentPermissionsIsPosting);
  const usersIsGetting = useSelector(getUsersIsGetting);
  const invitedUsers = useSelector(getInvitedUsers);

  const handleOpen = () => setOpenShareListPanel(true);

  const handleClose = () => setOpenShareListPanel(false);

  const handleChange = (e) => {
    dispatch(
      fetchGetUsers({
        query: e.target.value,
      })
    );
  };

  const handleAddInvitedUser = (el) => (e) => {
    dispatch(setInvitedUsers(invitedUsers.push(el).toSet().toList()));
  };

  const handleDeleteInvitedUser = (el) => (e) => {
    dispatch(
      setInvitedUsers(
        invitedUsers
          .toSet()
          .subtract(List([el]))
          .toList()
      )
    );
  };

  const handleAddPermissions = () => {
    dispatch(
      fetchPostDocumentPermissions({
        documentId,
        payload: invitedUsers.map((el) => ({
          documentPermissionType: "reader",
          documentUrl: window.location.href,
          user: {
            loginId: el.get("loginId"),
          },
        })),
      })
    );
  };

  const handleUpdatePermission = (el, type) => () => {
    apis.eds
      .fetchPatchDocumentPermission({
        documentId,
        documentPermissionType: type,
        user: {
          loginId: el.getIn(["user", "loginId"]),
        },
      })
      .then(() => {
        dispatch(
          fetchGetDocumentPermissions({
            isListOnly: 1,
            documentId,
          })
        );
      });
  };

  const handleDeletePermission = (el) => (e) => {
    dispatch(
      fetchDeleteDocumentPermission({
        documentId,
        user: {
          loginId: el.getIn(["user", "loginId"]),
        },
      })
    );
  };

  const renderInvitedUsers = () => {
    return (
      <Box px={2} pt={1}>
        {invitedUsers.map((el) => (
          <Chip
            className={classes.chip}
            key={el.get("loginId")}
            label={el.get("userName")}
            onDelete={handleDeleteInvitedUser(el)}
          />
        ))}
      </Box>
    );
  };

  const renderNoPermissionList = () => {
    let arr = noPermissionUsers;
    if (!arr) {
      return (
        <Box px={2} py={5}>
          <Typography align="center">輸入人名搜尋吧！</Typography>
        </Box>
      );
    }
    if (invitedUsers) {
      arr = arr.toSet().subtract(invitedUsers).toList();
    }
    return (
      <MuiList disablePadding dense className={classes.noPermissionList}>
        {arr.map((el) => {
          const userName = el.get("userName");
          return (
            <ListItem
              key={el.get("loginId")}
              button
              onClick={handleAddInvitedUser(el)}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: stringToColor(userName) }}>
                  {shortUsername(userName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={userName}
                secondary={el.get("userAccount")}
              />
            </ListItem>
          );
        })}
      </MuiList>
    );
  };

  const renderAction = (el) => {
    const documentPermissionType = el.get("documentPermissionType");
    const isOwner = documentPermissionType === "owner";
    const isEditor = documentPermissionType === "editor";
    const isReader = documentPermissionType === "reader";

    if (isOwner) {
      return <Typography variant="caption">擁有者</Typography>;
    }
    if (isEditable) {
      return (
        <ButtonMenu
          id={`menu-button-${el.getIn(["user", "loginId"])}`}
          button={
            <Typography variant="caption" className={classes.action}>
              {isEditor && "可以編輯"}
              {isReader && "可以瀏覽"}
              <ArrowDropDownIcon className={classes.actionIcon} />
            </Typography>
          }
        >
          <MenuItem onClick={handleUpdatePermission(el, "reader")}>
            可以瀏覽
          </MenuItem>
          <MenuItem onClick={handleUpdatePermission(el, "editor")}>
            可以編輯
          </MenuItem>
          <MenuItem onClick={handleDeletePermission(el)}>移除</MenuItem>
        </ButtonMenu>
      );
    }
    return (
      <Typography variant="caption">
        {isEditor && "可以編輯"}
        {isReader && "可以瀏覽"}
      </Typography>
    );
  };

  const renderPermissionList = () => {
    if (!documentPermissions) return undefined;

    return (
      <MuiList disablePadding>
        {documentPermissions
          .sort((a, b) =>
            a.get("documentPermissionType") === "owner" ? -1 : 0
          )
          .map((el) => {
            const userName = el.getIn(["user", "userName"]);
            return (
              <ListItem key={el.getIn(["user", "loginId"])}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: stringToColor(userName),
                    }}
                  >
                    {shortUsername(userName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={userName}
                  secondary={el.getIn(["user", "userAccount"])}
                />
                <ListItemSecondaryAction>
                  {renderAction(el)}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </MuiList>
    );
  };

  return (
    <React.Fragment>
      <MuiButton
        ref={buttonEl}
        className={classes.rootButton}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        邀請
      </MuiButton>
      <Popover
        className={classes.rootPopover}
        open={openShareListPanel}
        anchorEl={buttonEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper className={classes.paper}>
          <Box px={2} py={1}>
            <TextLoading
              loading={usersIsGetting}
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="搜尋"
              onChange={handleChange}
            />
          </Box>
          <Divider />
          {invitedUsers.size !== 0 && renderInvitedUsers()}
          {invitedUsers.size === 0 ? (
            <React.Fragment>
              {renderNoPermissionList()}
              {renderPermissionList()}
            </React.Fragment>
          ) : (
            <div className={classes.content}>{renderNoPermissionList()}</div>
          )}
          {invitedUsers.size !== 0 && (
            <React.Fragment>
              <Divider />
              <Box p={2} py={1} textAlign="right">
                <Button
                  loading={isPosting}
                  MuiButtonProps={{
                    variant: "outlined",
                    color: "primary",
                    onClick: handleAddPermissions,
                  }}
                >
                  寄出邀請
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

DocumentShareButton.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};

DocumentShareButton.defaultProps = {
  isEditable: true,
};

export default DocumentShareButton;
