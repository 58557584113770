import React from "react";

import { useLocation } from "react-router";
import useIsOpen from "@e-group/hooks/useIsOpen";

import NestedSideMenu from "@e-group/material-module/NestedSideMenu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import HideOnScroll from "components/HideOnScroll";
import UserAvatarMenu from "components/UserAvatarMenu";

const Navbar = ({ routes }) => {
  const location = useLocation();
  const { isOpen, handleOpen, handleClose } = useIsOpen(false);

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose}>
        <NestedSideMenu
          routes={routes}
          location={location}
          NestedListItemProps={{
            MuiListItemProps: {
              onClick: handleClose
            }
          }}
          NestedListItemItemsProps={{
            MuiListItemProps: {
              onClick: handleClose
            }
          }}
        />
      </Drawer>
      <HideOnScroll>
        <AppBar color="default">
          <Toolbar>
            <IconButton onClick={handleOpen} edge="start" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Box flexGrow={1} />
            <Button
              component="a"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfACqIP0cXtsp62YJve-Jwgd-jlt-Hb7r5WS5EV6_6gmUQzug/viewform"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              意見回饋
            </Button>
            <UserAvatarMenu id="user-menu" />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default Navbar;
