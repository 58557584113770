import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import { fetchGetOrganization, getOrganization } from "./redux";

import BackAppbar from "@e-group/material-module/BackAppbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import FixedCenter from "components/FixedCenter";

const OrganizationRoot = ({ route, location, history }) => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const organization = useSelector(getOrganization);

  React.useEffect(() => {
    dispatch(
      fetchGetOrganization({
        organizationId
      })
    );
  }, [dispatch, organizationId]);

  if (!organization) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <BackAppbar
        location={location}
        history={history}
        fadeIn
        position="fixed"
        elevation={0}
        backPath="/crm/organizations"
      >
        <Typography variant="h6">
          {organization.get("organizationName")}
        </Typography>
      </BackAppbar>
      {renderRoutes(route.routes, { organization })}
    </>
  );
};

OrganizationRoot.propTypes = {
  // react-router props
  route: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default OrganizationRoot;
