import React from "react";

import { Redirect } from "react-router-dom";
import Root from "landingPages/Root";
import Login from "landingPages/Login";
import FacebookLogin from "landingPages/Login/FacebookLogin";
import GoogleLogin from "landingPages/Login/GoogleLogin";
import Privacy from "landingPages/Privacy";
import Terms from "landingPages/Terms";

import Document from "pages/Document";

export default [
  {
    path: "/documents",
    component: Root,
    routes: [
      {
        path: "/documents/:documentId",
        exact: true,
        component: Document
      },
      {
        path: "/*",
        render: () => <Redirect to="/login" />
      }
    ]
  },
  {
    path: "/privacy",
    component: Privacy
  },
  {
    path: "/terms",
    component: Terms
  },
  {
    path: "/fb-login",
    component: FacebookLogin
  },
  {
    path: "/g-login",
    component: GoogleLogin
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "*",
    render: () => <Redirect to="/login" />
  }
];
