import React from "react";

import { clearApiResponse } from "@e-group/redux-modules/immutable/apis";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import bg from "./bg.jpeg";
import {
  fetchGetGoogleLoginUrl,
  fetchGetFacebookLoginUrl,
  getGoogleLoginUrl,
  getFacebookLoginUrl,
} from "./redux";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import FacebookIcon from "@material-ui/icons/Facebook";
import GoogleButton from "./GoogleButton";
import FacebookButton from "./FacebookButton";
import GoogleIcon from "./GoogleIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",

    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.4)",
      zIndex: 0,
    },
  },
  container: {
    position: "absolute",
    width: "450px",
    zIndex: 1,
    top: "20%",
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      left: 0,
      transform: "none",
      width: "100%",
    },
  },
  paper: {
    border: "1px solid #dadce0",
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
  },
  btn: {
    marginBottom: theme.spacing(),
  },
  icon: {
    position: "absolute",
    left: theme.spacing(),
  },
}));

/**
 * 登入頁面
 * 使用帳號密碼登入系統。
 */
const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const googleLoginUrl = useSelector(getGoogleLoginUrl);
  const facebookLoginUrl = useSelector(getFacebookLoginUrl);

  React.useEffect(() => {
    if (googleLoginUrl) {
      window.open(googleLoginUrl, "_self");
      dispatch(clearApiResponse(fetchGetGoogleLoginUrl()));
    }
  }, [dispatch, googleLoginUrl]);

  React.useEffect(() => {
    if (facebookLoginUrl) {
      window.open(facebookLoginUrl, "_self");
      dispatch(clearApiResponse(fetchGetFacebookLoginUrl()));
    }
  }, [dispatch, facebookLoginUrl]);

  const handleGoogleLogin = () => {
    dispatch(fetchGetGoogleLoginUrl());
  };

  const handleFbLogin = () => {
    dispatch(fetchGetFacebookLoginUrl());
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <Box p="36px 40px">
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              登入
            </Typography>
            <Typography variant="body1" align="center">
              使用 InfoCenter 系統
            </Typography>
          </Box>
          <Box p="0 40px 36px">
            <GoogleButton
              variant="contained"
              fullWidth
              className={classes.btn}
              onClick={handleGoogleLogin}
            >
              <GoogleIcon className={classes.icon} /> 使用 google
            </GoogleButton>
            <FacebookButton
              variant="contained"
              color="primary"
              fullWidth
              className={classes.btn}
              onClick={handleFbLogin}
            >
              <FacebookIcon className={classes.icon} /> 使用 facebook
            </FacebookButton>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
