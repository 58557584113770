import React from "react";
import { Redirect } from "react-router-dom";
import Root from "pages/Root";
import DocumentsRoot from "pages/Documents/DocumentsRoot";
import Documents from "pages/Documents";
import Document from "pages/Document";
import DocumentVersion from "pages/DocumentVersion";
import Feedbacks from "pages/Feedbacks";
import OrganizationsRoot from "pages/Organizations/OrganizationsRoot";
import Organizations from "pages/Organizations";
import OrganizationRoot from "pages/Organization/OrganizationRoot";
import Organization from "pages/Organization";
import InvoicesRoot from "pages/Invoices";
import B2BInvoices from "pages/Invoices/B2BInvoices";
import B2CInvoices from "pages/Invoices/B2CInvoices";
import InvoicePeriods from "pages/Invoices/InvoicePeriods";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BusinessIcon from "@material-ui/icons/Business";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CommentIcon from "@material-ui/icons/Comment";

export default [
  {
    component: Root,
    routes: [
      {
        path: "/documents",
        component: DocumentsRoot,
        breadcrumbName: "EDS",
        icon: <LibraryBooksIcon />,
        routes: [
          {
            path: "/documents",
            exact: true,
            breadcrumbName: "文件列表",
            component: Documents,
          },
          {
            component: DocumentVersion,
            path: "/documents/:documentId/document-versions/:documentVersionId",
          },
          {
            component: Document,
            path: "/documents/:documentId",
          },
        ],
      },
      {
        path: "/feedbacks",
        component: Feedbacks,
        breadcrumbName: "回饋管理",
        icon: <CommentIcon />,
      },
      {
        path: "/crm/organizations",
        component: OrganizationsRoot,
        breadcrumbName: "CRM",
        icon: <BusinessIcon />,
        routes: [
          {
            path: "/crm/organizations",
            exact: true,
            breadcrumbName: "單位管理",
            component: Organizations,
          },
          {
            path: "/crm/organizations/:organizationId",
            component: OrganizationRoot,
            routes: [
              {
                path: "/crm/organizations/:organizationId",
                exact: true,
                breadcrumbName: "單位總覽",
                component: Organization,
              },
            ],
          },
        ],
      },
      {
        path: "/invoice",
        component: InvoicesRoot,
        breadcrumbName: "發票",
        icon: <ReceiptIcon />,
        routes: [
          {
            path: "/invoice/b2b",
            breadcrumbName: "B2B 專區",
            component: B2BInvoices,
          },
          {
            path: "/invoice/b2c",
            breadcrumbName: "B2C 專區",
            component: B2CInvoices,
          },
          {
            path: "/invoice/invoice-periods",
            breadcrumbName: "字軌管理",
            component: InvoicePeriods,
          },
        ],
      },
      {
        path: "*",
        render: () => <Redirect to="/documents" />,
      },
    ],
  },
];
