import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { of, concat } from "rxjs";
import {
  fetchGetOptionsRequest,
  fetchGetOptionsSuccess,
  fetchGetOptionsFailure,
  FETCH_GET_OPTIONS
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetOptionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_OPTIONS,
  apiName: "crm.fetchGetOptions",
  fetchRequest: fetchGetOptionsRequest,
  handleSuccess: response => [fetchGetOptionsSuccess(fromJS(response.data))],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOptionsFailure(error))
    )
});

export default combineEpics(fetchGetOptionsEpic);
