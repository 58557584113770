import React from "react";

import s3Url from "utils/s3Url";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import apis from "redux/apis";
import {
  fetchGetDocument,
  fetchPatchDocument,
  fetchGetDocumentPermissions,
  clearDocument,
  toggleDrawer,
  getDocument,
  getDocumentError,
  getDocumentIsPublic,
  getDocumentIsEditable,
  getDocumentHasPermission,
} from "./redux";

import BackAppbar from "@e-group/material-module/BackAppbar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import HistoryIcon from "@material-ui/icons/History";
import FixedCenter from "components/FixedCenter";
import EdsCKEditor from "components/EdsCKEditor";
import ApiErrorBoundaries from "components/ApiErrorBoundaries";
import UserAvatarMenu from "components/UserAvatarMenu";
import StyledTextField from "./StyledTextField";
import DocumentStatusIcon from "./DocumentStatusIcon";
import DocumentVersionsDrawer from "./DocumentVersionsDrawer";
import DocumentShareButton from "./DocumentShareButton";
import DocumentLinkButton from "./DocumentLinkButton";
import DrawioIframe from "./DrawioIframe";
import DocumentAvatars from "./DocumentAvatars";
import {
  initDrawioEditor,
  saveing,
  createDrawioByPost,
  updateDrawioByPatch,
} from "./utils";

const DRAWIO_EDITOR_URL =
  "https://embed.diagrams.net/?embed=1&ui=atlas&spin=1&proto=json&lang=zh-tw";

const useStyles = makeStyles((theme) => ({
  title: {
    "& .MuiInput-root": theme.typography.h3,
  },
}));

const Document = () => {
  const [cookies] = useCookies();
  const isLogin = cookies.lid && cookies.tid && cookies.u_info;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { documentId } = useParams();
  const iframeEl = React.useRef();
  const dispatch = useDispatch();
  const documentData = useSelector(getDocument);
  const documentError = useSelector(getDocumentError);
  const isPublic = useSelector(getDocumentIsPublic);
  const isEditable = useSelector(getDocumentIsEditable);
  const hasPermission = useSelector(getDocumentHasPermission);
  const [iframeUrl, setIframeUrl] = React.useState();
  const [displayIframe, setDisplayIframe] = React.useState(false);

  const matche1 = useMediaQuery("(min-width:0px) and (orientation: landscape)");
  const matche2 = useMediaQuery("(min-width:600px)");
  let viewportTopOffset = 56;

  if (matche1) {
    viewportTopOffset = 48;
  }
  if (matche2) {
    viewportTopOffset = 64;
  }

  React.useEffect(() => {
    dispatch(
      fetchGetDocument({
        documentId,
      })
    );
    return () => {
      dispatch(clearDocument());
    };
  }, [dispatch, documentId]);

  React.useEffect(() => {
    if (isLogin && hasPermission) {
      dispatch(
        fetchGetDocumentPermissions({
          isListOnly: 1,
          documentId,
        })
      );
    }
  }, [dispatch, documentId, hasPermission, isLogin]);

  if (documentError) {
    return <ApiErrorBoundaries error={documentError} />;
  }

  if (!documentData) {
    return (
      <FixedCenter>
        <CircularProgress disableShrink />
      </FixedCenter>
    );
  }

  /**
   * If user is unlogin and document is public then we display data only.
   */
  if (!isLogin) {
    return (
      <Container>
        <Typography variant="h3">
          {documentData.get("documentTitle")}
        </Typography>
        <Box mt="6px" />
        <Typography color="textSecondary" variant="body2">
          上次更新時間：
          {moment(documentData.get("documentUpdateDateString")).format("LLLL")}
        </Typography>
        <Box mt="28px" />
        <EdsCKEditor
          data={documentData.get("documentContentEditor")}
          disabled
        />
      </Container>
    );
  }

  const handleEditorChange = (event, editor) => {
    dispatch(
      fetchPatchDocument({
        documentId,
        documentContentEditor: editor.getData(),
      })
    );
  };

  const handleTitleChange = (event) => {
    dispatch(
      fetchPatchDocument({
        documentId,
        documentTitle: event.target.value,
      })
    );
  };

  const handleNewDrawio = (e, editor) => {
    const iframe = iframeEl.current;
    let drawioId;
    const receive = async (e) => {
      if (typeof e.data === "undefined") return;
      const msg = JSON.parse(e.data);
      switch (msg.event) {
        case "init":
          initDrawioEditor(iframe, "");
          iframe.contentWindow.postMessage(
            JSON.stringify({
              action: "template",
            }),
            "*"
          );
          break;
        case "autosave":
          saveing(iframe);
          if (!drawioId) {
            drawioId = await createDrawioByPost({
              iframe,
              editor,
              xml: msg.xml,
            });
          } else {
            updateDrawioByPatch({
              iframe,
              editor,
              drawioId,
              xml: msg.xml,
            });
          }
          break;
        case "exit":
          setDisplayIframe(false);
          setIframeUrl("");
          window.removeEventListener("message", receive);
          break;
        default:
          break;
      }
    };

    setDisplayIframe(true);
    setIframeUrl(DRAWIO_EDITOR_URL);
    window.addEventListener("message", receive);
  };

  const handleEditDrawio = async (e, editor) => {
    let drawioSrc = editor.model.document.selection
      .getSelectedElement()
      .getAttribute("src");
    if (drawioSrc.indexOf("https") === -1) {
      drawioSrc = s3Url(drawioSrc);
    }
    const drawioHtml = await apis.eds
      .fetchGetDrawioHtml(drawioSrc)
      .then((res) => res.data);
    const drawioId = drawioSrc
      .replace(".html", "")
      .split("/")
      .pop()
      .split("_")
      .pop();
    const iframe = iframeEl.current;

    const receive = (e) => {
      if (typeof e.data === "undefined") return;
      const msg = JSON.parse(e.data);
      switch (msg.event) {
        case "init":
          initDrawioEditor(iframe, drawioHtml);
          break;
        case "autosave":
          saveing(iframe);
          updateDrawioByPatch({
            iframe,
            editor,
            drawioId,
            xml: msg.xml,
          });
          break;
        case "exit":
          setDisplayIframe(false);
          setIframeUrl("");
          window.removeEventListener("message", receive);
          break;
        default:
          break;
      }
    };

    setDisplayIframe(true);
    setIframeUrl(DRAWIO_EDITOR_URL);
    window.addEventListener("message", receive);
  };

  const renderTools = () => {
    /**
     * If use is login but without any permission.
     */
    if (!hasPermission) {
      return (
        <React.Fragment>
          <Typography variant="h6">
            {documentData.get("documentTitle")}
          </Typography>
          <DocumentStatusIcon isPublic={isPublic} />
          <Box flexGrow={1} />
          <DocumentLinkButton hidePublicSwitch={!isEditable} />
          <UserAvatarMenu id="document-user-menu" />
        </React.Fragment>
      );
    }

    /**
     * If user is login but without edit permission.
     */
    if (!isEditable) {
      return (
        <React.Fragment>
          <Typography variant="h6">
            {documentData.get("documentTitle")}
          </Typography>
          <DocumentStatusIcon isPublic={isPublic} />
          <Box flexGrow={1} />
          <DocumentAvatars />
          <DocumentShareButton isEditable={false} />
          <DocumentLinkButton hidePublicSwitch={!isEditable} />
          <UserAvatarMenu id="document-user-menu" />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Typography variant="h6">
          {documentData.get("documentTitle")}
        </Typography>
        <DocumentStatusIcon isPublic={isPublic} />
        <Box flexGrow={1} />
        <DocumentAvatars />
        <DocumentShareButton />
        <DocumentLinkButton hidePublicSwitch={!isEditable} />
        <Tooltip title="查看歷史紀錄">
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(toggleDrawer(true));
            }}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
        <UserAvatarMenu id="document-user-menu" />
      </React.Fragment>
    );
  };

  const renderContent = () => {
    if (!hasPermission || !isEditable) {
      return (
        <React.Fragment>
          <Typography variant="h3">
            {documentData.get("documentTitle")}
          </Typography>
          <Box mt="6px" />
          <Typography color="textSecondary" variant="body2">
            上次更新時間：
            {moment(documentData.get("documentUpdateDateString")).format(
              "LLLL"
            )}
          </Typography>
          <Box mt="28px" />
          <EdsCKEditor
            data={documentData.get("documentContentEditor")}
            disabled
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <StyledTextField
          classes={{
            root: classes.title,
          }}
          onChange={handleTitleChange}
          fullWidth
          defaultValue={documentData.get("documentTitle")}
        />
        <Box mt="6px" />
        <Typography color="textSecondary" variant="body2">
          上次更新時間：
          {moment(documentData.get("documentUpdateDateString")).format("LLLL")}
        </Typography>
        <Box mt="28px" />
        <EdsCKEditor
          config={{
            toolbar: {
              viewportTopOffset,
            },
            placeholder: "在這裡寫點什麼吧！",
            simpleUpload: {
              // The URL that the images are uploaded to.
              uploadUrl: `${process.env.REACT_APP_API_BASE_URL}/eds/editor/upload`,
            },
            drawio: {
              onCreateClick: handleNewDrawio,
            },
            drawioEdit: {
              onOpenClick: handleEditDrawio,
            },
          }}
          data={documentData.get("documentContentEditor")}
          onChange={handleEditorChange}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Fade in={displayIframe}>
        <DrawioIframe src={iframeUrl} ref={iframeEl} />
      </Fade>
      <BackAppbar
        color="default"
        location={location}
        history={history}
        fadeIn
        position="fixed"
        elevation={0}
        backPath="/documents"
      >
        {renderTools()}
      </BackAppbar>
      <DocumentVersionsDrawer />
      <Container>{renderContent()}</Container>
    </React.Fragment>
  );
};

export default Document;
