import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchPostLogout,
  fetchPostLogoutRequest,
  fetchPostLogoutSuccess,
  fetchPostLogoutFailure
} = actionCreators.components.userAvatarMenu;

/** Types */
export const FETCH_POST_LOGOUT = fetchPostLogout().type;
export const FETCH_POST_LOGOUT_REQUEST = fetchPostLogoutRequest().type;
export const FETCH_POST_LOGOUT_SUCCESS = fetchPostLogoutSuccess().type;
export const FETCH_POST_LOGOUT_FAILURE = fetchPostLogoutFailure().type;
