import axios from "axios";
import queryString from "query-string";

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10 * 60 * 1000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const edsFetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/eds`,
  timeout: 10 * 60 * 1000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const crmFetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/crm`,
  timeout: 10 * 60 * 1000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const invoiceFetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/invoice`,
  timeout: 10 * 60 * 1000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const tools = {
  /**
   * Log errors.
   */
  fetchPostLogs: (payload) => fetcher.post("/logs", payload),
};

const basic = {
  /**
   * Users
   */

  /**
   * Get Facebook login URL
   */
  fetchGetFacebookLoginUrl: (payload) =>
    fetcher.get("/user/facebook/login-url"),
  /**
   * Facebook login
   */
  fetchPostFacebookLogin: (payload) =>
    fetcher.post("/user/facebook/login", payload),
  /**
   * Get Google login URL
   */
  fetchGetGoogleLoginUrl: (payload) => fetcher.get("/user/google/login-url"),
  /**
   * Google login
   */
  fetchPostGoogleLogin: (payload) =>
    fetcher.post("/user/google/login", payload),

  /**
   * 使用者登出
   */
  fetchPostLogout: () => fetcher.post("/logout"),
};

const eds = {
  /**
   * Users
   */
  /**
   * 取得使用者
   */
  fetchGetUsers: (payload) =>
    edsFetcher.get(`/users?${queryString.stringify(payload)}`),

  /**
   * Documents
   */

  /**
   * 取得文件列表
   */
  fetchGetDocuments: (payload) =>
    edsFetcher.get(`/documents?${queryString.stringify(payload)}`),

  /**
   * 新增文件
   */
  fetchPostDocument: (payload) => edsFetcher.post("/documents", payload),

  /**
   * 取得文件
   */
  fetchGetDocument: ({ documentId }) =>
    edsFetcher.get(`/documents/${documentId}`),

  /**
   * 編輯文件
   */
  fetchPatchDocument: ({ documentId, ...payload }) =>
    edsFetcher.patch(`/documents/${documentId}`, payload),

  /**
   * 刪除文件
   */
  fetchDeleteDocument: ({ documentId }) =>
    edsFetcher.delete(`/documents/${documentId}`),

  /**
   * Document Versions
   */

  /**
   * 取得文件版本列表
   */
  fetchGetDocumentVersions: ({ documentId, ...payload }) =>
    edsFetcher.get(
      `/documents/${documentId}/document-versions?${queryString.stringify(
        payload
      )}`
    ),

  /**
   * 取得文件版本
   */
  fetchGetDocumentVersion: ({ documentId, documentVersionId }) =>
    edsFetcher.get(
      `/documents/${documentId}/document-versions/${documentVersionId}`
    ),

  /**
   * 新增文件版本
   */
  fetchPostDocumentVersion: ({ documentId, ...payload }) =>
    edsFetcher.post(`/documents/${documentId}/document-versions`, payload),

  /**
   * 更新文件版本
   */
  fetchPatchDocumentVersion: ({ documentId, documentVersionId, ...payload }) =>
    edsFetcher.patch(
      `/documents/${documentId}/document-versions/${documentVersionId}`,
      payload
    ),

  /**
   * Document Permissions
   */

  /**
   * 取得文件權限列表
   */
  fetchGetDocumentPermissions: ({ documentId, ...payload }) =>
    edsFetcher.get(
      `/documents/${documentId}/document-permissions?${queryString.stringify(
        payload
      )}`
    ),

  /**
   * 新增多筆文件權限
   */
  fetchPostDocumentPermissions: ({ documentId, payload }) =>
    edsFetcher.post(
      `/documents/${documentId}/document-permissions/insert-list`,
      payload
    ),

  /**
   * 編輯文件權限
   */
  fetchPatchDocumentPermission: ({ documentId, ...payload }) =>
    edsFetcher.patch(`/documents/${documentId}/document-permissions`, payload),

  /**
   * 刪除文件權限
   */
  fetchDeleteDocumentPermission: ({ documentId, ...payload }) =>
    edsFetcher.delete(`/documents/${documentId}/document-permissions`, {
      data: payload,
    }),

  /**
   * Drawios
   */

  /**
   * 取得 Drawio html 字串
   *
   * We need timestamp to clear get cache to solve CORS problem.
   * Please read issue #147 and stackoverflow for more info.
   * https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
   */
  fetchGetDrawioHtml: (payload) =>
    axios.get(`${payload}?timestamp=${new Date().getTime()}`),

  /**
   * 新增 Drawio 檔案
   */
  fetchPostDrawio: (payload) => edsFetcher.post("/drawios", payload),

  /**
   * 編輯 Drawio 檔案
   */
  fetchPatchDrawio: ({ drawioId, ...payload }) =>
    edsFetcher.patch(`/drawios/${drawioId}`, payload),

  /**
   * 刪除 Drawio 檔案
   */
  fetchDeleteDrawio: ({ drawioId }) =>
    edsFetcher.delete(`/drawios/${drawioId}`),
};

const feedbacks = {
  fetchGetFeedback: ({ organizationFeedbackId }) =>
    fetcher.get(
      `/organizations/4aba77788ae94eca8d6ff330506af945/organization-services/0a417649f952486d97b4cb176ceecb2d/feedbacks/${organizationFeedbackId}`
    ),
  fetchGetFeedbacks: (payload) =>
    fetcher.get(
      `/organizations/4aba77788ae94eca8d6ff330506af945/organization-services/0a417649f952486d97b4cb176ceecb2d/feedbacks?${queryString.stringify(
        payload
      )}`
    ),
};

const crm = {
  /**
   * tools
   */
  fetchGetOptions: () => crmFetcher.get("/options"),

  /**
   * Orgs
   */

  /**
   * 取得單位列表
   */
  fetchGetOrganizations: (payload) =>
    crmFetcher.get(`/organizations?${queryString.stringify(payload)}`),

  /**
   * 新增單位
   */
  fetchPostOrganization: (payload) =>
    crmFetcher.post("/organizations", payload),

  /**
   * 取得單位
   */
  fetchGetOrganization: ({ organizationId }) =>
    crmFetcher.get(`/organizations/${organizationId}`),

  /**
   * 編輯單位
   */
  fetchPatchOrganization: ({ organizationId, ...payload }) =>
    crmFetcher.patch(`/organizations/${organizationId}`, payload),

  /**
   * 刪除單位
   */
  fetchDeleteOrganization: ({ organizationId }) =>
    crmFetcher.delete(`/organizations/${organizationId}`),
};

const b2bApis = {
  // 取得發票清單(有些沒有發票號碼)
  fetchGetInvoices: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/B2B/invoices?${queryString.stringify(
        payload
      )}`
    ),

  // 開立發票存證 A0401
  fetchPostInvoice: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.post(`/company/${sellerIdentifier}/B2B/invoices`, payload),

  // 取得交易發票內容
  fetchGetInvoice: ({ sellerIdentifier, invoiceId }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/B2B/invoices/${invoiceId}`
    ),

  // 刪除交易發票(未開立的才能刪除)
  fetchDeleteInvoice: ({ sellerIdentifier, invoiceId }) =>
    invoiceFetcher.delete(
      `/company/${sellerIdentifier}/B2B/invoices/${invoiceId}`
    ),

  // 編輯交易發票內容
  fetchPatchInvoice: ({ sellerIdentifier, invoiceId, ...payload }) =>
    invoiceFetcher.patch(
      `/company/${sellerIdentifier}/B2B/invoices/${invoiceId}`,
      payload
    ),

  // 開立折讓發票證明單 B0401
  fetchPostAllowance: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.post(`/company/${sellerIdentifier}/B2B/allowances`, payload),

  // 複製發票
  fetchPostCopyInvoice: ({ sellerIdentifier, invoiceId, ...payload }) =>
    invoiceFetcher.post(
      `/company/${sellerIdentifier}/B2B/invoices/${invoiceId}/copy`,
      payload
    ),
};

const b2cApis = {
  // 取得發票清單(有些沒有發票號碼)
  fetchGetInvoices: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/B2C/invoices?${queryString.stringify(
        payload
      )}`
    ),

  // 開立發票存證 C0401
  fetchPostInvoice: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.post(`/company/${sellerIdentifier}/B2C/invoices`, payload),

  // 取得交易發票內容
  fetchGetInvoice: ({ sellerIdentifier, invoiceId }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/B2C/invoices/${invoiceId}`
    ),

  // 刪除交易發票(未開立的才能刪除)
  fetchDeleteInvoice: ({ sellerIdentifier, invoiceId }) =>
    invoiceFetcher.delete(
      `/company/${sellerIdentifier}/B2C/invoices/${invoiceId}`
    ),

  // 編輯交易發票內容
  fetchPatchInvoice: ({ sellerIdentifier, invoiceId, ...payload }) =>
    invoiceFetcher.patch(
      `/company/${sellerIdentifier}/B2C/invoices/${invoiceId}`,
      payload
    ),

  // 開立折讓發票證明單 D0401
  fetchPostAllowance: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.post(`/company/${sellerIdentifier}/B2C/allowances`, payload),

  // 複製發票
  fetchPostCopyInvoice: ({ sellerIdentifier, invoiceId }) =>
    invoiceFetcher.post(
      `/company/${sellerIdentifier}/B2C/invoices/${invoiceId}/copy`
    ),

  //取得捐贈單位清單
  fetchGetNpos: (payload) =>
    invoiceFetcher.get(`/applications/npos?${queryString.stringify(payload)}`),

  //取得會員清單
  fetchGetMembers: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/users?${queryString.stringify(payload)}`
    ),

  //取得發票字軌列表
  fetchGetInvoicePeriods: ({ sellerIdentifier, ...payload }) =>
    invoiceFetcher.get(
      `/company/${sellerIdentifier}/invoice-periods?${queryString.stringify(
        payload
      )}`
    ),

  //手機條碼驗證是否正確 (是否有註冊財政部）
  fetchPostCheckPhoneBarcode: (payload) =>
    invoiceFetcher.post(`/applications/check-phone-barcode`, payload),
};

export default {
  tools,
  eds,
  crm,
  b2bApis,
  b2cApis,
  feedbacks,
  ...basic,
};
