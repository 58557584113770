import actionCreators from "redux/actionCreators";
import { createSelector } from "reselect";
import { Map } from "immutable";

/** Actions */
export const {
  fetchGetFacebookLoginUrl,
  fetchGetFacebookLoginUrlRequest,
  fetchGetFacebookLoginUrlSuccess,
  fetchGetFacebookLoginUrlFailure,

  fetchPostFacebookLogin,
  fetchPostFacebookLoginRequest,
  fetchPostFacebookLoginSuccess,
  fetchPostFacebookLoginFailure,

  fetchGetGoogleLoginUrl,
  fetchGetGoogleLoginUrlRequest,
  fetchGetGoogleLoginUrlSuccess,
  fetchGetGoogleLoginUrlFailure,

  fetchPostGoogleLogin,
  fetchPostGoogleLoginRequest,
  fetchPostGoogleLoginSuccess,
  fetchPostGoogleLoginFailure
} = actionCreators.landingPages.login;

/** Types */
export const FETCH_GET_FACEBOOK_LOGIN_URL = fetchGetFacebookLoginUrl().type;
export const FETCH_GET_FACEBOOK_LOGIN_URL_REQUEST = fetchGetFacebookLoginUrlRequest()
  .type;
export const FETCH_GET_FACEBOOK_LOGIN_URL_SUCCESS = fetchGetFacebookLoginUrlSuccess()
  .type;
export const FETCH_GET_FACEBOOK_LOGIN_URL_FAILURE = fetchGetFacebookLoginUrlFailure()
  .type;

export const FETCH_POST_FACEBOOK_LOGIN = fetchPostFacebookLogin().type;
export const FETCH_POST_FACEBOOK_LOGIN_REQUEST = fetchPostFacebookLoginRequest()
  .type;
export const FETCH_POST_FACEBOOK_LOGIN_SUCCESS = fetchPostFacebookLoginSuccess()
  .type;
export const FETCH_POST_FACEBOOK_LOGIN_FAILURE = fetchPostFacebookLoginFailure()
  .type;

export const FETCH_GET_GOOGLE_LOGIN_URL = fetchGetGoogleLoginUrl().type;
export const FETCH_GET_GOOGLE_LOGIN_URL_REQUEST = fetchGetGoogleLoginUrlRequest()
  .type;
export const FETCH_GET_GOOGLE_LOGIN_URL_SUCCESS = fetchGetGoogleLoginUrlSuccess()
  .type;
export const FETCH_GET_GOOGLE_LOGIN_URL_FAILURE = fetchGetGoogleLoginUrlFailure()
  .type;

export const FETCH_POST_GOOGLE_LOGIN = fetchPostGoogleLogin().type;
export const FETCH_POST_GOOGLE_LOGIN_REQUEST = fetchPostGoogleLoginRequest()
  .type;
export const FETCH_POST_GOOGLE_LOGIN_SUCCESS = fetchPostGoogleLoginSuccess()
  .type;
export const FETCH_POST_GOOGLE_LOGIN_FAILURE = fetchPostGoogleLoginFailure()
  .type;

/** Selectors */
const getApiStates = state =>
  state.getIn(["apis", "landingPages", "login"], Map());

const getFetchGetFacebookLoginUrl = createSelector(getApiStates, apiStates =>
  apiStates.get("fetchGetFacebookLoginUrl", Map())
);

const getFetchGetGoogleLoginUrl = createSelector(getApiStates, apiStates =>
  apiStates.get("fetchGetGoogleLoginUrl", Map())
);

export const getFacebookLoginUrl = createSelector(
  getFetchGetFacebookLoginUrl,
  fetchPost => fetchPost.get("response")
);

export const getGoogleLoginUrl = createSelector(
  getFetchGetGoogleLoginUrl,
  fetchPost => fetchPost.get("response")
);
