import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { closeDialog } from "@e-group/redux-modules/dialogs";
import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetOrganizations,
  fetchGetOrganizationsRequest,
  fetchGetOrganizationsSuccess,
  fetchGetOrganizationsFailure,
  fetchPostOrganizationRequest,
  fetchPostOrganizationSuccess,
  fetchPostOrganizationFailure,
  fetchDeleteOrganizationRequest,
  fetchDeleteOrganizationSuccess,
  fetchDeleteOrganizationFailure,
  FETCH_GET_ORGANIZATIONS,
  FETCH_POST_ORGANIZATION,
  FETCH_DELETE_ORGANIZATION,
} from "./redux";
import { DIALOG as ADD_DIALOG } from "./OrganizationAddDialog";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetOrganizationsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ORGANIZATIONS,
  apiName: "crm.fetchGetOrganizations",
  fetchRequest: fetchGetOrganizationsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.organizations
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetOrganizationsSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOrganizationsFailure(error))
    ),
});

export const fetchPostOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ORGANIZATION,
  apiName: "crm.fetchPostOrganization",
  fetchRequest: fetchPostOrganizationRequest,
  handleSuccess: (response, { schema }) => [
    fetchGetOrganizations({
      limit: 10,
      offset: 0,
    }),
    fetchPostOrganizationSuccess(),
    closeDialog(ADD_DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostOrganizationFailure(error))
    ),
});

export const fetchDeleteOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_ORGANIZATION,
  apiName: "crm.fetchDeleteOrganization",
  fetchRequest: fetchDeleteOrganizationRequest,
  handleSuccess: (response) => [
    fetchGetOrganizations({
      limit: 10,
      offset: 0,
    }),
    fetchDeleteOrganizationSuccess(),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteOrganizationFailure(error))
    ),
});

export default combineEpics(
  fetchGetOrganizationsEpic,
  fetchPostOrganizationEpic,
  fetchDeleteOrganizationEpic
);
