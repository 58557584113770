import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchPostRegister,
  fetchPostRegisterRequest,
  fetchPostRegisterSuccess,
  fetchPostRegisterFailure
} = actionCreators.landingPages.register;

/** Types */
export const FETCH_POST_REGISTER = fetchPostRegister().type;
export const FETCH_POST_REGISTER_REQUEST = fetchPostRegisterRequest().type;
export const FETCH_POST_REGISTER_SUCCESS = fetchPostRegisterSuccess().type;
export const FETCH_POST_REGISTER_FAILURE = fetchPostRegisterFailure().type;

/**
 * Selectors
 */
export const getIsLoading = state =>
  state.getIn([
    "apis",
    "landingPages",
    "register",
    "fetchPostRegister",
    "isLoading"
  ]);
