import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchGetDocument,
  fetchGetDocumentRequest,
  fetchGetDocumentSuccess,
  fetchGetDocumentFailure,
  clearDocument,

  fetchPatchDocument,
  fetchPatchDocumentRequest,
  fetchPatchDocumentSuccess,
  fetchPatchDocumentFailure,

  fetchGetDocumentVersions,
  fetchGetDocumentVersionsRequest,
  fetchGetDocumentVersionsSuccess,
  fetchGetDocumentVersionsFailure,
  clearDocumentVersions,

  fetchPostDocumentVersion,
  fetchPostDocumentVersionRequest,
  fetchPostDocumentVersionSuccess,
  fetchPostDocumentVersionFailure,

  fetchPatchDocumentVersion,
  fetchPatchDocumentVersionRequest,
  fetchPatchDocumentVersionSuccess,
  fetchPatchDocumentVersionFailure,

  fetchGetUsers,
  fetchGetUsersRequest,
  fetchGetUsersSuccess,
  fetchGetUsersFailure,

  fetchGetDocumentPermissions,
  fetchGetDocumentPermissionsRequest,
  fetchGetDocumentPermissionsSuccess,
  fetchGetDocumentPermissionsFailure,

  fetchPostDocumentPermissions,
  fetchPostDocumentPermissionsRequest,
  fetchPostDocumentPermissionsSuccess,
  fetchPostDocumentPermissionsFailure,

  fetchDeleteDocumentPermission,
  fetchDeleteDocumentPermissionRequest,
  fetchDeleteDocumentPermissionSuccess,
  fetchDeleteDocumentPermissionFailure,

  fetchPostBackupDocumentVersion,
  fetchPostBackupDocumentVersionRequest,
  fetchPostBackupDocumentVersionSuccess,
  fetchPostBackupDocumentVersionFailure,

  setInvitedUsers,
  clearInvitedUsers,
  toggleDrawer,
} = actionCreators.pages.document;

/** Types */
export const FETCH_GET_DOCUMENT = fetchGetDocument().type;
export const FETCH_GET_DOCUMENT_REQUEST = fetchGetDocumentRequest().type;
export const FETCH_GET_DOCUMENT_SUCCESS = fetchGetDocumentSuccess().type;
export const FETCH_GET_DOCUMENT_FAILURE = fetchGetDocumentFailure().type;
export const CLEAR_DOCUMENT = clearDocument().type;

export const FETCH_PATCH_DOCUMENT = fetchPatchDocument().type;
export const FETCH_PATCH_DOCUMENT_REQUEST = fetchPatchDocumentRequest().type;
export const FETCH_PATCH_DOCUMENT_SUCCESS = fetchPatchDocumentSuccess().type;
export const FETCH_PATCH_DOCUMENT_FAILURE = fetchPatchDocumentFailure().type;

export const FETCH_GET_DOCUMENT_VERSIONS = fetchGetDocumentVersions().type;
export const FETCH_GET_DOCUMENT_VERSIONS_REQUEST = fetchGetDocumentVersionsRequest()
  .type;
export const FETCH_GET_DOCUMENT_VERSIONS_SUCCESS = fetchGetDocumentVersionsSuccess()
  .type;
export const FETCH_GET_DOCUMENT_VERSIONS_FAILURE = fetchGetDocumentVersionsFailure()
  .type;
export const CLEAR_DOCUMENT_VERSIONS = clearDocumentVersions().type;

export const FETCH_POST_DOCUMENT_VERSION = fetchPostDocumentVersion().type;
export const FETCH_POST_DOCUMENT_VERSION_REQUEST = fetchPostDocumentVersionRequest()
  .type;
export const FETCH_POST_DOCUMENT_VERSION_SUCCESS = fetchPostDocumentVersionSuccess()
  .type;
export const FETCH_POST_DOCUMENT_VERSION_FAILURE = fetchPostDocumentVersionFailure()
  .type;

export const FETCH_PATCH_DOCUMENT_VERSION = fetchPatchDocumentVersion().type;
export const FETCH_PATCH_DOCUMENT_VERSION_REQUEST = fetchPatchDocumentVersionRequest()
  .type;
export const FETCH_PATCH_DOCUMENT_VERSION_SUCCESS = fetchPatchDocumentVersionSuccess()
  .type;
export const FETCH_PATCH_DOCUMENT_VERSION_FAILURE = fetchPatchDocumentVersionFailure()
  .type;

export const FETCH_GET_USERS = fetchGetUsers().type;
export const FETCH_GET_USERS_REQUEST = fetchGetUsersRequest().type;
export const FETCH_GET_USERS_SUCCESS = fetchGetUsersSuccess().type;
export const FETCH_GET_USERS_FAILURE = fetchGetUsersFailure().type;

export const FETCH_GET_DOCUMENT_PERMISSIONS = fetchGetDocumentPermissions()
  .type;
export const FETCH_GET_DOCUMENT_PERMISSIONS_REQUEST = fetchGetDocumentPermissionsRequest()
  .type;
export const FETCH_GET_DOCUMENT_PERMISSIONS_SUCCESS = fetchGetDocumentPermissionsSuccess()
  .type;
export const FETCH_GET_DOCUMENT_PERMISSIONS_FAILURE = fetchGetDocumentPermissionsFailure()
  .type;

export const FETCH_POST_DOCUMENT_PERMISSIONS = fetchPostDocumentPermissions()
  .type;
export const FETCH_POST_DOCUMENT_PERMISSIONS_REQUEST = fetchPostDocumentPermissionsRequest()
  .type;
export const FETCH_POST_DOCUMENT_PERMISSIONS_SUCCESS = fetchPostDocumentPermissionsSuccess()
  .type;
export const FETCH_POST_DOCUMENT_PERMISSIONS_FAILURE = fetchPostDocumentPermissionsFailure()
  .type;

export const FETCH_DELETE_DOCUMENT_PERMISSION = fetchDeleteDocumentPermission()
  .type;
export const FETCH_DELETE_DOCUMENT_PERMISSION_REQUEST = fetchDeleteDocumentPermissionRequest()
  .type;
export const FETCH_DELETE_DOCUMENT_PERMISSION_SUCCESS = fetchDeleteDocumentPermissionSuccess()
  .type;
export const FETCH_DELETE_DOCUMENT_PERMISSION_FAILURE = fetchDeleteDocumentPermissionFailure()
  .type;

export const FETCH_POST_BACKUP_DOCUMENT_VERSION = fetchPostBackupDocumentVersion()
  .type;
export const FETCH_POST_BACKUP_DOCUMENT_VERSION_REQUEST = fetchPostBackupDocumentVersionRequest()
  .type;
export const FETCH_POST_BACKUP_DOCUMENT_VERSION_SUCCESS = fetchPostBackupDocumentVersionSuccess()
  .type;
export const FETCH_POST_BACKUP_DOCUMENT_VERSION_FAILURE = fetchPostBackupDocumentVersionFailure()
  .type;

export const SET_INVITED_USERS = setInvitedUsers().type;
export const CLEAR_INVITED_USERS = clearInvitedUsers().type;
export const TOGGLE_DRAWER = toggleDrawer().type;

/** Selectors **/
/**
 * Ui states
 */
const getUiStates = (state) => state.getIn(["pages", "Document"]);

export const getDocument = createSelector(
  getUiStates,
  getEntities,
  (uiStates, entities) =>
    denormalize(uiStates.get("document"), schema.document, entities)
);

export const getDocumentIsPublic = createSelector(
  getDocument,
  (documentData) => {
    if (documentData && documentData.has("isPublic")) {
      return Boolean(documentData.get("isPublic"));
    }
    return undefined;
  }
);

export const getDocumentIsEditable = createSelector(
  getDocument,
  (documentData) => {
    if (
      documentData &&
      documentData.hasIn(["documentPermission", "documentPermissionType"])
    ) {
      const type = documentData.getIn([
        "documentPermission",
        "documentPermissionType",
      ]);
      return type === "owner" || type === "editor";
    }
    return undefined;
  }
);

export const getDocumentHasPermission = createSelector(
  getDocument,
  (documentData) => {
    if (documentData) {
      return documentData.has("documentPermission");
    }
    return undefined;
  }
);

export const getDocumentVersions = createSelector(
  getUiStates,
  getEntities,
  (uiStates, entities) => {
    if (uiStates.has("documentVersions")) {
      return denormalize(
        uiStates.get("documentVersions"),
        schema.documentVersions,
        entities
      )
        .sortBy((el) =>
          new Date(el.get("documentVersionCreateDateString")).getTime()
        )
        .reverse();
    }
    return undefined;
  }
);

export const getInvitedUsers = createSelector(getUiStates, (uiStates) =>
  uiStates.get("invitedUsers")
);

export const getIsOpen = createSelector(getUiStates, (uiStates) =>
  uiStates.get("isOpen")
);

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(["apis", "pages", "document"], Map());

const getFetchGetDocument = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetDocument", Map())
);

const getFetchGetDocumentVersions = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetDocumentVersions", Map())
);

const getFetchPostBackupDocumentVersion = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchPostBackupDocumentVersion", Map())
);

const getFetchGetDocumentPermissions = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchGetDocumentPermissions", Map())
);

const getFetchPostDocumentPermissions = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchPostDocumentPermissions", Map())
);

const getFetchGetUsers = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetUsers", Map())
);

export const getDocumentError = createSelector(
  getFetchGetDocument,
  (fetchGet) => {
    if (fetchGet.get("isError")) {
      return fetchGet.get("error");
    }
    return undefined;
  }
);

export const getDocumentVersionsTotal = createSelector(
  getFetchGetDocumentVersions,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getDocumentVersionsIsGetting = createSelector(
  getFetchGetDocumentVersions,
  (fetchGet) => fetchGet.get("isLoading")
);

export const getBackupDocumentVersionIsPosting = createSelector(
  getFetchPostBackupDocumentVersion,
  (fetchPost) => fetchPost.get("isLoading")
);

export const getDocumentPermissions = createSelector(
  getFetchGetDocumentPermissions,
  getEntities,
  (fetchGet, entities) => {
    if (fetchGet.has("response")) {
      return denormalize(
        fetchGet.get("response"),
        schema.documentPermissions,
        entities
      );
    }
    return undefined;
  }
);

export const getNoPermissionUsers = createSelector(
  getDocumentPermissions,
  getFetchGetUsers,
  getEntities,
  (documentPermissions, fetchGetUsers, entities) => {
    if (documentPermissions && fetchGetUsers.has("response")) {
      const documentPermissionLoginIds = documentPermissions
        .map((el) => el.getIn(["user", "loginId"]))
        .toSet();
      const noPermissionUserLoginIds = fetchGetUsers
        .get("response")
        .toSet()
        .subtract(documentPermissionLoginIds);
      return denormalize(noPermissionUserLoginIds, schema.users, entities);
    }
    return undefined;
  }
);

export const getUsersIsGetting = createSelector(getFetchGetUsers, (fetchGet) =>
  fetchGet.get("isLoading")
);

export const getDocumentPermissionsIsPosting = createSelector(
  getFetchPostDocumentPermissions,
  (fetchPost) => fetchPost.get("isLoading")
);
