import React from "react";
import PropTypes from "prop-types";

import useTheme from "@material-ui/core/styles/useTheme";
import { renderRoutes } from "react-router-config";

import Main from "@e-group/material-layout/Main";
import NavbarBrick from "@e-group/material-layout/NavbarBrick";
import Navbar from "./Navbar";

const Root = ({ location, route }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Navbar location={location} routes={route.routes} />
      <NavbarBrick />
      <Main pl={`${theme.layout.sideMenuWidth}px`}>
        {renderRoutes(route.routes)}
      </Main>
    </React.Fragment>
  );
};

Root.propTypes = {
  route: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Root;
