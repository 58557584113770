import React from "react";
import PropTypes from "prop-types";

import { mergeDeep } from "immutable";
import s3Url from "utils/s3Url";

import CKEditor from "@e-group/material-lab/CKEditor";

const edsCKEditorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "blockQuote",
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "fontColor",
      "highlight",
      "removeFormat",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "indent",
      "outdent",
      "|",
      "link",
      "imageUpload",
      "mediaEmbed",
      "|",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
      "|",
      "insertTable",
      "drawio",
    ],
  },
  drawio: {
    autoEmbedMatcher: (url) =>
      url.indexOf(process.env.DRAWIO_BUCKET_URL) !== -1,
    formatSrc: (src) => {
      if (src.indexOf("https") === -1) {
        return s3Url(src);
      }
      return src;
    },
  },
};

const EdsCKEditor = ({ config = {}, ...other }) => {
  return <CKEditor config={mergeDeep(edsCKEditorConfig, config)} {...other} />;
};

EdsCKEditor.propTypes = {
  config: PropTypes.object,
};

export default EdsCKEditor;
