import React from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import displayValues from "@e-group/utils/displayValues";
import { openDialog } from "@e-group/redux-modules/dialogs";
import {
  fetchDeleteInvoice,
  fetchPatchInvoice,
  fetchPostCopyInvoice,
  getInvoiceIsDeleting,
  getSelectedSellerIdentifier,
  getSelectedInvoice,
  getInvoiceIsCopying,
} from "./redux";
import { DIALOG as EDIT_DIALOG } from "./InvoiceEditDialog";

import ConfirmDialog from "@e-group/material-module/ConfirmDialog";
import Button from "@e-group/material/Button";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

export const DIALOG = "invoiceDialog";

const CONFIRM_DELETE_DIALOG = "confirmDeleteInvoiceDialog";

const ConfirmDeleteDialog = withReduxDialog(CONFIRM_DELETE_DIALOG)(
  ConfirmDialog
);

const format = (currency) => {
  return Number(currency.toFixed(1)).toLocaleString();
};

const InvoiceDialog = React.forwardRef(function InvoiceDialog(props, ref) {
  const { isOpen, handleClose } = props;
  const dispatch = useDispatch();
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);
  const selectedInvoice = useSelector(getSelectedInvoice);
  const invoiceIsDeleting = useSelector(getInvoiceIsDeleting);
  const invoiceIsCopying = useSelector(getInvoiceIsCopying);
  const [cancelReason, setCancelReason] = React.useState("");

  const invoiceStatus = selectedInvoice
    ? selectedInvoice.get("invoiceStatus")
    : "";
  const displayDeleteButton =
    invoiceStatus === "已開立" || invoiceStatus === "未開立";
  const displayEditButton = invoiceStatus === "未開立";

  const getButtonText = () => {
    if (!selectedInvoice) {
      return undefined;
    }
    switch (selectedInvoice.get("invoiceStatus")) {
      case "已開立":
        return "作廢發票";
      case "未開立":
        return "刪除發票";
      default:
        return "刪除發票";
    }
  };

  const handleDelete = () => {
    if (invoiceStatus === "未開立") {
      dispatch(
        fetchDeleteInvoice({
          sellerIdentifier: selectedSellerIdentifier,
          invoiceId: selectedInvoice.get("invoiceId"),
        })
      );
    } else {
      dispatch(
        fetchPatchInvoice({
          sellerIdentifier: selectedInvoice.get("sellerIdentifier"),
          invoiceId: selectedInvoice.get("invoiceId"),
          cancelReason,
          eInvoiceMessage: "C0501",
        })
      );
    }
  };

  const handleChange = (e) => {
    setCancelReason(e.target.value);
  };

  const renderContent = () => {
    if (!selectedInvoice) {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress />
        </Box>
      );
    }
    return displayValues(
      [
        {
          key: "紀錄更新日期",
          value: selectedInvoice.get("transactionUpdateDateString"),
        },
        {
          key: "發票選項",
          value: selectedInvoice.get("invoiceResultTypeString"),
        },
        {
          key: "發票號碼",
          value: selectedInvoice.get("invoiceNumber"),
        },
        {
          key: "發票期別",
          value: selectedInvoice.get("yearMonth"),
        },
        {
          value: selectedInvoice.has("invoiceDate"),
          render: (index) => {
            return (
              <Grid xs={4} item key="發票開立日期">
                <Typography variant="body1" gutterBottom component="div">
                  發票開立日期：
                  {moment(selectedInvoice.get("invoiceDate")).format(
                    "YYYY-MM-DD"
                  )}{" "}
                  {selectedInvoice.get("invoiceTime")}
                </Typography>
              </Grid>
            );
          },
        },
        {
          value: selectedInvoice.has("transactionUpdateDateString"),
          render: (index) => {
            return (
              <Grid xs={4} item key="上次編輯時間">
                <Typography variant="body1" gutterBottom component="div">
                  上次編輯時間：
                  {selectedInvoice.get("transactionUpdateDateString")}
                </Typography>
              </Grid>
            );
          },
        },
        {
          key: "賣方統編",
          value: selectedInvoice.get("sellerIdentifier"),
        },
        {
          key: "買方統編",
          value: selectedInvoice.get("buyerIdentifier"),
        },
        {
          key: "買方名稱",
          value: selectedInvoice.get("buyerName"),
        },
        {
          key: "買方地址",
          value: selectedInvoice.get("buyerAddress"),
        },
        {
          key: "發票類別",
          value: selectedInvoice.get("invoiceType"),
        },
        {
          key: "發票防偽隨機碼",
          value: selectedInvoice.get("invoiceRandomNumber"),
        },
        {
          key: "銷售額合計",
          value: format(selectedInvoice.get("invoiceSalesAmount")),
        },
        {
          key: "營業稅額",
          value: format(selectedInvoice.get("invoiceTaxAmount")),
        },
        {
          key: "總計",
          value: format(selectedInvoice.get("invoiceTotalAmount")),
        },
        {
          key: "總備註",
          value: selectedInvoice.get("invoiceMainRemark"),
        },
        {
          key: "作廢發票號碼",
          value: selectedInvoice.get("cancelInvoiceNumber"),
        },
        {
          key: "作廢日期",
          value: selectedInvoice.get("cancelDate"),
        },
        {
          key: "作廢時間",
          value: selectedInvoice.get("cancelTime"),
        },
        {
          key: "作廢原因",
          value: selectedInvoice.get("cancelReason"),
        },
        {
          key: "發票狀態",
          value: selectedInvoice.get("invoiceStatus"),
        },
        {
          key: "捐贈碼",
          value: selectedInvoice.get("invoiceNPOBAN"),
        },
        {
          key: "捐贈單位",
          value: selectedInvoice.get("invoiceNPOName"),
        },
        {
          key: "列印註記",
          value: selectedInvoice.get("invoicePrintMark"),
        },
        {
          value: selectedInvoice.has("invoiceProveFilePath"),
          render: () => {
            if (
              selectedInvoice.get("invoiceResultType") === 6 &&
              selectedInvoice.get("invoiceStatus") === "已開立"
            ) {
              return (
                <Grid xs={4} item key="證明聯下載">
                  證明聯列印(注意：只能列印一次，
                  <Link
                    href="https://invoice-bucket-release.s3-ap-northeast-1.amazonaws.com/resources/file/tutorial/print_step.pdf"
                    target="_blank"
                  >
                    列印教學
                  </Link>
                  )：
                  <Link
                    href={`${selectedInvoice.get("invoiceProveFilePath")}`}
                    target="_blank"
                  >
                    證明聯列印
                  </Link>
                </Grid>
              );
            }
          },
        },
      ],
      ({ key, value }) => (
        <Grid xs={4} item key={key}>
          <Typography variant="body1" gutterBottom component="div">
            {key}：{value}
          </Typography>
        </Grid>
      )
    );
  };

  const renderProduct = () => {
    if (!selectedInvoice) {
      return <div />;
    }
    return (
      <Box border="solid 1px gray" px={1} mt={2} width="inherit">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              商品明細
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ marginBottom: "15px" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>商品品名</TableCell>
                    <TableCell>商品數量</TableCell>
                    <TableCell>單品項價格</TableCell>
                  </TableRow>
                </TableHead>
                {selectedInvoice.get("invoiceProductList").map((el) => (
                  <TableBody key={el.get("invoiceProductId")}>
                    <TableRow>
                      <TableCell>
                        {el.get("invoiceProductDescription")}
                      </TableCell>
                      <TableCell>
                        {el.get("invoiceProductQuantity")}{" "}
                        {el.get("invoiceProductUnit")}
                      </TableCell>
                      <TableCell>{el.get("invoiceProductUnitPrice")}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderAllowance = () => {
    if (selectedInvoice) {
      if (selectedInvoice.get("allowanceList")) {
        const allowanceList = selectedInvoice.get("allowanceList");
        return (
          <Box border="solid 1px gray" px={1} mt={2} width="inherit">
            <Grid xs={12} item>
              <Typography variant="h6" gutterBottom component="div">
                折讓紀錄
              </Typography>
            </Grid>
            {allowanceList.map((el) => (
              <React.Fragment key={el.get("allowanceNumber")}>
                <Typography variant="body1" gutterBottom component="div">
                  折讓編號：{el.get("allowanceNumber")}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  折讓日期：{el.get("allowanceDate")}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  折讓總額：{el.get("invoiceTotalAmount")}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  稅額：{el.get("invoiceTaxAmount")}
                </Typography>
                <TableContainer style={{ marginBottom: "15px" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>商品品名</TableCell>
                        <TableCell>商品數量</TableCell>
                        <TableCell>單品項價格</TableCell>
                      </TableRow>
                    </TableHead>
                    {el.get("allowanceProductList").map((el) => (
                      <TableBody key={el.get("allowanceProductId")}>
                        <TableRow>
                          <TableCell>
                            {el.get("allowanceProductOriginalDescription")}
                          </TableCell>
                          <TableCell>
                            {el.get("allowanceProductQuantity")}{" "}
                            {el.get("invoiceProductUnit")}
                          </TableCell>
                          <TableCell>
                            {el.get("allowanceProductUnitPrice")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </React.Fragment>
            ))}
          </Box>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <ConfirmDeleteDialog
        title={`確認${getButtonText()}`}
        message={
          getButtonText() === "刪除發票" ? (
            "刪除發票後將無法還原"
          ) : (
            <React.Fragment>
              <Typography>
                {getButtonText()}後將無法還原，請輸入作廢原因。
              </Typography>
              <TextField
                onChange={handleChange}
                value={cancelReason}
                autoFocus
                fullWidth
              />
            </React.Fragment>
          )
        }
        onConfirm={handleDelete}
        MuiDialogContentTextProps={{
          component: "div",
        }}
      />
      <Dialog
        maxWidth="lg"
        fullWidth
        open={isOpen}
        onClose={() => handleClose()}
      >
        <DialogTitle>發票內容</DialogTitle>
        <DialogContent>
          <Grid container>
            {renderContent()}
            {selectedInvoice && selectedInvoice.get("invoiceCarrierId1") && (
              <Grid xs={12} item>
                <Typography variant="body1" gutterBottom component="div">
                  {`載具ID：${selectedInvoice.get("invoiceCarrierId1")}`}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  {`會員Email：${selectedInvoice.get("userEmail")}`}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  {`會員名稱：${selectedInvoice.get("userName")}`}
                </Typography>
              </Grid>
            )}
            {renderProduct()}
            {renderAllowance()}
          </Grid>
        </DialogContent>
        <DialogActions>
          {displayEditButton && (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                disabled: invoiceIsDeleting,
                onClick: () => {
                  dispatch(openDialog(EDIT_DIALOG));
                  handleClose();
                },
              }}
            >
              編輯發票
            </Button>
          )}
          <Button
            loading={invoiceIsCopying}
            MuiButtonProps={{
              variant: "outlined",
              color: "primary",
              onClick: () => {
                dispatch(
                  fetchPostCopyInvoice({
                    sellerIdentifier: selectedSellerIdentifier,
                    invoiceId: selectedInvoice.get("invoiceId"),
                  })
                );
              },
            }}
          >
            複製發票
          </Button>
          {/*{displayAllowanceButton && (
            <Button
              MuiButtonProps={{
                variant: "outlined",
                color: "primary",
                disabled: invoiceIsDeleting,
                onClick: () => {
                  dispatch(openDialog(ALLOWANCE_DIALOG));
                  handleClose();
                }
              }}
            >
              折讓發票
            </Button>
          )}*/}
          {displayDeleteButton && (
            <Button
              loading={invoiceIsDeleting}
              MuiButtonProps={{
                variant: "outlined",
                color: "secondary",
                onClick: () => {
                  dispatch(openDialog(CONFIRM_DELETE_DIALOG));
                },
              }}
            >
              {getButtonText()}
            </Button>
          )}
          <Button
            MuiButtonProps={{
              variant: "outlined",
              onClick: handleClose,
              disabled: invoiceIsDeleting,
            }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

InvoiceDialog.propTypes = {
  // redux container props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(InvoiceDialog);
