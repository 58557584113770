import React from "react";
import PropTypes from "prop-types";

import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import makeSearchDataList from "@e-group/hooks/makeSearchDataList";
import {
  fetchGetInvoicePeriods,
  getInvoicePeriods,
  getInvoicePeriodsTotal,
  getInvoicePeriodsIsLoading,
  getSelectedSellerIdentifier,
  setSelectedSellerIdentifier
} from "./redux";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import SearchDataList from "@e-group/material-module/SearchDataList";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import FixedCenter from "components/FixedCenter";
import CircularProgress from "@material-ui/core/CircularProgress";

const useSearchDataList = makeSearchDataList({
  fromKey: "startIndex",
  sizeKey: "size"
});

const sellerIdentifierList = [
  {
    name: "馴錢師財商顧問股份有限公司",
    id: "28473556"
  },
  {
    name: "益群健康股份有限公司",
    id: "24314136"
  }
];

const InvoicePeriods = props => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const invoicePeriods = useSelector(getInvoicePeriods);
  const invoicePeriodsTotal = useSelector(getInvoicePeriodsTotal);
  const isLoading = useSelector(getInvoicePeriodsIsLoading);
  const selectedSellerIdentifier = useSelector(getSelectedSellerIdentifier);

  const {
    handleSearchChange,
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    payload
  } = useSearchDataList({
    fetchGet: React.useCallback(
      payload => {
        dispatch(
          fetchGetInvoicePeriods({
            sellerIdentifier: selectedSellerIdentifier,
            ...payload
          })
        );
      },
      [dispatch, selectedSellerIdentifier]
    ),
    history,
    location
  });

  const handleSelectChange = e => {
    dispatch(setSelectedSellerIdentifier(e.target.value));
  };

  const renderColumns = rowData => {
    return (
      <TableRow>
        <TableCell>
          <Typography variant="body2">{rowData[0]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rowData[1]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rowData[2]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rowData[3]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rowData[4]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rowData[5]}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderDataRow = rowData => {
    return (
      <TableRow key={rowData.invoicePeriodBeginNo} hover>
        <TableCell>
          <Typography noWrap variant="body2">
            {rowData.yearMonth}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {rowData.invoicePeriodTrack}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {`${rowData.invoicePeriodBeginNo} ～ ${rowData.invoicePeriodEndNo}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {rowData.sellerIdentifier}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {rowData.invoicePeriodTypeString}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap variant="body2">
            {rowData.invoicePeriodStatusString}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  if (!invoicePeriods) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <Container>
        <Box display="flex" alignItems="center">
          <TextField
            onChange={handleSelectChange}
            value={selectedSellerIdentifier}
            label="選擇公司"
            select
            SelectProps={{
              disableUnderline: true
            }}
          >
            {sellerIdentifierList.map(el => (
              <ListItem key={el.id} value={el.id}>
                {el.name}
              </ListItem>
            ))}
          </TextField>
        </Box>
        <Paper>
          <SearchDataList
            style={{ minWidth: 800 }}
            variant="table"
            size="small"
            onSubmit={handleSearchSubmit}
            title="字軌管理列表"
            SearchBarProps={{
              placeholder: "以期別、字軌搜尋",
              onChange: handleSearchChange,
              defaultValue: payload.query
            }}
            data={invoicePeriods.toJS()}
            columns={[
              "期別",
              "字軌",
              "發票號碼",
              "賣方統編",
              "字軌類別",
              "狀態"
            ]}
            serverSide
            loading={isLoading}
            isEmpty={invoicePeriods.size === 0}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            MuiTablePaginationProps={{
              count: invoicePeriodsTotal,
              labelRowsPerPage: "每頁幾筆",
              page: Math.ceil(payload.startIndex / payload.size),
              rowsPerPage: parseInt(payload.size),
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Container>
    </>
  );
};

InvoicePeriods.propTypes = {
  // react-router-dom props
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default InvoicePeriods;
