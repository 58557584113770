import actionCreators from "redux/actionCreators";
import { createSelector } from "reselect";
import { Map, isImmutable } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";

/** Actions */
export const {
  fetchGetInvoices,
  fetchGetInvoicesRequest,
  fetchGetInvoicesSuccess,
  fetchGetInvoicesFailure,

  fetchGetInvoice,
  fetchGetInvoiceRequest,
  fetchGetInvoiceSuccess,
  fetchGetInvoiceFailure,

  fetchPostInvoice,
  fetchPostInvoiceRequest,
  fetchPostInvoiceSuccess,
  fetchPostInvoiceFailure,

  fetchPatchInvoice,
  fetchPatchInvoiceRequest,
  fetchPatchInvoiceSuccess,
  fetchPatchInvoiceFailure,

  fetchDeleteInvoice,
  fetchDeleteInvoiceRequest,
  fetchDeleteInvoiceSuccess,
  fetchDeleteInvoiceFailure,

  fetchPostAllowance,
  fetchPostAllowanceRequest,
  fetchPostAllowanceSuccess,
  fetchPostAllowanceFailure,

  fetchPostCopyInvoice,
  fetchPostCopyInvoiceRequest,
  fetchPostCopyInvoiceSuccess,
  fetchPostCopyInvoiceFailure,

  setSelectedSellerIdentifier,
  setSelectedInvoice,
  setActiveStep,
  setConfirmAllowance,
  handleNext,
  handlePrev,
} = actionCreators.pages.b2bInvoices;

/** Types */
export const FETCH_GET_INVOICES = fetchGetInvoices().type;
export const FETCH_GET_INVOICES_REQUEST = fetchGetInvoicesRequest().type;
export const FETCH_GET_INVOICES_SUCCESS = fetchGetInvoicesSuccess().type;
export const FETCH_GET_INVOICES_FAILURE = fetchGetInvoicesFailure().type;

export const FETCH_GET_INVOICE = fetchGetInvoice().type;
export const FETCH_GET_INVOICE_REQUEST = fetchGetInvoiceRequest().type;
export const FETCH_GET_INVOICE_SUCCESS = fetchGetInvoiceSuccess().type;
export const FETCH_GET_INVOICE_FAILURE = fetchGetInvoiceFailure().type;

export const FETCH_POST_INVOICE = fetchPostInvoice().type;
export const FETCH_POST_INVOICE_REQUEST = fetchPostInvoiceRequest().type;
export const FETCH_POST_INVOICE_SUCCESS = fetchPostInvoiceSuccess().type;
export const FETCH_POST_INVOICE_FAILURE = fetchPostInvoiceFailure().type;

export const FETCH_PATCH_INVOICE = fetchPatchInvoice().type;
export const FETCH_PATCH_INVOICE_REQUEST = fetchPatchInvoiceRequest().type;
export const FETCH_PATCH_INVOICE_SUCCESS = fetchPatchInvoiceSuccess().type;
export const FETCH_PATCH_INVOICE_FAILURE = fetchPatchInvoiceFailure().type;

export const FETCH_DELETE_INVOICE = fetchDeleteInvoice().type;
export const FETCH_DELETE_INVOICE_REQUEST = fetchDeleteInvoiceRequest().type;
export const FETCH_DELETE_INVOICE_SUCCESS = fetchDeleteInvoiceSuccess().type;
export const FETCH_DELETE_INVOICE_FAILURE = fetchDeleteInvoiceFailure().type;

export const FETCH_POST_ALLOWANCE = fetchPostAllowance().type;
export const FETCH_POST_ALLOWANCE_REQUEST = fetchPostAllowanceRequest().type;
export const FETCH_POST_ALLOWANCE_SUCCESS = fetchPostAllowanceSuccess().type;
export const FETCH_POST_ALLOWANCE_FAILURE = fetchPostAllowanceFailure().type;

export const FETCH_POST_COPY_INVOICE = fetchPostCopyInvoice().type;
export const FETCH_POST_COPY_INVOICE_REQUEST = fetchPostCopyInvoiceRequest()
  .type;
export const FETCH_POST_COPY_INVOICE_SUCCESS = fetchPostCopyInvoiceSuccess()
  .type;
export const FETCH_POST_COPY_INVOICE_FAILURE = fetchPostCopyInvoiceFailure()
  .type;

export const SET_SELECTED_SELLER_IDENTIFIER = setSelectedSellerIdentifier()
  .type;
export const SET_SELECTED_INVOICE = setSelectedInvoice().type;
export const SET_ACTIVE_STEP = setActiveStep().type;
export const SET_CONFIRM_ALLOWANCE = setConfirmAllowance().type;
export const HANDLE_NEXT = handleNext().type;
export const HANDLE_PREV = handlePrev().type;

/** Selectors */
const getUiStates = (state) => state.getIn(["pages", "B2BInvoices"]);

export const getSelectedSellerIdentifier = createSelector(
  getUiStates,
  (uiStates) => uiStates.get("selectedSellerIdentifier")
);

export const getSelectedInvoice = createSelector(getUiStates, (uiStates) =>
  uiStates.get("selectedInvoice")
);

export const getActiveStep = createSelector(getUiStates, (uiStates) =>
  uiStates.get("activeStep")
);

export const getActiveStepMax = createSelector(getUiStates, (uiStates) =>
  uiStates.get("activeStepMax")
);

export const getConfirmAllowance = createSelector(getUiStates, (uiStates) =>
  uiStates.get("confirmAllowance")
);

const getAllowanceProductConcatList = createSelector(
  getSelectedInvoice,
  (selectedInvoice) => {
    if (
      !isImmutable(selectedInvoice) ||
      !selectedInvoice.has("allowanceList")
    ) {
      return undefined;
    }
    return selectedInvoice
      .get("allowanceList")
      .map((allowance) => allowance.get("allowanceProductList"))
      .flatten(true);
  }
);

const getAllowanceProductMap = createSelector(
  getSelectedInvoice,
  getAllowanceProductConcatList,
  (selectedInvoice, allowanceProductConcatList) => {
    if (
      !isImmutable(selectedInvoice) ||
      !isImmutable(allowanceProductConcatList)
    ) {
      return undefined;
    }
    return allowanceProductConcatList.reduce((a, c) => {
      if (!c.has("invoiceProductId")) {
        return a;
      }
      const invoiceProductId = c.get("invoiceProductId");
      if (!a.has(invoiceProductId)) {
        return a.set(invoiceProductId, c);
      }
      return a.update(invoiceProductId, (allowanceProduct) =>
        allowanceProduct.update(
          "allowanceProductQuantity",
          (allowanceProductQuantity) =>
            allowanceProductQuantity + c.get("allowanceProductQuantity")
        )
      );
    }, Map());
  }
);

export const getAllowanceInitialValues = createSelector(
  getSelectedInvoice,
  getAllowanceProductMap,
  (selectedInvoice, allowanceProductMap) => {
    if (!isImmutable(selectedInvoice) || !isImmutable(allowanceProductMap)) {
      return undefined;
    }

    return selectedInvoice.update(
      "invoiceProductList",
      (invoiceProductList) => {
        return invoiceProductList
          .map((invoiceProduct) => {
            const invoiceProductQuantity = invoiceProduct.get(
              "invoiceProductQuantity"
            );
            const allowanceProductQuantity = allowanceProductMap.getIn(
              [
                invoiceProduct.get("invoiceProductId"),
                "allowanceProductQuantity",
              ],
              0
            );
            if (invoiceProductQuantity - allowanceProductQuantity > 0) {
              return invoiceProduct.update(
                "invoiceProductQuantity",
                (invoiceProductQuantity) =>
                  invoiceProductQuantity - allowanceProductQuantity
              );
            }
            return undefined;
          })
          .filter(Boolean);
      }
    );
  }
);

const getApiStates = (state) =>
  state.getIn(["apis", "pages", "b2bInvoices"], Map());

const getFetchGetInvoices = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetInvoices", Map())
);

const getFetchGetInvoice = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchGetInvoice", Map())
);

const getFetchPostInvoice = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPostInvoice", Map())
);

const getFetchPatchInvoice = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPatchInvoice", Map())
);

const getFetchDeleteInvoice = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchDeleteInvoice", Map())
);

const getFetchPostAllowance = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPostAllowance", Map())
);

const getFetchPostCopyInvoice = createSelector(getApiStates, (apiStates) =>
  apiStates.get("fetchPostCopyInvoice", Map())
);

export const getInvoices = createSelector(
  getFetchGetInvoices,
  getEntities,
  (fetchGet, entities) =>
    denormalize(
      fetchGet.getIn(["response", "result"]),
      schema.invoices,
      entities
    )
);

export const getInvoicesTotal = createSelector(
  getFetchGetInvoices,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getInvoicesIsLoading = createSelector(
  getFetchGetInvoices,
  (fetchGet) => fetchGet.get("isLoading")
);

export const getInvoice = createSelector(
  getFetchGetInvoice,
  getEntities,
  (fetchGet, entities) =>
    denormalize(fetchGet.get("response"), schema.invoice, entities)
);

export const getInvoiceIsPosting = createSelector(
  getFetchPostInvoice,
  (fetchPost) => fetchPost.get("isLoading")
);

export const getInvoiceIsPatching = createSelector(
  getFetchPatchInvoice,
  (fetchPatch) => fetchPatch.get("isLoading")
);

export const getInvoiceIsDeleting = createSelector(
  getFetchDeleteInvoice,
  (fetchDelete) => fetchDelete.get("isLoading")
);

export const getAllowanceIsPosting = createSelector(
  getFetchPostAllowance,
  (fetchPost) => fetchPost.get("isLoading")
);

export const getInvoiceIsCopying = createSelector(
  getFetchPostCopyInvoice,
  (fetchPost) => fetchPost.get("isLoading")
);
