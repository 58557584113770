import { createActions } from "redux-actions";

const actionMap = {
  COMPONENTS: {
    MUI_THEME_PROVIDER: {
      HANDLE_CHANGE_THEME_OPTIONS: undefined,
    },
    ROUTER_ROOT: {
      FETCH_GET_OPTIONS: undefined,
      FETCH_GET_OPTIONS_REQUEST: undefined,
      FETCH_GET_OPTIONS_SUCCESS: undefined,
      FETCH_GET_OPTIONS_FAILURE: undefined,
    },
    USER_AVATAR_MENU: {
      FETCH_POST_LOGOUT: undefined,
      FETCH_POST_LOGOUT_REQUEST: undefined,
      FETCH_POST_LOGOUT_SUCCESS: undefined,
      FETCH_POST_LOGOUT_FAILURE: undefined,
    },
  },
  PAGES: {
    DOCUMENT: {
      FETCH_GET_DOCUMENT: undefined,
      FETCH_GET_DOCUMENT_REQUEST: undefined,
      FETCH_GET_DOCUMENT_SUCCESS: undefined,
      FETCH_GET_DOCUMENT_FAILURE: undefined,
      CLEAR_DOCUMENT: undefined,

      FETCH_PATCH_DOCUMENT: undefined,
      FETCH_PATCH_DOCUMENT_REQUEST: undefined,
      FETCH_PATCH_DOCUMENT_SUCCESS: undefined,
      FETCH_PATCH_DOCUMENT_FAILURE: undefined,

      FETCH_GET_DOCUMENT_VERSIONS: undefined,
      FETCH_GET_DOCUMENT_VERSIONS_REQUEST: undefined,
      FETCH_GET_DOCUMENT_VERSIONS_SUCCESS: undefined,
      FETCH_GET_DOCUMENT_VERSIONS_FAILURE: undefined,
      CLEAR_DOCUMENT_VERSIONS: undefined,

      FETCH_POST_DOCUMENT_VERSION: undefined,
      FETCH_POST_DOCUMENT_VERSION_REQUEST: undefined,
      FETCH_POST_DOCUMENT_VERSION_SUCCESS: undefined,
      FETCH_POST_DOCUMENT_VERSION_FAILURE: undefined,

      FETCH_PATCH_DOCUMENT_VERSION: undefined,
      FETCH_PATCH_DOCUMENT_VERSION_REQUEST: undefined,
      FETCH_PATCH_DOCUMENT_VERSION_SUCCESS: undefined,
      FETCH_PATCH_DOCUMENT_VERSION_FAILURE: undefined,

      FETCH_GET_USERS: undefined,
      FETCH_GET_USERS_REQUEST: undefined,
      FETCH_GET_USERS_SUCCESS: undefined,
      FETCH_GET_USERS_FAILURE: undefined,

      FETCH_GET_DOCUMENT_PERMISSIONS: undefined,
      FETCH_GET_DOCUMENT_PERMISSIONS_REQUEST: undefined,
      FETCH_GET_DOCUMENT_PERMISSIONS_SUCCESS: undefined,
      FETCH_GET_DOCUMENT_PERMISSIONS_FAILURE: undefined,

      FETCH_POST_DOCUMENT_PERMISSIONS: undefined,
      FETCH_POST_DOCUMENT_PERMISSIONS_REQUEST: undefined,
      FETCH_POST_DOCUMENT_PERMISSIONS_SUCCESS: undefined,
      FETCH_POST_DOCUMENT_PERMISSIONS_FAILURE: undefined,

      FETCH_DELETE_DOCUMENT_PERMISSION: undefined,
      FETCH_DELETE_DOCUMENT_PERMISSION_REQUEST: undefined,
      FETCH_DELETE_DOCUMENT_PERMISSION_SUCCESS: undefined,
      FETCH_DELETE_DOCUMENT_PERMISSION_FAILURE: undefined,

      FETCH_POST_BACKUP_DOCUMENT_VERSION: undefined,
      FETCH_POST_BACKUP_DOCUMENT_VERSION_REQUEST: undefined,
      FETCH_POST_BACKUP_DOCUMENT_VERSION_SUCCESS: undefined,
      FETCH_POST_BACKUP_DOCUMENT_VERSION_FAILURE: undefined,

      SET_INVITED_USERS: undefined,
      CLEAR_INVITED_USERS: undefined,
      TOGGLE_DRAWER: undefined,
    },
    DOCUMENTS: {
      FETCH_GET_DOCUMENTS: undefined,
      FETCH_GET_DOCUMENTS_REQUEST: undefined,
      FETCH_GET_DOCUMENTS_SUCCESS: undefined,
      FETCH_GET_DOCUMENTS_FAILURE: undefined,

      FETCH_POST_DOCUMENT: undefined,
      FETCH_POST_DOCUMENT_REQUEST: undefined,
      FETCH_POST_DOCUMENT_SUCCESS: undefined,
      FETCH_POST_DOCUMENT_FAILURE: undefined,
    },
    DOCUMENT_VERSION: {
      FETCH_GET_DOCUMENT_VERSION: undefined,
      FETCH_GET_DOCUMENT_VERSION_REQUEST: undefined,
      FETCH_GET_DOCUMENT_VERSION_SUCCESS: undefined,
      FETCH_GET_DOCUMENT_VERSION_FAILURE: undefined,
      CLEAR_DOCUMENT_VERSION: undefined,

      FETCH_PATCH_DOCUMENT: undefined,
      FETCH_PATCH_DOCUMENT_REQUEST: undefined,
      FETCH_PATCH_DOCUMENT_SUCCESS: undefined,
      FETCH_PATCH_DOCUMENT_FAILURE: undefined,
    },
    ORGANIZATIONS: {
      FETCH_GET_ORGANIZATIONS: undefined,
      FETCH_GET_ORGANIZATIONS_REQUEST: undefined,
      FETCH_GET_ORGANIZATIONS_SUCCESS: undefined,
      FETCH_GET_ORGANIZATIONS_FAILURE: undefined,

      FETCH_POST_ORGANIZATION: undefined,
      FETCH_POST_ORGANIZATION_REQUEST: undefined,
      FETCH_POST_ORGANIZATION_SUCCESS: undefined,
      FETCH_POST_ORGANIZATION_FAILURE: undefined,

      FETCH_DELETE_ORGANIZATION: undefined,
      FETCH_DELETE_ORGANIZATION_REQUEST: undefined,
      FETCH_DELETE_ORGANIZATION_SUCCESS: undefined,
      FETCH_DELETE_ORGANIZATION_FAILURE: undefined,
    },
    FEEDBACKS: {
      FETCH_GET_FEEDBACKS: undefined,
      FETCH_GET_FEEDBACKS_REQUEST: undefined,
      FETCH_GET_FEEDBACKS_SUCCESS: undefined,
      FETCH_GET_FEEDBACKS_FAILURE: undefined,

      FETCH_GET_FEEDBACK: undefined,
      FETCH_GET_FEEDBACK_REQUEST: undefined,
      FETCH_GET_FEEDBACK_SUCCESS: undefined,
      FETCH_GET_FEEDBACK_FAILURE: undefined,
    },
    ORGANIZATION: {
      FETCH_GET_ORGANIZATION: undefined,
      FETCH_GET_ORGANIZATION_REQUEST: undefined,
      FETCH_GET_ORGANIZATION_SUCCESS: undefined,
      FETCH_GET_ORGANIZATION_FAILURE: undefined,

      FETCH_PATCH_ORGANIZATION: undefined,
      FETCH_PATCH_ORGANIZATION_REQUEST: undefined,
      FETCH_PATCH_ORGANIZATION_SUCCESS: undefined,
      FETCH_PATCH_ORGANIZATION_FAILURE: undefined,
    },
    B2B_INVOICES: {
      FETCH_GET_INVOICES: undefined,
      FETCH_GET_INVOICES_REQUEST: undefined,
      FETCH_GET_INVOICES_SUCCESS: undefined,
      FETCH_GET_INVOICES_FAILURE: undefined,

      FETCH_GET_INVOICE: undefined,
      FETCH_GET_INVOICE_REQUEST: undefined,
      FETCH_GET_INVOICE_SUCCESS: undefined,
      FETCH_GET_INVOICE_FAILURE: undefined,

      FETCH_POST_INVOICE: undefined,
      FETCH_POST_INVOICE_REQUEST: undefined,
      FETCH_POST_INVOICE_SUCCESS: undefined,
      FETCH_POST_INVOICE_FAILURE: undefined,

      FETCH_PATCH_INVOICE: undefined,
      FETCH_PATCH_INVOICE_REQUEST: undefined,
      FETCH_PATCH_INVOICE_SUCCESS: undefined,
      FETCH_PATCH_INVOICE_FAILURE: undefined,

      FETCH_DELETE_INVOICE: undefined,
      FETCH_DELETE_INVOICE_REQUEST: undefined,
      FETCH_DELETE_INVOICE_SUCCESS: undefined,
      FETCH_DELETE_INVOICE_FAILURE: undefined,

      FETCH_POST_ALLOWANCE: undefined,
      FETCH_POST_ALLOWANCE_REQUEST: undefined,
      FETCH_POST_ALLOWANCE_SUCCESS: undefined,
      FETCH_POST_ALLOWANCE_FAILURE: undefined,

      FETCH_POST_COPY_INVOICE: undefined,
      FETCH_POST_COPY_INVOICE_REQUEST: undefined,
      FETCH_POST_COPY_INVOICE_SUCCESS: undefined,
      FETCH_POST_COPY_INVOICE_FAILURE: undefined,

      SET_SELECTED_SELLER_IDENTIFIER: undefined,
      SET_SELECTED_INVOICE: undefined,
      SET_ACTIVE_STEP: undefined,
      SET_CONFIRM_ALLOWANCE: undefined,
      HANDLE_NEXT: undefined,
      HANDLE_PREV: undefined,
    },
    B2C_INVOICES: {
      FETCH_GET_INVOICES: undefined,
      FETCH_GET_INVOICES_REQUEST: undefined,
      FETCH_GET_INVOICES_SUCCESS: undefined,
      FETCH_GET_INVOICES_FAILURE: undefined,

      FETCH_GET_INVOICE: undefined,
      FETCH_GET_INVOICE_REQUEST: undefined,
      FETCH_GET_INVOICE_SUCCESS: undefined,
      FETCH_GET_INVOICE_FAILURE: undefined,

      FETCH_POST_INVOICE: undefined,
      FETCH_POST_INVOICE_REQUEST: undefined,
      FETCH_POST_INVOICE_SUCCESS: undefined,
      FETCH_POST_INVOICE_FAILURE: undefined,

      FETCH_PATCH_INVOICE: undefined,
      FETCH_PATCH_INVOICE_REQUEST: undefined,
      FETCH_PATCH_INVOICE_SUCCESS: undefined,
      FETCH_PATCH_INVOICE_FAILURE: undefined,

      FETCH_DELETE_INVOICE: undefined,
      FETCH_DELETE_INVOICE_REQUEST: undefined,
      FETCH_DELETE_INVOICE_SUCCESS: undefined,
      FETCH_DELETE_INVOICE_FAILURE: undefined,

      FETCH_POST_ALLOWANCE: undefined,
      FETCH_POST_ALLOWANCE_REQUEST: undefined,
      FETCH_POST_ALLOWANCE_SUCCESS: undefined,
      FETCH_POST_ALLOWANCE_FAILURE: undefined,

      FETCH_GET_NPOS: undefined,
      FETCH_GET_NPOS_REQUEST: undefined,
      FETCH_GET_NPOS_SUCCESS: undefined,
      FETCH_GET_NPOS_FAILURE: undefined,

      FETCH_GET_MEMBERS: undefined,
      FETCH_GET_MEMBERS_REQUEST: undefined,
      FETCH_GET_MEMBERS_SUCCESS: undefined,
      FETCH_GET_MEMBERS_FAILURE: undefined,

      FETCH_POST_COPY_INVOICE: undefined,
      FETCH_POST_COPY_INVOICE_REQUEST: undefined,
      FETCH_POST_COPY_INVOICE_SUCCESS: undefined,
      FETCH_POST_COPY_INVOICE_FAILURE: undefined,

      SET_SELECTED_SELLER_IDENTIFIER: undefined,
      SET_SELECTED_INVOICE: undefined,
      SET_ACTIVE_STEP: undefined,
      HANDLE_NEXT: undefined,
      HANDLE_PREV: undefined,
    },
    INVOICE_PERIODS: {
      FETCH_GET_INVOICE_PERIODS: undefined,
      FETCH_GET_INVOICE_PERIODS_REQUEST: undefined,
      FETCH_GET_INVOICE_PERIODS_SUCCESS: undefined,
      FETCH_GET_INVOICE_PERIODS_FAILURE: undefined,

      SET_SELECTED_SELLER_IDENTIFIER: undefined,
    },
  },
  LANDING_PAGES: {
    LOGIN: {
      FETCH_GET_FACEBOOK_LOGIN_URL: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_REQUEST: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_SUCCESS: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_FAILURE: undefined,

      FETCH_POST_FACEBOOK_LOGIN: undefined,
      FETCH_POST_FACEBOOK_LOGIN_REQUEST: undefined,
      FETCH_POST_FACEBOOK_LOGIN_SUCCESS: undefined,
      FETCH_POST_FACEBOOK_LOGIN_FAILURE: undefined,

      FETCH_GET_GOOGLE_LOGIN_URL: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_REQUEST: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_SUCCESS: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_FAILURE: undefined,

      FETCH_POST_GOOGLE_LOGIN: undefined,
      FETCH_POST_GOOGLE_LOGIN_REQUEST: undefined,
      FETCH_POST_GOOGLE_LOGIN_SUCCESS: undefined,
      FETCH_POST_GOOGLE_LOGIN_FAILURE: undefined,
    },
    REGISTER: {
      FETCH_POST_REGISTER: undefined,
      FETCH_POST_REGISTER_REQUEST: undefined,
      FETCH_POST_REGISTER_SUCCESS: undefined,
      FETCH_POST_REGISTER_FAILURE: undefined,
    },
  },
};

export default createActions(actionMap);
