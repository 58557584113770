import PropTypes from "prop-types";

import { renderRoutes } from "react-router-config";

const DocumentsRoot = ({ route }) => {
  return renderRoutes(route.routes);
};

DocumentsRoot.propTypes = {
  // react-router-dom props
  route: PropTypes.object.isRequired
};

export default DocumentsRoot;
