import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetDocumentRequest,
  fetchGetDocumentSuccess,
  fetchGetDocumentFailure,
  fetchPatchDocumentRequest,
  fetchPatchDocumentSuccess,
  fetchPatchDocumentFailure,
  fetchGetDocumentVersions,
  fetchGetDocumentVersionsRequest,
  fetchGetDocumentVersionsSuccess,
  fetchGetDocumentVersionsFailure,
  fetchPostDocumentVersion,
  fetchPostDocumentVersionRequest,
  fetchPostDocumentVersionSuccess,
  fetchPostDocumentVersionFailure,
  fetchPatchDocumentVersionRequest,
  fetchPatchDocumentVersionSuccess,
  fetchPatchDocumentVersionFailure,
  fetchGetDocumentPermissions,
  fetchGetDocumentPermissionsRequest,
  fetchGetDocumentPermissionsSuccess,
  fetchGetDocumentPermissionsFailure,
  fetchGetUsersRequest,
  fetchGetUsersSuccess,
  fetchGetUsersFailure,
  fetchPostDocumentPermissionsRequest,
  fetchPostDocumentPermissionsSuccess,
  fetchPostDocumentPermissionsFailure,
  fetchDeleteDocumentPermissionRequest,
  fetchDeleteDocumentPermissionSuccess,
  fetchDeleteDocumentPermissionFailure,
  fetchPostBackupDocumentVersionRequest,
  fetchPostBackupDocumentVersionSuccess,
  fetchPostBackupDocumentVersionFailure,
  clearInvitedUsers,
  FETCH_GET_DOCUMENT,
  FETCH_PATCH_DOCUMENT,
  FETCH_GET_DOCUMENT_VERSIONS,
  FETCH_POST_DOCUMENT_VERSION,
  FETCH_PATCH_DOCUMENT_VERSION,
  FETCH_GET_DOCUMENT_PERMISSIONS,
  FETCH_GET_USERS,
  FETCH_POST_DOCUMENT_PERMISSIONS,
  FETCH_DELETE_DOCUMENT_PERMISSION,
  FETCH_POST_BACKUP_DOCUMENT_VERSION,
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetDocumentEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_DOCUMENT,
  apiName: "eds.fetchGetDocument",
  fetchRequest: fetchGetDocumentRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.document
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetDocumentSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetDocumentFailure(error))
    ),
});

export const fetchPatchDocumentEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_DOCUMENT,
  apiName: "eds.fetchPatchDocument",
  debounceTime: 500,
  fetchRequest: fetchPatchDocumentRequest,
  handleSuccess: (response, { schema }) => {
    const data = response.data || {};
    // We need delete documentContentEditor to prevent update because it will cause
    // React flush update error.
    delete data.documentContentEditor;
    const { result, entities } = normalize(data, schema.document);
    return [
      setEntities(fromJS(entities)),
      fetchPatchDocumentSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchDocumentFailure(error))
    ),
});

export const fetchGetDocumentVersionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_DOCUMENT_VERSIONS,
  apiName: "eds.fetchGetDocumentVersions",
  fetchRequest: fetchGetDocumentVersionsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.documentVersions
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetDocumentVersionsSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetDocumentVersionsFailure(error))
    ),
});

export const fetchPatchDocumentVersionEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_DOCUMENT_VERSION,
  apiName: "eds.fetchPatchDocumentVersion",
  fetchRequest: fetchPatchDocumentVersionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.documentVersion
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchDocumentVersionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchDocumentVersionFailure(error))
    ),
});

export const fetchGetUsersEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USERS,
  apiName: "eds.fetchGetUsers",
  debounceTime: 300,
  fetchRequest: fetchGetUsersRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.users
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetUsersSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUsersFailure(error))
    ),
});

export const fetchGetDocumentPermissionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_DOCUMENT_PERMISSIONS,
  apiName: "eds.fetchGetDocumentPermissions",
  fetchRequest: fetchGetDocumentPermissionsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.documentPermissions
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetDocumentPermissionsSuccess(fromJS(result).reverse()),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetDocumentPermissionsFailure(error))
    ),
});

export const fetchPostDocumentPermissionsEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_DOCUMENT_PERMISSIONS,
  apiName: "eds.fetchPostDocumentPermissions",
  fetchRequest: fetchPostDocumentPermissionsRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || [],
      schema.documentPermissions
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostDocumentPermissionsSuccess(fromJS(result)),
      fetchGetDocumentPermissions({
        isListOnly: 1,
        documentId: action.payload.documentId,
      }),
      clearInvitedUsers(),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostDocumentPermissionsFailure(error))
    ),
});

export const fetchDeleteDocumentPermissionEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_DOCUMENT_PERMISSION,
  apiName: "eds.fetchDeleteDocumentPermission",
  fetchRequest: fetchDeleteDocumentPermissionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.documentPermission
    );
    return [
      setEntities(fromJS(entities)),
      fetchDeleteDocumentPermissionSuccess(fromJS(result)),
      fetchGetDocumentPermissions({
        isListOnly: 1,
        documentId: response.data.document.documentId,
      }),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteDocumentPermissionFailure(error))
    ),
});

export const fetchPostDocumentVersionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_DOCUMENT_VERSION,
  apiName: "eds.fetchPostDocumentVersion",
  fetchRequest: fetchPostDocumentVersionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.documentVersion
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostDocumentVersionSuccess(fromJS(result)),
      fetchPostBackupDocumentVersionSuccess(),
      fetchGetDocumentVersions({
        documentId: response.data.document.documentId,
      }),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostDocumentVersionFailure(error)),
      of(fetchPostBackupDocumentVersionFailure(error))
    ),
});

export const fetchPostBackupDocumentVersionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_BACKUP_DOCUMENT_VERSION,
  apiName: "eds.fetchGetDocument",
  fetchRequest: fetchPostBackupDocumentVersionRequest,
  handleSuccess: (response, { schema }) => {
    const { documentId, documentTitle, documentContentEditor } = response.data;
    return [
      fetchPostDocumentVersion({
        documentId: documentId,
        documentVersionTitle: documentTitle,
        documentVersionContentEditor: documentContentEditor,
      }),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostBackupDocumentVersionFailure(error))
    ),
});

export default combineEpics(
  fetchGetDocumentEpic,
  fetchPatchDocumentEpic,
  fetchGetDocumentVersionsEpic,
  fetchPatchDocumentVersionEpic,
  fetchGetUsersEpic,
  fetchGetDocumentPermissionsEpic,
  fetchPostDocumentPermissionsEpic,
  fetchDeleteDocumentPermissionEpic,
  fetchPostDocumentVersionEpic,
  fetchPostBackupDocumentVersionEpic
);
