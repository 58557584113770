import actionCreators from "redux/actionCreators";
import { Map } from "immutable";
import { createSelector } from "reselect";

/** Actions */
export const {
  fetchGetOptions,
  fetchGetOptionsRequest,
  fetchGetOptionsSuccess,
  fetchGetOptionsFailure
} = actionCreators.components.routerRoot;

/** Types */
export const FETCH_GET_OPTIONS = fetchGetOptions().type;
export const FETCH_GET_OPTIONS_REQUEST = fetchGetOptionsRequest().type;
export const FETCH_GET_OPTIONS_SUCCESS = fetchGetOptionsSuccess().type;
export const FETCH_GET_OPTIONS_FAILURE = fetchGetOptionsFailure().type;

/** Selectors */
const getApiStates = state =>
  state.getIn(["apis", "components", "routerRoot"], Map());

const getFetchGetOptions = createSelector(getApiStates, rootStates =>
  rootStates.get("fetchGetOptions", Map())
);

export const getOptions = createSelector(getFetchGetOptions, fetchGet =>
  fetchGet.get("response")
);
