import React from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import withReduxDialog from "@e-group/redux-modules/immutable/withReduxDialog";
import { fetchPostDocument, getDocumentIsPosting } from "./redux";

import Button from "@e-group/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DocumentAddForm, { form } from "./DocumentAddForm";

export const DIALOG = "documentAddDialog";

const DocumentAddDialog = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getDocumentIsPosting);
  const handleSubmit = (values) => {
    dispatch(fetchPostDocument(values.toJS()));
  };
  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={() => handleClose()}>
      <DialogTitle>新增文件</DialogTitle>
      <DialogContent>
        <DialogContentText>替你的文件取個名稱吧！</DialogContentText>
        <DocumentAddForm onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions>
        <Button
          MuiButtonProps={{
            onClick: () => handleClose(),
            color: "primary",
          }}
        >
          取消
        </Button>
        <Button
          loading={isPosting}
          MuiButtonProps={{
            type: "submit",
            variant: "contained",
            color: "primary",
            form,
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DocumentAddDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

DocumentAddDialog.defaultProps = {
  isOpen: false,
};

export default withReduxDialog(DIALOG)(DocumentAddDialog);
