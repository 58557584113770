import { of, concat } from "rxjs";
import { combineEpics } from "redux-observable";
import { fromJS } from "immutable";
import { normalize } from "normalizr";

import { setEntities } from "@e-group/redux-modules/immutable/entities";
import {
  fetchGetOrganizationRequest,
  fetchGetOrganizationSuccess,
  fetchGetOrganizationFailure,
  fetchPatchOrganizationRequest,
  fetchPatchOrganizationSuccess,
  fetchPatchOrganizationFailure,
  FETCH_GET_ORGANIZATION,
  FETCH_PATCH_ORGANIZATION,
} from "./redux";

import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";

export const fetchGetOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ORGANIZATION,
  apiName: "crm.fetchGetOrganization",
  fetchRequest: fetchGetOrganizationRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organization
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetOrganizationSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOrganizationFailure(error))
    ),
});

export const fetchPatchOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ORGANIZATION,
  apiName: "crm.fetchPatchOrganization",
  fetchRequest: fetchPatchOrganizationRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organization
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchOrganizationSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchOrganizationFailure(error))
    ),
});

export default combineEpics(
  fetchGetOrganizationEpic,
  fetchPatchOrganizationEpic
);
