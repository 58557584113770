import { createSelector } from "reselect";
import { Map } from "immutable";
import { denormalize } from "normalizr";
import * as schema from "redux/schema";
import { getEntities } from "@e-group/redux-modules/immutable/entities";
import actionCreators from "redux/actionCreators";

/** Actions */
export const {
  fetchGetOrganizations,
  fetchGetOrganizationsRequest,
  fetchGetOrganizationsSuccess,
  fetchGetOrganizationsFailure,

  fetchPostOrganization,
  fetchPostOrganizationRequest,
  fetchPostOrganizationSuccess,
  fetchPostOrganizationFailure,

  fetchDeleteOrganization,
  fetchDeleteOrganizationRequest,
  fetchDeleteOrganizationSuccess,
  fetchDeleteOrganizationFailure,
} = actionCreators.pages.organizations;

/** Types */
export const FETCH_GET_ORGANIZATIONS = fetchGetOrganizations().type;
export const FETCH_GET_ORGANIZATIONS_REQUEST = fetchGetOrganizationsRequest()
  .type;
export const FETCH_GET_ORGANIZATIONS_SUCCESS = fetchGetOrganizationsSuccess()
  .type;
export const FETCH_GET_ORGANIZATIONS_FAILURE = fetchGetOrganizationsFailure()
  .type;

export const FETCH_POST_ORGANIZATION = fetchPostOrganization().type;
export const FETCH_POST_ORGANIZATION_REQUEST = fetchPostOrganizationRequest()
  .type;
export const FETCH_POST_ORGANIZATION_SUCCESS = fetchPostOrganizationSuccess()
  .type;
export const FETCH_POST_ORGANIZATION_FAILURE = fetchPostOrganizationFailure()
  .type;

export const FETCH_DELETE_ORGANIZATION = fetchDeleteOrganization().type;
export const FETCH_DELETE_ORGANIZATION_REQUEST = fetchDeleteOrganizationRequest()
  .type;
export const FETCH_DELETE_ORGANIZATION_REQUEST_ORGANIZATION_SUCCESS = fetchDeleteOrganizationSuccess()
  .type;
export const FETCH_DELETE_ORGANIZATION_FAILURE = fetchDeleteOrganizationFailure()
  .type;

/**
 * Selectors
 */
const getApiStates = (state) =>
  state.getIn(["apis", "pages", "organizations"], Map());

export const getFetchGetOrganizations = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchGetOrganizations", Map())
);

export const getFetchPostOrganization = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchPostOrganization", Map())
);

export const getFetchDeleteOrganization = createSelector(
  getApiStates,
  (apiStates) => apiStates.get("fetchDeleteOrganization", Map())
);

export const getOrganizations = createSelector(
  getFetchGetOrganizations,
  getEntities,
  (fetchGet, entities) =>
    denormalize(
      fetchGet.getIn(["response", "result"]),
      schema.organizations,
      entities
    )
);

export const getOrganizationsTotal = createSelector(
  getFetchGetOrganizations,
  (fetchGet) => fetchGet.getIn(["response", "total"])
);

export const getIsLoading = createSelector(
  getFetchGetOrganizations,
  (fetchGet) => fetchGet.get("isLoading")
);

export const getOrganizationIsPosting = createSelector(
  getFetchPostOrganization,
  (fetchGet) => fetchGet.get("isLoading")
);
