import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchPatchDocument, getDocumentIsPublic } from "./redux";

import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 300
  }
}));

const DocumentLinkButton = ({ hidePublicSwitch }) => {
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const classes = useStyles();
  const linkButtonEl = React.useRef();
  const [openLinkPanel, setOpenLinkPanel] = React.useState(false);
  const copyEl = React.useRef();
  const isPublic = useSelector(getDocumentIsPublic);

  const handleCopy = () => {
    /* Select the text field */
    copyEl.current.select();
    copyEl.current.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  };

  const handleChangeIsPublic = event => {
    const checked = event.target.checked;
    dispatch(
      fetchPatchDocument({
        documentId,
        isPublic: Number(checked)
      })
    );
  };

  const handleOpenLinkPanel = () => setOpenLinkPanel(true);

  const handleCloseLinkPanel = () => setOpenLinkPanel(false);

  return (
    <React.Fragment>
      <Tooltip title="文件連結">
        <IconButton
          onClick={handleOpenLinkPanel}
          ref={linkButtonEl}
          color="inherit"
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={openLinkPanel}
        anchorEl={linkButtonEl.current}
        onClose={handleCloseLinkPanel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Paper className={classes.paper}>
          <Box px={2} py={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  複製當前文件連結
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                alignItems="center"
              >
                <TextField
                  inputRef={copyEl}
                  variant="outlined"
                  margin="dense"
                  value={window.location.href}
                  style={{ margin: 0 }}
                />
                <Button variant="outlined" onClick={handleCopy}>
                  複製
                </Button>
              </Grid>
              {!hidePublicSwitch && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    pt={2}
                    pb={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body2"
                      display="inline"
                      color="primary"
                    >
                      擁有連結的人均可以檢視
                    </Typography>
                    <Switch
                      checked={isPublic}
                      onChange={handleChangeIsPublic}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

DocumentLinkButton.propTypes = {
  hidePublicSwitch: PropTypes.bool
};

export default DocumentLinkButton;
