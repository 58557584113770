import { Map } from "immutable";
import { handleActions } from "redux-actions";
import { SET_SELECTED_SELLER_IDENTIFIER } from "./redux";

const reducer = handleActions(
  {
    [SET_SELECTED_SELLER_IDENTIFIER]: (state, action) =>
      state.set("selectedSellerIdentifier", action.payload)
  },
  Map({
    selectedSellerIdentifier: "28473556"
  })
);

export default reducer;
