import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import blueGrey from '@material-ui/core/colors/blueGrey';

export default withStyles(theme => ({
  root: {
    fontSize: '0.875rem',
    width: 36,
    height: 36,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#fff',
    transition: 'border-color 0.4s',
    '&:hover': {
      borderColor: blueGrey[300]
    }
  }
}))(Avatar);
