import React from "react";
import { Redirect } from "react-router-dom";
import Root from "pages/Root";
import DocumentsRoot from "pages/Documents/DocumentsRoot";
import Documents from "pages/Documents";
import Document from "pages/Document";
import DocumentVersion from "pages/DocumentVersion";
import Feedbacks from "pages/Feedbacks";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CommentIcon from "@material-ui/icons/Comment";

export default [
  {
    component: Root,
    routes: [
      {
        path: "/documents",
        component: DocumentsRoot,
        breadcrumbName: "EDS",
        icon: <LibraryBooksIcon />,
        routes: [
          {
            path: "/documents",
            exact: true,
            breadcrumbName: "文件列表",
            component: Documents,
          },
          {
            component: DocumentVersion,
            path: "/documents/:documentId/document-versions/:documentVersionId",
          },
          {
            component: Document,
            path: "/documents/:documentId",
          },
        ],
      },
      {
        path: "/feedbacks",
        component: Feedbacks,
        breadcrumbName: "回饋管理",
        icon: <CommentIcon />,
      },
      {
        path: "*",
        render: () => <Redirect to="/documents" />,
      },
    ],
  },
];
