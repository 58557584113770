import React from "react";

import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetDocumentVersion,
  fetchPatchDocument,
  getDocumentVersion,
  getDocumentIsPatching
} from "./redux";

import { Link } from "react-router-dom";
import NavbarBrick from "@e-group/material-layout/NavbarBrick";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import FixedCenter from "components/FixedCenter";
import EdsCKEditor from "components/EdsCKEditor";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1)
  }
}));

const DocumentVersion = props => {
  const { documentId, documentVersionId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const documentVersion = useSelector(getDocumentVersion);
  const documentIsPatching = useSelector(getDocumentIsPatching);

  React.useEffect(() => {
    dispatch(
      fetchGetDocumentVersion({
        documentId,
        documentVersionId: documentVersionId
      })
    );
  }, [dispatch, documentId, documentVersionId]);

  const handleRecovery = () => {
    dispatch(
      fetchPatchDocument({
        documentId,
        documentTitle: documentVersion.get("documentVersionTitle"),
        documentContentEditor: documentVersion.get(
          "documentVersionContentEditor"
        )
      })
    );
  };

  const renderContent = () => {
    if (!documentVersion) {
      return (
        <FixedCenter>
          <CircularProgress disableShrink />
        </FixedCenter>
      );
    }
    return (
      <EdsCKEditor
        data={documentVersion.get("documentVersionContentEditor")}
        disabled
      />
    );
  };

  return (
    <Dialog fullScreen open>
      <AppBar color="default">
        <Toolbar>
          <Typography variant="h6">
            {documentVersion && documentVersion.get("documentVersionTitle")}
          </Typography>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={handleRecovery}
            disabled={!documentVersion}
          >
            復原至此版本
          </Button>
          <Button
            component={Link}
            variant="outlined"
            className={classes.button}
            to={`/documents/${documentId}`}
          >
            返回原本文件
          </Button>
        </Toolbar>
      </AppBar>
      <NavbarBrick />
      <Container>{renderContent()}</Container>
      <Fade in={documentIsPatching}>
        <FixedCenter>
          <CircularProgress disableShrink />
        </FixedCenter>
      </Fade>
    </Dialog>
  );
};

export default DocumentVersion;
